import React, { useRef } from "react";
import styles from "./VerificationCode.module.css";
import { PRIMARYCOLOR } from "../../Utility/constants";

const VerificationCode = ({ length = 4, style = {}, ...props }) => {
  const inputs = useRef([]);
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length > 1) {
      e.target.value = value.charAt(0); // Only keep the first character
    }
    if (value.length === 1 && index < length - 1) {
      inputs.current[index + 1].focus();
    }
    props.setExternalValue(
      Number(inputs.current.map((item) => item.value).join(""))
    );
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  return (
    <div className={styles.inputContainer} style={style}>
      {[...Array(length)].map((_, index) => (
        <input
          key={index}
          type="number"
          maxLength="1"
          className={styles.inputField}
          style={{border:PRIMARYCOLOR}}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputs.current[index] = el)}
        />
      ))}
    </div>
  );
};

export default VerificationCode;
