import React from "react";
import { Link, useLocation } from "react-router-dom";
import homeSvg from "../../assets/dashboard/home1.svg";
import styles from "./BreadCrumbs.module.css";
import rightArrow from "../../assets/dashboard/rightArrow.svg";
import { isNumeric, removeNonAlphabeticCharacters } from "../../Utility/helper";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const validPathnames = pathnames; // change for breadcrumb ,remove nubmers from url

  return (
    <nav aria-label="breadcrumb">
      <div className={styles.breadCrumbContainer}>
        {validPathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === validPathnames.length - 1;
          name = name;
          return isLast ? (
            <div
              key={name}
              className={`${styles.bread} ${styles.activeBreadCrumb}`}
              aria-current="page"
            >
              {index === 0 && (
                <img src={homeSvg} alt="" className={styles.imgClass} />
              )}
              <span>
                <img src={rightArrow} alt="" /> {name}
              </span>
            </div>
          ) : (
            <div
              key={name}
              className={`${styles.bread} ${styles.inActiveBreadCrumb}`}
            >
              {index === 0 && (
                <img src={homeSvg} alt="" className={styles.imgClass} />
              )}
              <Link to={routeTo}>
                <img src={rightArrow} alt="" /> {name}
              </Link>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default Breadcrumbs;
