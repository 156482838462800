import React from "react";
import styles from "./Billing.module.css";
function Billing({ img, statusText, desc, status }) {
  return (
    <div className={styles.con}>
      <div>
        <img src={img} />
      </div>
      <p
        className={status === "succes" ? `${styles.success}` : `${styles.warn}`}
      >
        {statusText}
      </p>
      <p className={styles.desc}>{desc}</p>
    </div>
  );
}

export default Billing;
