import { Pagination } from "@mui/material";
import { padding, width } from "@mui/system";
import React from "react";
// import { PRIMARYCOLOR } from "../../../Utility/constants";

const CustomPagination = ({ setPageState, max_pages = 1 }) => {
  //   const [page, setPage] = React.useState(1);
  return (
    <div style={{display:"flex",justifyContent:"end",width:"100%",padding:"10px 0px"}} >
      <Pagination
        color="primary"
        count={max_pages}
        shape="rounded"
        onChange={(e, value) => setPageState(value)}
        sx={{
          // "& .MuiPaginationItem-root": {
          //   color: "grey.500", // Color for inactive page numbers
          // },
          "& .MuiPaginationItem-root.Mui-selected": {
            color: "#fff", // Color for the active page number
            // backgroundColor: PRIMARYCOLOR, // Optional: Background color for active page number
          },
        }}
      />
    </div>
  );
};

export default CustomPagination;
