import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { createTheme } from "@mui/material/styles";
import {
  FILLEDPAGE,
  FORM,
  FORMID,
  HEADERLANGUAGE,
  HEATINGSYSTEMS,
  LANGUAGE,
  LOCAL_STRING,
  NEEDDATA,
  NEEDFILLEDPAGE,
  NEEDID,
  NEEDPAGE,
  OTHERDATA,
  PAGE,
  PDFID,
  REMEMBER,
  TOKEN,
  UNIQUEID,
  USER,
  WALLS,
} from "../../Utility/strings";
import { PRIMARYCOLOR, SECONDARYCOLOR } from "../../Utility/constants";
import Cookies from "js-cookie";
import { dycryptAES, encryptAES } from "../../Utility/crypt";
import api from "../../Utility/network";
import { germanText } from "../../Utility/gerLangObject";

export const AppContext = createContext(null);

const cookieValue = Cookies.get(LOCAL_STRING);
const sessionValue = sessionStorage.getItem(LOCAL_STRING);

const themes = createTheme({
  palette: {
    primary: {
      main: PRIMARYCOLOR,
    },
    secondary: {
      main: SECONDARYCOLOR,
    },
    white: {
      main: "rgba(255,255,255,1)",
    },
    mode: "light",
  },
  typography: {
    button: {
      textTransform: "capitalise",
      color: "#fff !important", // Set button text color to white
    },
  },
});

function getLocalData(keyname, value) {
  let decryptedData = null;
  try {
    decryptedData = dycryptAES(value);
  } catch {
    console.log("Error while fetching data from cookie");
  }
  return checkObject(keyname, decryptedData);
}

function checkObject(keyname, decryptedData) {
  if (decryptedData) {
    if (decryptedData[keyname]) {
      return decryptedData[keyname];
    }
  }
  if (keyname === TOKEN) return { access: "", refresh: "" };
  if (keyname === USER) return "";
  if (keyname === REMEMBER) return false;
  if (keyname === FORM) return {};
  if (keyname === OTHERDATA)
    return {
      hotwater_included_in_heating_system: 0,
      construction_year: null,
      heating_info: [],
      yearArray: [],
      buildingData: {},
      vacancy: [],
      noOfHeatingSystem: null,
      property: null,
      delAdd: null,
      lastStep: {
        order_type: 1,
        first_name: "",
        last_name: "",
        phone: null,
        postcode: null,
        location: "",
        email: "",
        company_name: "",
        vat_id: "",
        street: "",
      },
      info: {
        living_space: "",
        building_part: "",
        ventilation_id: [],
        was_the_1977_thermal_insulation_regulation_met: false,

        does_property_have_basement: false,
        what_lies_below_the_ground_floor: null,

        are_renewable_energy_used: false,
        type_of_renewable_energy_id: [],
        energy_used_for_id: [],

        is_there_cooling_system: false,
        type_of_cooling_system_used_id: [],

        optional_information_abt_equipment: false,

        sun_protection: "",
        does_the_property_have_thermal_insulation: false,
        is_there_roof_or_attic_insulation: false,
      },
    };
  if (keyname === NEEDDATA)
    return {
      heatingSystemsCount: null,
      hotSystemsCount: null,
      construction_year: null,
      floor_details: [],
      roof_detail: {},
      basement_detail: {},
      insulation_details: [],
      windowDimension: false,
      active: {},
      insulation: false,
      heating_systems: [],
      hot_water_systems: [],
      are_renewable_energy_used: false,
      do_u_have_solar_collector: false,
      unobstructed_collector_area: null,
      address: {
        direction_point: null,
        is_property_have_border_on_another_house: false,
        location: "",
        postcode: "",
        street: "",
      },
      billing_address: {
        order_type: 1,
        first_name: "",
        last_name: "",
        phone: null,
        postcode: null,
        location: "",
        email: "",
        company_name: "",
        vat_id: "",
        street: "",
      },
    };

  if (keyname === PAGE) return 1;
  if (keyname === FILLEDPAGE) return 1;
  if (keyname === NEEDPAGE) return 1;
  if (keyname === NEEDFILLEDPAGE) return 1;
  if (keyname === PDFID) return null;
  if (keyname === FORMID) return null;
  if (keyname === UNIQUEID) return null;
  if (keyname === HEATINGSYSTEMS) return null;
  if (keyname === LANGUAGE) return "de";
  if (keyname === HEADERLANGUAGE) return germanText;
  if (keyname === WALLS)
    return {
      structure_type: null,
      wall_details: [],
      window_details: [],
      structure_detail: {},
    };
  return "";
}

function AppContextProvider(props) {
  const [state, setState] = useState({
    theme: themes,
    language: getLocalData(LANGUAGE, cookieValue || sessionValue),
    token: getLocalData(TOKEN, cookieValue || sessionValue),
    user: getLocalData(USER, cookieValue || sessionValue),
    remember: getLocalData(REMEMBER, cookieValue || sessionValue),
    form: getLocalData(FORM, cookieValue || sessionValue),
    otherData: getLocalData(OTHERDATA, cookieValue || sessionValue),
    page: getLocalData(PAGE, cookieValue || sessionValue),
    filledPage: getLocalData(FILLEDPAGE, cookieValue || sessionValue),
    unique_id: getLocalData(UNIQUEID, cookieValue || sessionValue),
    form_id: getLocalData(FORMID, cookieValue || sessionValue),
    formData: { id: 49 },
    pdfId: null,
    needPage: getLocalData(NEEDPAGE, cookieValue || sessionValue),
    // needPage: 4,
    needFilledPage: getLocalData(NEEDFILLEDPAGE, cookieValue || sessionValue),
    // needFilledPage: 4,
    heatingSystems: getLocalData(HEATINGSYSTEMS, cookieValue || sessionValue),
    needData: getLocalData(NEEDDATA, cookieValue || sessionValue),
    needId: getLocalData(NEEDID, cookieValue || sessionValue),
    needPdfId: null,
    walls: getLocalData(WALLS, cookieValue || sessionValue),
    // needId: 4,
    snackbar: {
      open: false,
      text: "",
      severity: "info",
      duration: 3000,
    },
    headerLanguage: getLocalData(HEADERLANGUAGE, cookieValue || sessionValue),
  });

  useLayoutEffect(() => {
    const authInterceptor = api.interceptors.request.use(
      (config) => {
        const { token } = state;
        if (token && token.access) {
          config.headers.Authorization = `Bearer ${token.access}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return () => {
      api.interceptors.request.eject(authInterceptor);
    };
  }, [state.token]);
  useEffect(() => {
    const { theme, ...restState } = state;
    const encryptedData = encryptAES(restState);
    if (!state.remember) {
      try {
        sessionStorage.setItem(LOCAL_STRING, encryptedData);
      } catch (error) {
        console.log(error);
      }
    } else {
      Cookies.set(LOCAL_STRING, encryptedData, { expires: 1 });
    }
  }, [state]);

  const updateState = (key, data) => {
    if (key === REMEMBER) {
      data === true ? sessionStorage.clear() : Cookies.remove(LOCAL_STRING);
    }
    setState((prev) => ({ ...prev, [key]: data }));
  };

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove(LOCAL_STRING);
    window.location.href = "/";
  };

  // SNACKBAR
  const showSnackbar = (text, severity = "info", duration = 3000) => {
    setState((prev) => ({
      ...prev,
      snackbar: {
        open: true,
        text,
        severity,
        duration,
      },
    }));
    setTimeout(() => {
      handleCloseSnackbar();
    }, duration);
  };

  const handleCloseSnackbar = () => {
    setState((prev) => ({
      ...prev,
      snackbar: {
        ...prev.snackbar,
        open: false,
      },
    }));
  };

  return (
    <AppContext.Provider
      value={{
        theme: state.theme,
        updateState: updateState,
        handleLogout: handleLogout,
        state: state,
        setState: setState,
        showSnackbar: showSnackbar,
        handleCloseSnackbar: handleCloseSnackbar,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
