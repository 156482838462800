import React, { lazy, useContext, useEffect, useState } from "react";
import styles from "./Step.module.css";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import property from "../../../assets/componentAssets/Property.png";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { directions, monthYear } from "../utility/utils";
import { PatchApi, getApi } from "../../../Utility/network";
import PropertyAddress from "../../../assets/componentAssets/properttyAddress.png";

function Step5() {
  const { state, setState } = useContext(AppContext);
  const style = { width: "100%" };
  const [errorsData, setErrorsData] = useState({});
  const [submitData, setSubmit] = useState({
    street: "",
    postcode: "",
    location: "",
    direction_point: "",
    is_property_have_border_on_another_house: false,
  });

  // New state variables for error messages

  //   "address": {

  //     "street": "sas",

  //     "postcode": 1212,

  //     "location": "mumbai",

  //     "direction_point": 1,

  //     "is_property_have_border_on_another_house": true,

  //     "attached_wall_id": 1

  // }
  useEffect(() => {
    const {
      street,
      postcode,
      location,
      direction_point,
      is_property_have_border_on_another_house,
    } = state.needData.address;
    setSubmit({
      street,
      postcode,
      location,
      direction_point,
      is_property_have_border_on_another_house,
    });
  }, [state.needData.address]);
  const validateFields = () => {
    const errors = {};
    let hasErrors = false;
    const data = { ...submitData };
  
    // Iterate over each key in the object
    for (const key in data) {
      // Skip validation for 'is_property_have_border_on_another_house'
      if (key === 'is_property_have_border_on_another_house') {
        continue;
      }
  
      if (Array.isArray(data[key])) {
        // Check if the array is empty
        if (data[key].length === 0) {
          errors[key] = "This field is required";
          hasErrors = true;
        } else {
          // Check nested arrays of objects like wall_details
          data[key].forEach((item, index) => {
            for (const subKey in item) {
              if (item[subKey] === null || item[subKey] === '' || item[subKey] === false) {
                errors[`${key}[${index}].${subKey}`] = "This field is required";
                hasErrors = true;
              }
            }
          });
        }
      } else if (data[key] === null || data[key] === '' || data[key] === false) {
        // Check if the value is null, empty string, or false
        errors[key] = "This field is required";
        hasErrors = true;
      }
    }
  
    // Log the errors (or handle them as needed)
    setErrorsData(errors);
  
    // Return true if there are errors
    return hasErrors;
  };
  
  const handleSubmit = async (num, type) => {
    const errors = validateFields();
    if (errors) return;
    try {
      if (type === "next") {
        const {
          street,
          postcode,
          location,
          direction_point,
          is_property_have_border_on_another_house,
        } = submitData;
        const _data = {
          street,
          postcode,
          location,
          direction_point,
          is_property_have_border_on_another_house,
        };
        try {
          const postData = await PatchApi(`/energy_need/${state.needId}/`, {
            address: _data,
          });
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              needPage: prev.needPage + 1,
              needData: { ...prev.needData, address: _data },
              needFilledPage:
                prev.needFilledPage >= prev.needPage + 1
                  ? prev.needFilledPage
                  : prev.needPage + 1,
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, needPage: prev.needPage - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_need/${state.needId}/generate_link/`);
        // navigate("/login");
        alert("link sent to your email");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmit((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={PropertyAddress}
          heading={"Property Info"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          <p className={styles.fieldName}>Street or House Number</p>
          <TextField
            size="small"
            style={{ width: "100%" }}
            onChange={handleChange}
            name="street"
            value={submitData.street}
            error={errorsData?.street}
            helperText={errorsData?.street}
          />
          <div className={styles.fieldCom}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Location</p>
              <TextField
                name="location"
                onChange={handleChange}
                value={submitData.location}
                size="small"
                style={{ width: "100%" }}
                error={errorsData?.location}
                helperText={errorsData?.location}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Postcode</p>
              <TextField
                name="postcode"
                size="small"
                onChange={handleChange}
                value={submitData.postcode}
                style={{ width: "100%" }}
                error={errorsData?.postcode}
                helperText={errorsData?.postcode}
              />
            </div>
          </div>
          <p className={styles.fieldName}>
            In which cardinal direction does side A of the object point? 
          </p>
          <FormControl fullWidth
          error={Boolean(errorsData.direction_point)}>
            <Select
              size="small"
              // value={submitData.material_id}
              name="direction_point"
              value={submitData.direction_point}
              onChange={(e) => handleChange(e)}
            >
              {directions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorsData?.direction_point && (
            <FormHelperText error={errorsData.direction_point}>
              {errorsData?.direction_point}
            </FormHelperText>
          )}
          <div>
            <p className={styles.fieldName}>
              Is the property next to another house?
            </p>
            <RadioGroup
              name="is_property_have_border_on_another_house" // Name of the radio group
              value={submitData.is_property_have_border_on_another_house} // Bind the state value
              // onChange={handleChange} // Handle value changes
            >
              <div className={styles.Radio}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  disabled={true}
                  label="yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  disabled={true}
                  label="no"
                />
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>

      <NavButton handleSubmit={handleSubmit} />
    </div>
  );
}

export default Step5;
// "address": {

//   "street": "sas",

//   "postcode": 1212,

//   "location": "mumbai",

//   "direction_point": 1,

//   "is_property_have_border_on_another_house": true,

//   "attached_wall_id": 1

// }
