import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import OuterHeader from "../../Components/OuterHeader/OuterHeader";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Text,
} from "recharts";
import draftIcon from "../../assets/dashboard/draftIcon.svg";
import correctionPending from "../../assets/dashboard/correctionPending.svg";
import issuedIcon from "../../assets/dashboard/issuedIcon.svg";
import issuedIconV2 from "../../assets/dashboard/issuedIconV2.svg";
import api, { PostApi } from "../../Utility/network";
import { colors, Skeleton, Typography } from "@mui/material";
import DateField from "../../Components/DateField/DateField";
import { stringToYYYYMMDD } from "../../Utility/helper";
import { threeYearsBack } from "../Need/utility/utils";

function Dashboard(props) {
  const [loading, setLoading] = useState(true);

  const [dates, setDates] = useState({
    start_date: stringToYYYYMMDD(
      new Date(new Date().setMonth(new Date().getMonth() - 1))
    ),
    end_date: stringToYYYYMMDD(new Date()),
  });
  console.log(dates, "dates");

  const [apiData, setApiData] = useState({
    graph: [],
    consumption_revenue: 0,
    need_revenue: 0,
    total_revenue: 0,

    total_consumption_draft_orders: 0,
    total_need_draft_orders: 0,

    total_consumption_issued_orders: 0,
    total_need_issued_orders: 0,

    total_consumption_pending_orders: 0,
    total_need_pending_orders: 0,

    total_consumption_issued_version_2: 0,
    total_need_issued_issued_version_2: 0,
  });
  const [pieData, setPieData] = useState([
    { name: "Consumption", value: 0, orders: 0 },
    { name: "Need", value: 0, orders: 0 },
  ]);
  useEffect(() => {
    setPieData((prev) => [
      {
        name: "Consumption",
        value: apiData.consumption_revenue,
        orders: apiData.total_consumption_issued_orders,
      },
      {
        name: "Need",
        value: apiData.need_revenue,
        orders: apiData.total_need_issued_orders,
      },
    ]);
  }, [apiData]);

  const getData = async (filter) => {
    try {
      const responseData = await PostApi(
        `/payment/checkout/dashboard/`,
        filter
      );

      setApiData(responseData.data.data);
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};

      if (dates.start_date !== "" && dates.end_date !== "") {
        finalFilters.from_date = dates.start_date;
        finalFilters.to_date = dates.end_date;
      }

      getData(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [dates]);
  const data = [
    { name: "1st Sep", Consumption: 60, Need: 80 },
    { name: "2nd Sep", Consumption: 30, Need: 50 },
    { name: "3rd Sep", Consumption: 50, Need: 60 },
    { name: "4th Sep", Consumption: 70, Need: 70 },
    { name: "5th Sep", Consumption: 40, Need: 20 },
    { name: "6th Sep", Consumption: 60, Need: 40 },
    { name: "7th Sep", Consumption: 50, Need: 80 },
    { name: "8th Sep", Consumption: 70, Need: 80 },
    { name: "9th Sep", Consumption: 60, Need: 80 },
  ];
  const COLORS = ["url(#gradient2)", "url(#gradient1)"];

  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const totalValue = pieData.reduce((acc, entry) => acc + entry.value, 0);

  const formatTooltipValue = (value) =>
    `${((value / totalValue) * 100).toFixed(0)}%`;
  const handleChange = (name, data) => {
    // If changing the start_date or end_date, ensure end_date >= start_date
    setDates((prev) => {
      const newStartDate =
        name === "start_date" ? stringToYYYYMMDD(data) : prev.start_date;
      const newEndDate =
        name === "end_date" ? stringToYYYYMMDD(data) : prev.end_date;

      // Check if the new end_date is before the start_date
      if (name === "end_date" && newEndDate < newStartDate) {
        return prev; // Do not update end_date if it's before start_date
      }

      return {
        ...prev,
        start_date: newStartDate,
        end_date: newEndDate,
      };
    });
  };

  return (
    <div>
      {/* <OuterHeader/> */}

      {/* Dates  */}
      <div className={styles.datesCon}>
        <div className={styles.filterTxt}>Filter by date</div>
        <div style={{ width: "15%" }}>
          <DateField
            placeholderText={"From Date"}
            onChange={(date) => handleChange("start_date", date)}
            name="start_date"
            startDate={dates.start_date}
            max={true}
            minDate={threeYearsBack(3)}
          />
        </div>
        <div style={{ width: "15%" }}>
          <DateField
            placeholderText={"To Date"}
            onChange={(date) => handleChange("end_date", date)}
            name="end_date"
            startDate={dates.end_date}
            max={true}
            minDate={dates?.start_date}
            today={true}
          />
        </div>
        <div>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="name" name="startDate" />
          </LocalizationProvider> */}
        </div>
      </div>

      {/* Graph Container  */}
      <div className={styles.allCon}>
        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            width={"60%"}
            height={350}
          />
        ) : (
          <div className={styles.graphCon}>
            <ResponsiveContainer width="100%" height="80%">
              <div className={styles.topHead}>
                <p className={styles.orders}>Daily Orders</p>
                <div className={styles.legend}>
                  <div className={styles.legend}>
                    <div className={styles.comBorder}>
                      <div className={styles.comColor} />
                    </div>
                    <p className={styles.consum}>Consumption</p>
                  </div>
                  <div className={styles.legend}>
                    <div className={styles.needBorder}>
                      <div className={styles.needColor} />
                    </div>
                    <p className={styles.consum}>Need</p>
                  </div>
                </div>
              </div>
              <BarChart
                data={apiData.graph}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <defs>
                  <linearGradient
                    id="firstBarGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#FEE96A" />
                    <stop offset="101.63%" stopColor="#FFC853" />
                  </linearGradient>
                  <linearGradient
                    id="secondBarGradient"
                    x1="0%"
                    y1="100%"
                    x2="0%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#22A156" />
                    <stop offset="100%" stopColor="#AFDB28" />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  color="#F3F2F7"
                  vertical={false}
                  strokeWidth={0.5}
                />
                <XAxis
                  dataKey="date"
                  fontSize={"12px"}
                  axisLine={false}
                  tickLine={false}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  fontSize={"15px"}
                  label={{
                    value: "No of Orders",
                    angle: -90,
                    position: "insideLeft",
                    offset: -5,
                    style: { textAnchor: "end", fontSize: "14px" },
                  }}
                />
                <Tooltip />
                {/* <Legend /> */}
                <Bar
                  dataKey="no_of_consumption_orders"
                  fill="url(#firstBarGradient)"
                  barSize={5}
                />
                <Bar
                  dataKey="no_of_need_orders"
                  fill="url(#secondBarGradient)"
                  barSize={5}
                />
              </BarChart>
            </ResponsiveContainer>

            {/* Total Orders */}
          </div>
        )}
        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            width={"35%"}
            height={350}
          />
        ) : (
          <div className={styles.totalOrdersContainer}>
            <div className={styles.totalOrdersTxt}>Total Orders</div>

            <div className={styles.totalOrders}>
              {/* Draft Container  */}
              <div className={styles.draftCon}>
                <div className={styles.insideBox}>
                  <img src={draftIcon} alt="Draft_Icon" />
                  <div>
                    <div className={styles.insideValue}>
                      <span className={styles.consumtion}>
                        {apiData.total_consumption_draft_orders}
                      </span>{" "}
                      /{" "}
                      <span className={styles.need}>
                        {apiData.total_need_draft_orders}
                      </span>
                    </div>
                    <div className={styles.insideTxt}>DRAFT</div>
                  </div>
                </div>
              </div>

              {/* Correction Container  */}
              <div className={styles.draftCon}>
                <div className={styles.insideBox}>
                  <img src={correctionPending} alt="Correction_Pending" />
                  <div>
                    <div className={styles.insideValue}>
                      <span className={styles.consumtion}>
                        {apiData.total_consumption_pending_orders}
                      </span>{" "}
                      /{" "}
                      <span className={styles.need}>
                        {apiData.total_need_pending_orders}
                      </span>
                    </div>
                    <div className={styles.insideTxt}>CORRECTION PENDING</div>
                  </div>
                </div>
              </div>

              {/* Issued Container  */}
              <div className={styles.draftCon}>
                <div className={styles.insideBox}>
                  <img src={issuedIcon} alt="Issued_Icon" />
                  <div>
                    <div className={styles.insideValue}>
                      <span className={styles.consumtion}>
                        {apiData.total_consumption_issued_orders}
                      </span>{" "}
                      /{" "}
                      <span className={styles.need}>
                        {apiData.total_need_issued_orders}
                      </span>
                    </div>
                    <div className={styles.insideTxt}>ISSUED</div>
                  </div>
                </div>
              </div>

              {/* Issued Container 2 */}
              <div className={styles.draftCon}>
                <div className={styles.insideBox}>
                  <img src={issuedIconV2} alt="Issued_IconV2" />
                  <div>
                    <div className={styles.insideValue}>
                      <span className={styles.consumtion}>
                        {apiData.total_consumption_issued_version_2}
                      </span>{" "}
                      /{" "}
                      <span className={styles.need}>
                        {apiData.total_need_issued_issued_version_2}
                      </span>
                    </div>
                    <div className={styles.insideTxt}>ISSUED VERSION 2</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            width={"60%"}
            height={350}
          />
        ) : (
          <div className={styles.pieChartCon}>
            <h3 className={styles.heading}>Total Amount</h3>
            <div className={styles.chartCon}>
              <PieChart width={400} height={250}>
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#22A156", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#AFDB28", stopOpacity: 1 }}
                    />
                  </linearGradient>
                  <linearGradient
                    id="gradient2"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#FEE96A", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#FFC853", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  // label={renderCustomLabel}
                  outerRadius={120}
                  innerRadius={60}
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <Tooltip formatter={formatTooltipValue} />
                <Legend
                  layout="vertical"
                  verticalAlign="middle"
                  align="right"
                />
              </PieChart>
            </div>
            <Typography
              variant="h6"
              style={{
                position: "absolute",
                top: "48%",
                left: "43%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              € {apiData.total_revenue}
            </Typography>
            {/* <div
          style={{
            position: "relative",
            top: "-140px",
            left: "210px",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {apiData.total_revenue}
        </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-60px",
                padding: "0px 30px",
                boxSizing: "border-box",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <strong>€ {apiData?.consumption_revenue}</strong> <br />
                <span
                  style={{
                    background:
                      "linear-gradient(90deg, #FEE96A 0%, #FFC853 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontSize: "30px",
                    fontWeight: "700",
                  }}
                >
                  {apiData.consumption_revenue_percentage || 0}%{" "}
                </span>
                <br />
                <span className={styles.consum}>Consumption</span>
                <br />
                <p style={{ color: "#979797", margin: "0px" }}>
                  {" "}
                  {apiData?.total_consumption_issued_orders} Orders
                </p>
              </div>
              <div style={{ textAlign: "right" }}>
                <strong>€ {apiData?.need_revenue}</strong> <br />
                <span
                  style={{
                    background:
                      "linear-gradient(91.2deg, #22A156 1.22%, #AFDB28 99.9%)",

                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontSize: "30px",
                    fontWeight: "700",
                  }}
                >
                  {apiData.need_revenue_percentage || 0}%
                </span>{" "}
                <br />
                <span className={styles.consum}>Need</span>
                <br />
                <p style={{ color: "#979797", margin: "0px" }}>
                  {" "}
                  {apiData?.total_need_issued_orders} Orders
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
