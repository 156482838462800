import { capitalise } from "./helper";

// constant strings //
export const PRIMARYCOLOR = "#22A156";
export const SECONDARYCOLOR = "#AFDB28";
export const TERNARYCOLOR = "#6EAF1A";
export const ERRORCOLOR = "#ef5350";
export const FAINTCOLOR = "#FFF0E9";
export const errorMessage = "#d32f2f";
export const NAME = "name";
export const TEXT = "TEXT";
export const EMAIL = "email";
export const PASSWORD = "password";

// constant objects //
export const nameField = {
  name: NAME,
  type: TEXT,
  label: capitalise(NAME),
  required: false,
  placeholder: capitalise(NAME),
};

export const emailField = {
  name: EMAIL,
  type: EMAIL,
  label: capitalise(EMAIL),
  required: false,
  placeholder: capitalise(EMAIL),
};

export const passwordField = {
  name: PASSWORD,
  type: PASSWORD,
  label: capitalise(PASSWORD),
  placeholder: capitalise(PASSWORD),
  required: false,
};
