import React, { useEffect, useState } from "react";
import CustomTable from "../CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../../Utility/network";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import CustomPagination from "../../CustomPagination/CustomPagination";

function NeedCertificateTable() {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const applyFilters = () => {
      getData(pageState);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [pageState]);

  const getData = async (filter) => {
    try {
      const responseData = await getApi(
        `/energy_need/user_energy_need/?page=${filter}`
      );

      setPaginationData(responseData?.data);
      let list = responseData.data.data;
      list = list.map((item, index) => {
        return {
          ...item,
          name_email: item?.billing_address ? (
            <>
              {item?.billing_address?.first_name}{" "}
              {item?.billing_address?.last_name} <br />
              {item?.billing_address?.email}
            </>
          ) : (
            "-"
          ),
          phone: item?.billing_address ? item?.billing_address?.phone : "-",
          city_postal: item?.billing_address
            ? item?.billing_address?.location +
              ", " +
              item?.billing_address?.postcode
            : "-",
          created: dayjs(item?.created).format("DD-MM-YYYY"),
          status: item?.status,
          energy_consumption: item?.power_consumption
            ? item?.power_consumption.energy_consumption
            : "-",
          payment_status: item?.payment_status,
        };
      });
      setData(list);
      setLoading(false);
    } catch (error) {
      setData([]);
    }
  };
  const onHandleView = (_row) => {
    navigate(`/energy-certificate/${_row.id}`);
  };
  return (
    <div>
      <CustomTable
        tableBodys={data}
        tableHeads={tabHead}
        edit={true}
        loading={loading}
        onHandleView={onHandleView}
      />
      <CustomPagination max_pages={max_pages} setPageState={setPageState} />
    </div>
  );
}

export default NeedCertificateTable;
const tabHead = [
  {
    label: "Id",
    id: "id",
    key: false,
    keyName: "",
  },
  {
    label: "Name & Email",
    id: "name_email",
    key: false,
    minWidth: 120,
  },
  {
    label: "Phone Number",
    id: "phone",
    key: false,
    minWidth: 120,
  },
  {
    label: "City, Postal",
    id: "city_postal",
    key: false,
    minWidth: 120,
  },
  {
    label: "Created On",
    id: "created",
    key: false,
    minWidth: 120,
  },
  {
    label: "Form Status",
    id: "status",
    key: false,
    minWidth: 120,
  },
  {
    label: "Energy Certificate",
    id: "certificate_status",
    key: false,
    minWidth: 120,
  },
  {
    label: "Payment Status",
    id: "payment_status",
    key: false,
    minWidth: 120,
  },
];

const dataArray = [
  {
    id: "1822",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Submitted",
    EnergyCertificate: "Draft",
    PaymentStatus: "Pending",
  },
  {
    id: "1823",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Not Submitted",
    EnergyCertificate: "Issued",
    PaymentStatus: "Pending",
  },
  {
    id: "1824",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Submitted",
    EnergyCertificate: "Correction Pending",
    PaymentStatus: "Failed",
  },
  {
    id: "1825",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Submitted",
    EnergyCertificate: "Issued Version 2",
    PaymentStatus: "Paid ",
  },
  {
    id: "1822",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Submitted",
    EnergyCertificate: "Draft",
    PaymentStatus: "Pending",
  },
  {
    id: "1823",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Not Submitted",
    EnergyCertificate: "Issued",
    PaymentStatus: "Pending",
  },
  {
    id: "1824",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Submitted",
    EnergyCertificate: "Correction Pending",
    PaymentStatus: "Failed",
  },
  {
    id: "1825",
    nameEmail: "Keval Ranoliya kevalranoliya@gmail.   ",
    PhoneNumber: "9999999999",
    CityPostal: "Mumbai, 401606",
    CreatedOn: "24th Feb 2024",
    FormStatus: "Submitted",
    EnergyCertificate: "Issued Version 2",
    PaymentStatus: "Paid ",
  },
];

export const tableHead = [
  {
    id: "id",
    label: "Id.",
    key: false,
    keyName: "",
  },
  {
    id: "nameEmail",
    label: "Name & Email",
    key: false,
    keyName: "",
    edit: "true",
  },
  {
    id: "PhoneNumber",
    label: "Phone Number",
    key: false,
    keyName: "",
  },
  {
    id: "CityPostal",
    label: "City, Postal",
    key: false,
    keyName: "",
    minWidth: 110,
  },
  {
    id: "CreatedOn",
    label: "Created On",
    key: false,
    keyName: "",
    minWidth: 110,
  },

  {
    id: "FormStatus",
    label: "Form Status",
    key: false,
    keyName: "",
    minWidth: 120,
  },
  {
    id: "EnergyCertificate",
    label: "Energy Certificate",
    key: false,
    keyName: "",
    minWidth: 120,
  },
  {
    id: "PaymentStatus",
    label: "Payment Status ",
    key: false,
    keyName: "",
  },
];
