import TextField from "@mui/material/TextField";
import React from "react";

function RegisterFields({
  name = "",
  value = "",
  onChange,
  label = "",
  size = "small",
  placeholder = "",
  type = "text",
  style = {},
  error = "",
  required = false,
  ...props
}) {
  return (
    <React.Fragment>
      <TextField
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        style={style}
        size={size}
        placeholder={placeholder}
        type={type}
        error={error ? true :false}
        helperText={error}
        required={required}
      />
    </React.Fragment>
  );
}

export default RegisterFields;
