import React, { useContext, useEffect, useReducer, useState } from "react";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import { formStep1 } from "../utility/utils";
import { formState } from "../InitialState/formState";
import { reducer } from "../consumptionReducer";
import {
  validateConstruction,
  validateEmail,
  validatePostalCode,
} from "../utility/validations";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import building from "../../../assets/dashboard/building.svg";
import styles from "./Step.module.css";
import CustomRadio from "../../../Components/CustomRadio/CustomRadio";
import { PostApi, getApi } from "../../../Utility/network";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";
import { ContentCutTwoTone } from "@mui/icons-material";
import { ordinalSuffix } from "../../../Utility/utils";

function Step1({ handleNavigation, currentPage, steps, ...props }) {
  const { state, setState } = useContext(AppContext);
  const [state1, dispatch] = useReducer(reducer, formState);
  const [floor, setFloor] = useState({ floor_details: [] });

  const [building_type, setBuilding_type] = useState([]);
  const [occasion_type, setOccasion_type] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const { step1 } = state1;

  useEffect(() => {
    getTypeData();
  }, []);
  useEffect(() => {
    const { number_of_floors } = step1;
    let num = number_of_floors ? number_of_floors : 0;
    let arr = [];
    for (let i = 0; i < num; i++) {
      arr.push({ index: i + 1, heating_percentage: null });
    }
    // setFloor({ floor_details: arr });
  }, [step1.number_of_floors]);
  const getTypeData = async () => {
    try {
      const dataResponse = await getApi(`/energy_consumption/building_type`);
      const occasionResponse = await getApi(`/energy_consumption/occasion`);
      setOccasion_type(occasionResponse.data.data);
      setBuilding_type(dataResponse.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      otherData: { ...prev.otherData, buildingData: { ...step1 } },
    }));
  }, [step1]);
  useEffect(() => {
    if (initialLoad) return;
    if (state?.otherData?.buildingData?.hasOwnProperty("occasion_id")) {
      const _data = state?.otherData?.buildingData;
      dispatch({
        type: "BULK_STEP1",
        payload: {
          ..._data,
        },
      });
      setInitialLoad(true);
    }
    if (state?.needData?.hasOwnProperty("floor_details")) {
      setFloor({ floor_details: state.needData.floor_details });
    }
  }, [state, step1]);

  const validateRequiredField = (value, fieldName, type) => {
    if (type === "number") {
      if (!value) {
        return `The ${fieldName} is required as a number.`;
      }
      if (/[-eE+]/.test(value)) {
        return `${fieldName} should not contain '-', 'e', or '+'.`;
      }
      return "";
    } else {
      return value ? "" : `${fieldName} is required`;
    }
  };

  const validateRequiredFields = (value, fieldName, type) => {
    if (type === "number") {
      if (!value) {
        return `The ${fieldName} is required as a number.`;
      }
      if (/[-eE+]/.test(value)) {
        return `${fieldName} should not contain '-', 'e', or '+'.`;
      }
      return "";
    } else {
      return value ? "" : `${fieldName} is required`;
    }
  };

  const validateFields = (name, value) => {
    let error = "";
    const fieldName =
      formStep1.find((field) => field.name === name)?.title || name;
    switch (name) {
      case "email":
        error = validateEmail(value);
        break;
      case "postal_code":
        error = validatePostalCode(value);
        break;
      case "construction_year":
        error = validateConstruction(value);
        break;
      case "number_of_apartments":
      case "floors":
        error = validateRequiredField(value, fieldName, "number");
        break;
      case "floor_to_ceiling_height":
        error = validateRequiredFields(value, fieldName, "number");
        break;
      case "building_type_id":
      case "occasion_id":
        error = validateRequiredField(value, fieldName);
        break;
      default:
        break;
    }
    return error;
  };
  const handleChange = (name, value, item) => {
    const error = validateFields(name, value);
    let values = value;
    if (numberType.includes(name)) {
      values = value.replace(/[^0-9]/g, "");
    }
    if (numberType.includes("floor_to_ceiling_height")) {
      values = value.replace(/[^0-9.]/g, "");
    }
    if (name === "number_of_floors") {
      let num = value ? value : 0;
      let arr = [];
      for (let i = 0; i < num; i++) {
        arr.push({ index: i + 1, heating_percentage: null });
      }
      setFloor({ floor_details: arr });
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    dispatch({
      type: "UPDATE_STEP1",
      payload: {
        [name]: values,
      },
    });
    // Perform validations
    // const errors = {};
    // switch (name) {
    //   case "email":
    //     const emailError = validateEmail(value);
    //     if (emailError) {
    //       errors.email = emailError;
    //     }
    //     break;
    //   case "postalCode":
    //     const postalCodeError = validatePostalCode(value);
    //     if (postalCodeError) {
    //       errors.postalCode = postalCodeError;
    //     }
    //     break;
    //   // Add more cases for other fields
    //   default:
    //     break;
    // }

    // // Dispatch all validation errors
    // if (Object.keys(errors).length > 0) {
    //   dispatch({
    //     type: "SET_ERROR1",
    //     payload: errors,
    //   });
    // }
  };

  const handleChange12 = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;
    // Check if the value is a boolean string
    if (value === "true") {
      parsedValue = true;
    } else if (value === "false") {
      parsedValue = false;
    }
    // Check if the value is a number string
    else if (!isNaN(value) && value.trim() !== "") {
      parsedValue = parseFloat(value);
    }
    dispatch({
      type: "UPDATE_STEP1",
      payload: {
        [name]: value,
      },
    });
  };
  const handleSubmit = async (num, type) => {
    try {
      if (type === "next") {
        // Validate all fields before submitting
        // debugger
        const validationErrors = {};
        formStep1.forEach((field) => {
          const error = validateFields(field.name, step1[field.name]);
          if (error) validationErrors[field.name] = error;
        });
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
          return; // Stop submission if there are validation errors
        }

        try {
          const postData = await PostApi(`/energy_need/`, {
            ...step1,
            ...floor,
          });
          setState((prev) => ({
            ...prev,
            needData: {
              ...prev.needData,
              construction_year: step1.construction_year,
              floor_details: floor.floor_details,
            },
          }));
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              needPage: prev.needPage + 1,
              needFilledPage:
                prev.needFilledPage >= prev.needPage + 1
                  ? prev.needFilledPage
                  : prev.needPage + 1,
              needId: postData.data.data.id,
              formData: postData.data.data,
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, needPage: prev.needPage - 1 }));
      } else if (type == "save") {
      }
    } catch (error) {
      alert("something went wrong");
    }
  };
  const handleChangeFloor = (e) => {
    setFloor((prev) => ({ ...prev, count: e.target.value }));
  };
  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={building}
          heading={"Building Data"}
          secPara="Provided information"
        />
        <div className={styles.fieldDetails}>
          <div className={styles.fieldCom}>
            {formStep1.map((field) => (
              <div className={styles.fields}>
                {(field.fieldType === 1 || field.fieldType === 3) && (
                  <CustomInput
                    key={field.id}
                    label={field.title}
                    name={field.name}
                    value={step1[field.name]}
                    onChange={handleChange}
                    postData={field?.popData}
                    options={
                      field.name === "building_type_id"
                        ? building_type
                        : field.name === "occasion_id"
                        ? occasion_type
                        : []
                    }
                    error={errors[field.name]}
                    helperText={errors[field.name]}
                    {...field}
                  />
                )}
                {/* <CustomRadio
                    data={field}
                    name={field.name}
 
                    onChanges={handleChange}
                  /> */}
                {field.fieldType === 2 && (
                  <div>
                    <p className={styles.fieldName}>
                      Is there is a heated basement
                    </p>
                    <FormControl>
                      <RadioGroup
                        name="is_there_heated_basement" // Name of the radio group
                        value={step1.is_there_heated_basement} // Bind the state value
                        onChange={handleChange12} // Handle value changes
                      >
                        <div className={styles.Radio}>
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="no"
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.fieldCom}>
            <div className={styles.fieldCom}>
              {floor.floor_details.map((item, index) => {
                const suffixNumber = ordinalSuffix(index + 1);
                return (
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>
                      Percentage Heated Area in {suffixNumber} Floor
                    </p>
                    <TextField
                      size="small"
                      value={item.heating_percentage}
                      name="heating_percentage"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFloor((prev) => {
                          let arr = prev.floor_details;
                          arr[index].heating_percentage = e.target.value;
                          return { floor_details: arr };
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          disabled={currentPage <= 1}
          onClick={() => handleSubmit(-1, "prev")}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          disabled={currentPage === steps.length}
          onClick={() => handleSubmit(1, "next")}
        >
          Next
        </Button>
      </div> */}
      </div>
      <NavButton handleSubmit={handleSubmit} />
    </div>
  );
}

export default Step1;
const numberType = [
  "postal_code",
  "construction_year",
  "number_of_apartments",
  "floors",
];

const details = {
  index: 1,
  heating_percentage: null,
};
