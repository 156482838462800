import React from "react";
import styles from "../Onboarding.module.css";
import heritageLogo from "../../assets/onboarding/heritageLogo.png";
import RegisterFields from "../RegisterFields/RegisterFields";
import ControlledCheckbox from "../../Components/ControlledCheckBox/ControlledCheckBox";
import ControlledButton from "../../Components/ControlledButton/ControlledButton";
import ImageryText from "../ImageryText/ImageryText";
import CircularLoading from "../../Components/CircularLoading/CircularLoading";
import { ERRORCOLOR, PRIMARYCOLOR } from "../../Utility/constants";
import { capitalise } from "../../Utility/helper";

function Step1({ id, fields, formState, fieldsData, handleClick, ...props }) {
  return (
    <div className={styles.stepContainer}>
      <ImageryText
        image={heritageLogo}
        primaryText="Log in to your account"
        showBorder={false}
      >
        <span></span>
      </ImageryText>
      <div className={styles.formSection}>
        {fields.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                marginBottom: `${fields.length - 1 === index ? "" : "25px"}`,
              }}
            >
              <RegisterFields
                key={index}
                name={item.name}
                placeholder={item.placeholder}
                size="small"
                {...fieldsData[item.name]}
                style={{ width: "100%" }}
                type={item.type}
                label={capitalise(item.label)}
              />
            </div>
          );
        })}
        <p style={{ marginTop: "8px", color: ERRORCOLOR }}>{formState.error}</p>
        <div className={styles.secondaryDiv}>
          <div>
            <ControlledCheckbox /> <span> Remember me</span>
          </div>
          <div
            onClick={() => {
              handleClick(id, "redirection");
            }}
          >
            <span
              className={styles.blueText}
              style={{ color: `${PRIMARYCOLOR}` }}
            >
              Forgot Password?
            </span>
          </div>
        </div>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px" }}
          onClick={() => {
            handleClick(id, "apicall");
          }}
          disabled={formState.loading}
        >
          {formState.loading && (
            <CircularLoading size={20} style={{ marginRight: "15px" }} />
          )}{" "}
          Login
        </ControlledButton>
      </div>
    </div>
  );
}

export default Step1;
