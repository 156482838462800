export const LOGIN = "/auth/login/";
export const SEND_EMAIL_OTP = "/auth/forgotPassword";
export const VALIDATE_OTP = "/auth/validateOTP";
export const SIGN_UP_2 = "/auth/passwordUpdate";

export const apiCallsConfig = {
  login: {
    endpoint: LOGIN,
  },
  sendOTP: {
    endpoint: SEND_EMAIL_OTP,
  },
  validateOTP: {
    endpoint: VALIDATE_OTP,
  },
  resetPassword: {
    endpoint: SIGN_UP_2, // Double-check if this endpoint is correct
  },
};
