import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../../Utility/network";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

function ResumeEnergy(props) {
  const { state, setState } = useContext(AppContext);
  let navigate = useNavigate();
  const { uuid } = useParams();
  useEffect(() => {
    getData();
  }, [uuid]);
  async function getData() {
    try {
      const postData = await getApi(`/energy_need/?unique_id=${uuid}`);
      const {
        structure_detail,
        windowDimension,
        basement_detail,
        insulation_details,
        roof_detail,
        window_details,
        insulation,
        heating_systems,
        hot_water_systems,
        are_renewable_energy_used,
        do_u_have_solar_collector,
        unobstructed_collector_area,
        address,
        billing_address,
        building_type,
        construction_year,
        user,
        number_of_floors,
        occasion,
        floor_details,
      } = postData.data.data[0];
      let page = 1;
      if (structure_detail) {
        page = 2;
      }
      if (basement_detail) {
        page = 3;
      }
      if (heating_systems && heating_systems.length > 0) {
        page = 4;
      }
      if (address) {
        page = 5;
      }
      if (billing_address) {
        page = 8;
      }
      setState((prev) => ({
        ...prev,
        needPage: page,
        needFilledPage: page,
        needId: postData.data.data.id,
        needData: {
          ...prev.needData,
          structure_detail: structure_detail
            ? structure_detail
            : { ...prev.needData.structure_detail },
          basement_detail,
          insulation_details,
          roof_detail,
          window_details,
          insulation,
          //   active: windowsNew.active,
          windowDimension,
          heating_systems,
          hot_water_systems,
          are_renewable_energy_used,
          do_u_have_solar_collector,
          unobstructed_collector_area,
          address,
          billing_address,

          floor_details,
        },
        otherData: {
          ...prev.otherData,
          buildingData: {
            building_type_id: building_type?.id,
            construction_year,
            email: user?.email,
            number_of_floors,
            occasion_id: occasion?.id,
          },
        },
      }));
      navigate(`/form/needs-certificate`);
    } catch (error) {}
  }
  return <div></div>;
}

export default ResumeEnergy;
