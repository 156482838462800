import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { threeYearsBack } from "../../Pages/Consumption/utility/utils";
import "./DateField.css";
const StyledDatePickerWrapper = styled("div")(({ theme }) => ({
  ".react-datepicker-wrapper": {
    width: "100%",
  },
  ".react-datepicker": {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  ".react-datepicker__header": {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__current-month, .react-datepicker__day-name": {
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected":
    {
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.contrastText,
      color: "#fff",
    },
  ".react-datepicker__day--today": {
    fontWeight: "bold",
  },
  ".react-datepicker__day:hover": {
    backgroundColor: theme.palette.primary.dark,
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__input-container input": {
    width: "97%",
    height: "40px",
    padding: "0 30px",
    fontSize: "16px",
    borderRadius: "4px",
    border: `1px solid rgba(0,0,0, 0.23)`,
    // border:"none",

    color: "theme.palette.text.primary",
    // backgroundColor: 'rgba(0, 0, 0, 0.06)',
    "&:focus": {
      border: " 1.5px solid #22A156",

      outline: "none",
    },
  },
  ".react-datepicker__input-container input:hover": {
    // border: `1px solid rgba(0,0,0, 0.83)`,
    // border:"none",
  },
  ".react-datepicker-popper": {
    zIndex: `15`,
    lineHeight: `0`,
  },
  ".react-datepicker__input-container .react-datepicker__calendar-icon": {
    position: `absolute`,
    // padding: `0.7rem 0.4rem`,
    padding: `7px 4px`,
    boxSizing: `content-box`,
  },
  "@media (max-width: 1024px)": {
    ".react-datepicker__input-container input": {
      width: "84%",
      height: "40px",
      padding: "0 30px",
      fontSize: "16px",
      borderRadius: "4px",
      border: `1px solid rgba(0,0,0, 0.23)`,
      // border:"none",

      color: "theme.palette.text.primary",
      // backgroundColor: 'rgba(0, 0, 0, 0.06)',
      "&:focus": {
        border: " 1.5px solid #22A156",

        outline: "none",
      },
    },
  },
  "@media (max-width: 768px)": {
    ".react-datepicker__input-container input": {
      width: "100%",
      height: "40px",
      padding: "0 30px",
      fontSize: "16px",
      borderRadius: "4px",
      border: `1px solid rgba(0,0,0, 0.23)`,
      // border:"none",

      color: "theme.palette.text.primary",
      // backgroundColor: 'rgba(0, 0, 0, 0.06)',
      "&:focus": {
        border: " 1.5px solid #22A156",

        outline: "none",
      },
    },
  },
}));

const DateField = ({
  onChange,
  startDate = new Date(),
  disbled,
  placeholderText = "",
  max = false,
  today = false,
  minDate,
  ...props
}) => {
  const todayDate = new Date();
  return (
    <StyledDatePickerWrapper>
      <DatePicker
        showIcon
        icon={<CalendarMonthIcon />}
        dateFormat="d MMMM yyyy"
        selected={startDate}
        onChange={onChange}
        disabled={disbled}
        maxDate={today ? todayDate : !max ? threeYearsBack(3) : ""}
        minDate={minDate}
        placeholderText={placeholderText}
        className="custom-datepicker"
        // {...props}
      />
    </StyledDatePickerWrapper>
  );
};

export default DateField;
