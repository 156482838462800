import React, { useContext } from "react";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { Navigate, useLocation } from "react-router-dom";

function RoleBasedRouter({ children, ...props }) {
  const location = useLocation();
  const { state } = useContext(AppContext);
  const { token } = state;
  if (location.pathname !== "/login" && !token.access) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (location.pathname === "/login" && token.access) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }
  return children;
}

export default RoleBasedRouter;
