import React, { useContext, useEffect, useState } from "react";
import { getApi } from "../../Utility/network";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

function FinalConsumptionCertificate(props) {
  const { state } = useContext(AppContext);
  const { pdfId, id } = useParams();
  const [send, setSend] = useState(false);
  const [pdf, setPdf] = useState({ file: "" });
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseData = await getApi(
        `/records/${pdfId}/generate_energy_certificate/`
      );
      setPdf(responseData.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const printPDF = () => {
    window.open(pdf.file, "PRINT", "height=700,width=800");
  };

  const email = async () => {
    try {
      const responseData = await getApi(
        `/records/${id}/send_certificate_via_email`
      );
      setSend(true);
    } catch (error) {}
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "end",
          width: "90%",
          marginBottom: "30px",
        }}
      >
        <div>
          <Button
            variant="outlined"
            sx={{
              padding: "12px 25px",
              borderRadius: "10px",
              background: "white",
              // boxShadow: "none",
              // color: "white",
              // "&:disabled": {
              //   background: "gray",
              //   color: "#ccc",
              // },
              marginRight: "10px",
            }}
            onClick={printPDF}
          >
            Print
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            sx={{
              padding: "12px 25px",
              background: " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
              boxShadow: "none",
              borderRadius: "10px",
              color: "white",
              "&:disabled": {
                background: "gray",
                color: "#ccc",
              },
            }}
            onClick={() => email()}
          >
            {send ? "Email sent" : "Email"}
          </Button>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
          {pdf.file && (
            <iframe
              id="pdfViewer"
              src={`${pdf.file}#toolbar=0&navpanes=0&scrollbar=0`} // Disable the toolbar, navigation panes, and scrollbar
              title="PDF Viewer"
              style={{
                width: "80%",
                height: "100%",
                border: "none",
                backgroundColor: "white", // Set background color to white
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FinalConsumptionCertificate;
