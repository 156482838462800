import React, { useContext, useEffect, useState } from "react";
import styles from "./GenerateCertifiacte.module.css";
import CustomInput from "../CustomInput/CustomInput";
import upload from "../../assets/componentAssets/upload.png";
import FinalCertificate from "../FinalCertificate/FinalCertificate";
import CustomCheckBox from "../CustomCheckBox/CustomCheckBox";
import { Button, TextField } from "@mui/material";
import CostEffectiveTable from "../CostEffectiveTable/CostEffectiveTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UploadImage from "../UploadImage/UploadImage";
import axios from "axios";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { DeleteApi, PatchApi, base_url, getApi } from "../../Utility/network";
import { useNavigate } from "react-router-dom";
import deleteIcion from "../../assets/componentAssets/deleteIcon.png";
function GenerateCertifiacte({ show = false, setShow }) {
  const navigate = useNavigate();
  const { state: contextState, setState } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = useState();
  const [buildingImg, setBuildingImg] = useState([]);
  const [prevData, setPrevData] = useState({});
  const [submitData, setSubmitData] = useState({
    serial_number: null,
    tableData: [],
  });
  useEffect(() => {
    setOpen(show);
  }, [show]);
  console.log(submitData, "check iD");

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (image) {
      uploadImageApi();
    }
  }, [image]);

  const uploadImageApi = () => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("type", 2);
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .patch(
        `${base_url}/energy_consumption/form/${contextState.form_id}/upload_building_photos/`,
        formData,
        axiosConfig
      )
      .then((response) => {
        setImage();
        getPrevData(contextState.form_id);

      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getPrevData = async (id) => {
    try {
      const responseData = await getApi(`/energy_consumption/form/${id}/`);
      console.log(responseData.data.data, "finalData");
      setPrevData(responseData.data.data);
    } catch (error) {}
  };
  const DeleteImages = async (id) => {
    try {
      const response = await DeleteApi(`energy_consumption/building/${id}/`);
      getPrevData(contextState.form_id);
      // setBuildingImg(response.data.data.building_images);
    } catch (error) {}
  };
  const handleDeleteImg = (id) => {
    DeleteImages(id);
    console.log(id, "ids");
  };
  useEffect(() => {
    getPrevData(contextState.form_id);
  }, [contextState?.form_id]);
  useEffect(() => {
    if (prevData) {
      setSubmitData({
        serial_number: prevData?.serial_number,
        tableData: prevData?.recommendations,
      });
      setBuildingImg(prevData?.building_images);
    }
  }, [prevData]);
  console.log(buildingImg, "buildingImg");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmitData((prev) => ({ ...prev, [name]: value }));
  };
  const handleData = (_data) => {
    setSubmitData((prev) => ({ ...prev, tableData: _data }));
  };
  const handleSubmit = async () => {
    try {
      const postData = await PatchApi(
        `/energy_consumption/form/${contextState.form_id}/`,
        {
          serial_number: submitData.serial_number,
          recommendations: submitData.tableData,
        }
      );
      if (postData.status === 200 || postData.status === 201) {
        // setState((prev) => ({ ...prev, page: prev.page + 1 }));
        const postEnergy = await getApi(
          `energy_consumption/form/${contextState.form_id}/calculate_energy_consumption/`
        );
        if (postData.status === 200) {
          const generate_pdf = await getApi(
            `records/${postEnergy?.data?.data?.id}/generate_energy_certificate/`
          );
          if (generate_pdf.status === 200 || generate_pdf.status === 201) {
            setState((prev) => ({
              ...prev,
              pdfId: postEnergy?.data?.data?.id,
            }));
            if (postData.data.data.payment_status == 2) {
              navigate(
                `/consumption-certificate/${contextState.form_id}/${postEnergy?.data?.data?.id}`
              );
            } else {
              setShow(false);
            }
          }
        }
      }
    } catch (error) {}
  };

  return (
    <div className={styles.con}>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl" // You can use 'xs', 'sm', 'md', 'lg', 'xl' for different widths
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <p className={styles.name}>Serial number</p>
          <TextField
            // name={field.name}
            size="small"
            style={{ width: "100%" }}
            value={submitData.serial_number}
            name="serial_number"
            onChange={(e) => handleChange(e)}
          />

          <div className={styles.filecontainer}>
            <div className={styles.uploadcontainer}>
              <p className={styles.name}>Upload Signature</p>
              <UploadImage getImage={setImage} imgs={image} />
              <div style={{ marginTop: "20px" }}>
                {buildingImg?.map((item) => {
                  const filename = item.image.split("/").pop();
                  if (item.type !== 2) return;
                  return (
                    <div
                      style={{
                        background: "#AFDB2826",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className={styles.imageContainer}
                    >
                      <div>
                        <div>
                          <img
                            src={item.image}
                            style={{ marginRight: "15px", width: "80px" }}
                          />
                        </div>
                        <div>{filename}</div>
                      </div>

                      <div style={{ padding: "10px", cursor: "pointer" }}>
                        <img
                          src={deleteIcion}
                          onClick={() => {
                            handleDeleteImg(item?.id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <p className={styles.fieldName}>
              Recommendations for cost-effective modernization
            </p>
            <div className={styles.fieldDetailsCon}>
              <CostEffectiveTable
                serial={submitData.serial_number}
                handleData={handleData}
                prevData={submitData?.tableData}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" onClick={() => handleSubmit()} autoFocus>
            Generate Final Certificate
          </Button>
        </DialogActions>
      </Dialog>

      {/* <CustomCheckBox label={"Yes, I have checked every entries"} />
      <div className={styles.btns}>
        <Button variant="outlined">Preview</Button>
        <Button variant="contained">Generate Final Certificate</Button>
      </div>
      {open == true && (
        <FinalCertificate open={open} handleClose={handleClose} />
      )} */}
    </div>
  );
}

export default GenerateCertifiacte;
