import React, { lazy, useContext, useEffect, useState } from "react";
import styles from "./Step.module.css";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import info from "../../../assets/componentAssets/info.svg";
import { CheckBox } from "@mui/icons-material";
import CustomCheckBox from "../../../Components/CustomCheckBox/CustomCheckBox";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import property from "../../../assets/componentAssets/Property.png";
import NavButton from "./NavButton";
import { PatchApi, getApi } from "../../../Utility/network";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { monthYear } from "../utility/utils";
import FormHelperText from "@mui/material/FormHelperText";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";

function Step5() {
  const { state, setState } = useContext(AppContext);
  const [formD, setFormD] = useState(dd);
  const style = { width: "100%" };
  const [data, setData] = useState({ heating: [] });
  const [submitData, setSubmitData] = useState({
    living_space: "",
    building_part: "",
    ventilation_id: [],
    was_the_1977_thermal_insulation_regulation_met: false,

    does_property_have_basement: false,
    what_lies_below_the_ground_floor: null,

    are_renewable_energy_used: false,
    type_of_renewable_energy_id: [],
    energy_used_for_id: [],

    is_there_cooling_system: false,
    type_of_cooling_system_used_id: [],

    optional_information_abt_equipment: false,

    sun_protection: "",
    does_the_property_have_thermal_insulation: false,
    is_there_roof_or_attic_insulation: false,
  });
  const [ventilation, setVentaltion] = useState([]);
  const [coolings, setCoolings] = useState([]);
  const [renewable, setRenewable] = useState([]);
  const [renewableFor, setRenewableFor] = useState([]);
  const [pop, setPop] = useState(false);
  const [infoText, setInfoText] = useState([]);
  // New state variables for error messages
  const [errors1, setErrors1] = useState([]);
  console.log(errors1, "errors1errors1errors1");
  const [errors, setErrors] = useState({
    living_space: "",
    building_part: "",
    sun_protection: "",
  });
  const options = [];
  const [buildType, setBuildType] = useState([]);
  useEffect(() => {
    getData();
    getList();
  }, []);

  const getList = async () => {
    try {
      const ven = await getApi(`/energy_consumption/ventilation`);
      const cool = await getApi(`/energy_consumption/colling_system_types`);
      const renew = await getApi(`/energy_consumption/renewable_energy_types`);
      const renewFor = await getApi(`/energy_consumption/renewable_energy_for`);
      setVentaltion(ven.data.data);
      setCoolings(cool.data.data);
      setRenewable(renew.data.data);
      setRenewableFor(renewFor.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (
      state.otherData &&
      state.otherData?.yearArray &&
      state.otherData?.yearArray.length > 0
    ) {
      const err = state?.otherData?.yearArray.map((item, index) => {
        return { installation_year: "" };
      });
      setErrors1(err);
      setData((prev) => ({ ...prev, heating: state.otherData.yearArray }));
      setSubmitData((prev) => ({ ...prev, ...state.otherData.info }));
    }
  }, [state.otherData]);

  const getData = async () => {
    try {
      const responseData = await getApi(`/energy_consumption/building_type`);
      setBuildType(responseData.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (
      !submitData.living_space ||
      isNaN(submitData.living_space) ||
      submitData.living_space <= 0
    ) {
      newErrors.living_space =
        "Please enter a valid living space (positive number).";
    }
    if (!submitData.building_part) {
      newErrors.building_part = "Please select a building part.";
    }
    if (!submitData.sun_protection) {
      newErrors.sun_protection = "Please select a sun protection option.";
    }
    // data.heating
    // state.otherData.construction_year
    const { heating } = data;
    const currentYear = new Date().getFullYear();
    const err = [...errors1];
    for (let i = 0; i < heating.length; i++) {
      console.log(
        heating[i].installation_year < state.otherData.construction_year,
        "heating[i].installation_year"
      );
      if (
        heating[i].installation_year < state.otherData.construction_year ||
        heating[i].installation_year > currentYear
      ) {
        console.log("hellor errror");
        newErrors[`installation_year${i}`] = "error in date";
        err[
          i
        ].construction_year = `Year must be between ${state.otherData.construction_year} and ${currentYear}`;
      } else {
        err[i].construction_year = "";
        console.log("hellor errror");
      }
      console.log(err, "errerr");
      setErrors1(err);
    }
    return newErrors;
  };
  const validateArrayOfObjects = (arrayOfObjects) => {
    const errors = arrayOfObjects.map((obj) => {
      const errorObj = {}; // Create an error object for each item

      // Check if the key exists and its value is an empty string, null, or undefined
      if (
        obj.construction_year === "" ||
        obj.construction_year === null ||
        obj.construction_year === undefined
      ) {
        errorObj["construction_year"] = `This field cannot be empty.`; // Add error message
      }

      return errorObj;
    });

    return errors;
  };

  const handleSubmit = async (num, type) => {
    // Validate fields before submitting
    if (type === "next") {
      const validationErrors = validateFields();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return; // Prevent submission if there are validation errors
      }
    }
    try {
      if (type === "next") {
        try {
          const postData = await PatchApi(
            `/energy_consumption/form/${state.form_id}/`,
            { ...submitData, heating_info: data.heating }
          );
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              filledPage:
                prev.filledPage >= prev.page + 1
                  ? prev.filledPage
                  : prev.page + 1,
              page: prev.page + 1,
              otherData: {
                ...prev.otherData,
                info: submitData,
                yearArray: data.heating,
              },
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, page: prev.page - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_consumption/form/${state.form_id}/generate_link`);
        // navigate("/login");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };

  const handleField = (e) => {
    const { value, name } = e.target;
    setFormD((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange1 = (e) => {
    const { value, name } = e.target;
    setSubmitData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormD((prev) => ({ ...prev, [name]: value }));

    const newErrors = validateFields();

    // Remove the error for the specific field if it is valid
    if (value) {
      setErrors((prevErrors) => {
        // Copy the previous errors and remove the error for the specific field
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    } else {
      setErrors(newErrors);
    }
  };

  const handleRadioChange = (event) => {};

  const handleChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;
    // Check if the value is a boolean string
    if (value === "true") {
      parsedValue = true;
    } else if (value === "false") {
      parsedValue = false;
    }
    // Check if the value is a number string
    else if (!isNaN(value) && value.trim() !== "") {
      parsedValue = parseFloat(value);
    }
    setSubmitData((prev) => ({
      ...prev,
      [name]: parsedValue, // Assign the parsed value (boolean, number, or string)
    }));

    const newErrors = validateFields();

    // Remove the error for the specific field if it is valid
    if (value) {
      setErrors((prevErrors) => {
        // Copy the previous errors and remove the error for the specific field
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    } else {
      setErrors(newErrors);
    }
  };

  const handleCheckboxChange = (name, checkedIds) => {
    if (name === "ventilation_id") {
      setSubmitData((prev) => ({ ...prev, ventilation_id: checkedIds }));
    }
    if (name === "type_of_cooling_system_used_id") {
      setSubmitData((prev) => ({
        ...prev,
        type_of_cooling_system_used_id: checkedIds,
      }));
    }
    if (name === "type_of_renewable_energy_id") {
      setSubmitData((prev) => ({
        ...prev,
        type_of_renewable_energy_id: checkedIds,
      }));
    }
    if (name === "energy_used_for_id") {
      setSubmitData((prev) => ({
        ...prev,
        energy_used_for_id: checkedIds,
      }));
    }
  };

  const handlePopUp = (data) => {
    setPop(true);
    setInfoText(data);
  };
  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={property}
          heading={"Property Info"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          <div className={styles.fieldCom}>
            {data.heating.map((item, index) => {
              let value = null;
              if (
                item.consumption_info !== null &&
                item.consumption_info.length > 0
              ) {
                value = monthYear(item.consumption_info[0].date);
              }
              return (
                <div className={styles.fields}>
                  <p className={styles.fieldName}>
                    Year the {index + 1}st heating system was installed on{" "}
                    {value}
                    <img
                      src={info}
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePopUp(popData?.installed)}
                    />
                  </p>
                  {item.consumption}
                  <TextField
                    size="small"
                    value={item.installation_year}
                    style={style}
                    onChange={(e) => {
                      let newData = [...data.heating];
                      const value = e.target.value;
                      const currentYear = new Date().getFullYear();
                      newData[index].installation_year = e.target.value;
                      setData({ heating: newData });
                      if (
                        value < Number(state.otherData.construction_year) ||
                        value > currentYear
                      ) {
                        const err = [...errors1];
                        err[
                          index
                        ].construction_year = `Year must be between ${state?.otherData?.construction_year} and ${currentYear}`;
                        setErrors1(err);
                      } else {
                        const err = [...errors1];
                        err[index].construction_year = ``;
                        setErrors1(err);
                      }
                    }}
                    error={errors1[index].construction_year}
                    helperText={errors1[index].construction_year}
                    type="number"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">Kwh</InputAdornment>
                    //   ),
                    // }}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.fieldCom1}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Type of Ventilation
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.ventilation)}
                />
              </p>
              <CustomCheckBox
                data={ventilation}
                name="ventilation_id"
                onCheckChange={handleCheckboxChange}
                selectedValues={submitData.ventilation_id}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Living space (not building area)
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.livingSpace)}
                />
              </p>
              <TextField
                size="small"
                style={style}
                name="living_space"
                type="number"
                onChange={handleChange}
                error={!!errors.living_space}
                helperText={errors.living_space}
                value={submitData.living_space}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">Kwh</InputAdornment>
                //   ),
                // }}
              />
            </div>
          </div>
          <div className={styles.fieldCom1}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Was the 1977 thermal insulation regulation met?
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.thermal)}
                />
              </p>
              <FormControl>
                <RadioGroup
                  name="was_the_1977_thermal_insulation_regulation_met" // Name of the radio group
                  value={
                    submitData.was_the_1977_thermal_insulation_regulation_met
                  } // Bind the state value
                  onChange={handleChange} // Handle value changes
                >
                  <div className={styles.Radio}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="no"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Building Part
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.building)}
                />
              </p>
              <Select
                style={style}
                size="small"
                name="building_part"
                onChange={handleChange1}
                error={!!errors.building_part}
                helperText={errors.building_part}
                value={submitData.building_part}
              >
                {buildPart.map((option) => (
                  <MenuItem key={option.title} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
              {errors.building_part && (
                <p
                  style={{
                    fontSize: "12px",
                    margin: "4px 14px 0",
                    color: "#d32f2f",
                  }}
                >
                  {errors.building_part}
                </p>
              )}
            </div>
          </div>
          <div className={styles.fieldDetailsCon}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Does property have basement?</p>
              <RadioGroup
                name="does_property_have_basement" // Name of the radio group
                value={submitData.does_property_have_basement} // Bind the state value
                onChange={handleChange} // Handle value changes
              >
                <div className={styles.Radio}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="no"
                  />
                </div>
              </RadioGroup>
            </div>
            {submitData.does_property_have_basement && (
              <div className={styles.fields}>
                <p className={styles.fieldName}>
                  What lies below the ground floor?
                </p>
                <RadioGroup
                  name="what_lies_below_the_ground_floor" // Name of the radio group
                  value={submitData.what_lies_below_the_ground_floor} // Bind the state value
                  onChange={handleChange} // Handle value changes
                >
                  <div className={styles.Radio1}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Unheated basement"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Heated cellar"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="No basement available/ floor slab"
                    />
                  </div>
                </RadioGroup>
              </div>
            )}
          </div>
          <div className={styles.fieldDetailsCon}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Are renewables energy used?</p>
              <FormControl>
                <RadioGroup
                  name="are_renewable_energy_used" // Name of the radio group
                  value={submitData.are_renewable_energy_used} // Bind the state value
                  onChange={handleChange} // Handle value changes
                >
                  <div className={styles.Radio}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="no"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <div className={styles.fieldCom}>
              {submitData.are_renewable_energy_used && (
                <div className={styles.fields}>
                  <p className={styles.fieldName}>What do you use?</p>
                  <CustomCheckBox
                    data={renewable}
                    name="type_of_renewable_energy_id"
                    onCheckChange={handleCheckboxChange}
                    selectedValues={submitData.type_of_renewable_energy_id}
                  />
                </div>
              )}
              {submitData.are_renewable_energy_used && (
                <div className={styles.fields}>
                  <p className={styles.fieldName}>
                    What are renewables energy used for?
                  </p>
                  <CustomCheckBox
                    data={renewableFor}
                    name="energy_used_for_id"
                    onCheckChange={handleCheckboxChange}
                    selectedValues={submitData.energy_used_for_id}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.fieldDetailsCon}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Is there a cooling system?</p>
              <FormControl>
                <RadioGroup
                  name="is_there_cooling_system" // Name of the radio group
                  value={submitData.is_there_cooling_system} // Bind the state value
                  onChange={handleChange} // Handle value changes
                >
                  <div className={styles.Radio}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="no"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
              {submitData.is_there_cooling_system && (
                <div>
                  <CustomCheckBox
                    data={coolings}
                    name="type_of_cooling_system_used_id"
                    onCheckChange={handleCheckboxChange}
                    selectedValues={submitData.type_of_cooling_system_used_id}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.fieldDetailsCon}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Would you like to provide optional information about the
                equipment?
              </p>
              <RadioGroup
                name="optional_information_abt_equipment" // Name of the radio group
                value={submitData.optional_information_abt_equipment} // Bind the state value
                onChange={handleChange} // Handle value changes
              >
                <div className={styles.Radio}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="no"
                  />
                </div>
              </RadioGroup>
            </div>
            {submitData.optional_information_abt_equipment && (
              <div className={styles.fields}>
                <p className={styles.fieldName}>
                  Which sun protection is available?
                </p>
                <FormHelperText style={{ color: "red" }}>
                  {errors.sun_protection}
                </FormHelperText>
                <FormControl>
                  <RadioGroup
                    name="sun_protection" // Name of the radio group
                    value={submitData.sun_protection} // Bind the state value
                    onChange={handleChange} // Handle value changes
                    error={!!errors.sun_protection}
                  >
                    <div className={styles.Radio1}>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="No sun/glare protection"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Sun and/or flare protection (roller shutters, blinds), automatically operated"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Only glare protection (mostly in office building)"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="Only glare protection, light-directing (mostly in office buildings)"
                      />
                    </div>
                  </RadioGroup>

                  {/* {errors.sun_protection && (
                  <p style={{fontSize:"12px", margin:"4px 14px 0", color:"#d32f2f"}}>{errors.sun_protection}</p>
                )} */}
                </FormControl>
              </div>
            )}
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Does the property have thermal insulation/insulation glazing?
              </p>
              <RadioGroup
                name="does_the_property_have_thermal_insulation" // Name of the radio group
                value={submitData.does_the_property_have_thermal_insulation} // Bind the state value
                onChange={handleChange} // Handle value changes
              >
                <div className={styles.Radio}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="no"
                  />
                </div>
              </RadioGroup>
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Is there roof or attic insulation?
              </p>
              <FormControl>
                <RadioGroup
                  name="is_there_roof_or_attic_insulation" // Name of the radio group
                  value={submitData.is_there_roof_or_attic_insulation} // Bind the state value
                  onChange={handleChange} // Handle value changes
                >
                  <div className={styles.Radio}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="no"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
      {pop && (
        <PopUpInfo
          handleClose={() => setPop(false)}
          open={pop}
          data={infoText}
        />
      )}
    </div>
  );
}

export default Step5;
const Ventilations = [
  { id: 1, label: "Windows Ventilation" },
  { id: 2, label: "Shaft Ventilation" },
  { id: 3, label: "Ventilation system without heat recovery" },
  { id: 4, label: "Ventilation system without heat recovery" },
  { id: 5, label: "Exhaust air system" },
];
const Energy = [
  { id: 1, label: "Solar thermal energy" },
  { id: 2, label: "Photovoltaics" },
  { id: 3, label: "Heat pump" },
  { id: 4, label: "Biomass" },
];
const renewables = [
  { id: 1, label: "Heating" },
  { id: 2, label: "Hotwater" },
  { id: 3, label: "Electricity" },
];
const cooling = [
  { id: 1, label: "Cooling from electricity" },
  { id: 2, label: "Delivered cold" },
  { id: 3, label: "Passive cooling" },
  { id: 4, label: "Cooling from heat" },
];

const dd = {
  was_the_1977_thermal_insulation_regulation_met: false,
  building_part: null,
  ventilation_id: [],
  type_of_renewable_energy_id: [],
  energy_used_for_id: [],
  is_there_cooling_system: "",
  type_of_cooling_system_used_id: [],
  living_space: null,

  does_property_have_basement: false,
  what_lies_below_the_ground_floor: null,

  are_renewable_energy_used: false,
  type_of_renewable_energy: null,
  energy_used_for: null,

  is_there_cooling_system: false,
  type_of_cooling_system_used: null,

  optional_information_abt_equipment: false,
  sun_protection: null,
  does_the_property_have_thermal_insulation: null,
  is_there_roof_or_attic_insulation: null,
};

const buildPart = [
  {
    title: "Total building",
    id: 1,
  },
  {
    title: "Residential part of mixed building",
    id: 2,
  },
];

const popData = {
  installed: [
    {
      heading: "Year of construction of the heating system",
      para1: `The year of manufacture of the heating system can be found in the exhaust gas measurement report, in the operating instructions, on the type plate, in the acceptance report, etc. If you use district heating, please state the year of manufacture of the district heating transfer station. If your boiler has been modernized, please state the year of modernization. The year the burner was modernized is not relevant. If you have several devices, please state the predominant year of manufacture.`,
    },
  ],
  ventilation: [
    {
      heading: "Type Of Ventilation",
      para1: `How is the building ventilated? Examples of ventilation systems without heat recovery are exhaust air systems in rooms without windows, such as bathrooms. Ventilation systems with heat recovery include, for example, central or decentralized supply and exhaust air systems that are equipped with heat exchangers.`,
    },
  ],
  livingSpace: [
    {
      heading: "Living space (not building area) ",
      para1: `The energy certificate is always linked to the respective building. We therefore ask you to state the living space of the entire building (excluding the basement) in square meters. Based on this information, the usable area of ​​the building is automatically calculated in the energy certificate.`,
    },
  ],
  building: [
    {
      heading: "Building part",
      para1: `In the local sense, a building is only considered a mixed building if two separate energy certificates have to be issued. This is the case if the commercial and residential areas are in a closer ratio than 90% and 10% to each other. In all other cases, "residential building" must be selected.
`,
      para2: `Furthermore, a joint energy certificate can be issued if the heating system does not differ significantly.
`,
      para3: `We do not currently issue energy certificates for non-residential buildings.`,
    },
  ],
  thermal: [
    {
      heading: "Was the 1977 thermal insulation regulation met?",
    },
  ],
};
