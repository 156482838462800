import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomInput from "../CustomInput/CustomInput";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomCheckBox from "../CustomCheckBox/CustomCheckBox";
import AddIcon from "@mui/icons-material/Add";
import { Height, Margin } from "@mui/icons-material";
import styles from "./CostEffectiveTable.module.css";
function CostEffectiveTable({ serial, handleData, prevData }) {
  const options = [];
  const style = { width: "100%" };
  const cellStyle = {
    textAlign: "center",
    border: "none",
  };
  const heads = { minWidth: "170px" };
  const [tableData, setTableData] = React.useState([
    {
      title: "",
      description: "",
      in_connection_with_major_modernization: false,
      as_an_individual_major: false,
      estimated_payback_period: "",
      index: 1,
    },
  ]);
  console.log(prevData, "prev");

  React.useEffect(() => {
    if (prevData?.length > 0) {
      setTableData(prevData);
    }
  }, []);
  console.log(tableData, "tableData");

  React.useEffect(() => {
    handleData(tableData);
  }, [tableData]);
  const addRow = () => {
    setTableData([
      ...tableData,
      {
        index: tableData.length + 1,
        title: "",
        description: "",
        in_connection_with_major_modernization: false,
        as_an_individual_major: false,
        estimated_payback_period: "",
      },
    ]);
  };
  const handleChange = (e, row, index) => {
    const { name, value } = e.target;
    setTableData((prev) => {
      let newData = { ...prev[index] };
      // Update the specific field in the copied row
      newData[name] = value;
      // Return the updated state
      const updatedData = [...prev];
      updatedData[index] = newData;

      return updatedData;
    });
  };
  const handleChanges = (e, row, index) => {
    const { name, checked } = e.target;
    setTableData((prev) => {
      let newData = { ...prev[index] };
      // Update the specific field in the copied row
      newData[name] = checked;
      // Return the updated state
      const updatedData = [...prev];
      updatedData[index] = newData;
      return updatedData;
    });
  };
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.headBorders} sx={heads}>
                Components
              </TableCell>
              <TableCell className={styles.headBorders} sx={heads}>
                Description
              </TableCell>
              <TableCell className={styles.headBorders} sx={heads}>
                {" "}
                In connection with major modernization
              </TableCell>
              <TableCell className={styles.headBorders} sx={heads}>
                As an individual measure
              </TableCell>
              <TableCell className={styles.headBorders} sx={heads}>
                Estimated payback period
              </TableCell>

              {/* <TableCell sx={heads}>
                In connection with major modernization
              </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell align="center" sx={cellStyle}>
                  <div style={{ minWidth: "220px" }}>
                    <TextField
                      size="small"
                      style={style}
                      name="title"
                      value={row.title}
                      onChange={(e) => handleChange(e, row, index)}
                    />
                  </div>
                </TableCell>
                <TableCell align="center" sx={cellStyle}>
                  <div style={{ minWidth: "320px" }}>
                    <TextField
                      size="small"
                      style={style}
                      name="description"
                      onChange={(e) => handleChange(e, row, index)}
                      value={row.description}
                    />
                  </div>
                </TableCell>
                <TableCell align="center" sx={cellStyle}>
                  <div style={{ minWidth: "220px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="in_connection_with_major_modernization"
                          checked={row.in_connection_with_major_modernization}
                          onChange={(e) => handleChanges(e, row, index)}
                          color="primary" // You can set the color to 'primary', 'secondary', etc.
                        />
                      }
                    />
                  </div>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <div style={{ minWidth: "220px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="as_an_individual_major"
                          checked={row.as_an_individual_major}
                          onChange={(e) => handleChanges(e, row, index)}
                          color="primary" // You can set the color to 'primary', 'secondary', etc.
                        />
                      }
                    />
                  </div>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <div style={{ minWidth: "220px" }}>
                    <TextField
                      size="small"
                      style={style}
                      name="estimated_payback_period"
                      value={row.estimated_payback_period}
                      onChange={(e) => handleChange(e, row, index)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Button startIcon={<AddIcon />} variant="contained" onClick={addRow}>
          {" "}
          Add
        </Button>
      </div>
    </>
  );
}

export default CostEffectiveTable;
