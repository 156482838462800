import React, { useContext, useEffect, useRef, useState } from "react";
import NavButton from "./NavButton";
import { getApi } from "../../../Utility/network";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import styles from "./Step.module.css";
import CustomCheckBox from "../../../Components/CustomCheckBox/CustomCheckBox";

function Step7(props) {
  const scrollRef = useRef(null);
  const { state, setState } = useContext(AppContext);
  const [pdf, setPdf] = useState({ file: "" });
  const [submitData, setSubmitData] = useState({ review: [] });
  useEffect(() => {
    getPdfId();
  }, []);

  const getPdfId = async () => {
    try {
      const postEnergy = await getApi(
        `energy_consumption/form/${state.form_id}/calculate_energy_consumption/`
      );
      console.log(postEnergy.data.data, "postEnergy");
      if (postEnergy.status === 200) {
        const generate_pdf = await getApi(
          `records/${postEnergy?.data?.data?.id}/generate_energy_certificate/`
        );
        if (generate_pdf.status === 200 || generate_pdf.status === 201) {
          getData(postEnergy?.data?.data?.id);
        }
      }
    } catch (error) {
      alert("something went wrong");
    }
  };

  const getData = async (pdf) => {
    try {
      const responseData = await getApi(
        `/records/${pdf}/generate_energy_certificate/`
      );
      setPdf(responseData.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (num, type) => {
    if (type === "next") {
      setState((prev) => ({
        ...prev,
        page: prev.page + 1,
        filledPage:
          prev.filledPage >= prev.page + 1 ? prev.filledPage : prev.page + 1,
      }));
    } else {
      setState((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };
  const handleCheckboxChange = (name, checkedIds) => {
    setSubmitData({ review: checkedIds });
  };

  const handleClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start", // You might want to use "start" to scroll to the top of the element
      });
    } else {
      console.log("scrollRef.current is null");
    }
  };

  return (
    <div>
      <div
        style={{
          width: "80%",
          margin: "auto",
        }}
      >
        <p
          style={{
            fontSize: "28px",
            color: "#22A156",
            fontWeight: "900",
            marginBottom: "10px",
          }}
        >
          Your Energy Certificate is Available Now
        </p>
        <p>
          Here is your simplified sample copy with your data. Please note that
          this is not the original ID card. You will receive the original after
          we review your order.
        </p>
        <p style={{ marginTop: "30px", color: "#000000CC" }}>
          Please review all the data again and then click{" "}
          <span
            onClick={handleClick}
            style={{ color: "#22A156", cursor: "pointer" }}
          >
            <b>continue</b>
          </span>
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
          {pdf.file && (
            <iframe
              src={`${pdf.file}#toolbar=0&navpanes=0&scrollbar=0`} // Disable the toolbar, navigation panes, and scrollbar
              title="PDF Viewer"
              style={{
                width: "80%",
                height: "100%",
                border: "none",
                backgroundColor: "white", // Set background color to white
              }}
            />
          )}
        </div>
      </div>
      <div style={{ width: "80%", margin: "auto" }} ref={scrollRef}>
        <div className={styles.fields} style={{ fontWeight: "900" }}>
          <p className={styles.fieldName}></p>
          <CustomCheckBox
            data={data}
            name="review"
            onCheckChange={handleCheckboxChange}
            selectedValues={submitData.review}
          />
        </div>
      </div>
      <NavButton
        handleSubmit={handleSubmit}
        disabled={submitData.review.length > 0 ? false : true}
      />
    </div>
  );
}

export default Step7;

const data = [
  {
    title: "I reviewed my entries and energy values and found no errors.",
    id: 1,
  },
];
