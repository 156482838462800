import React, { useContext, useEffect } from "react";
import CertificateTable from "../../Components/Greenox/CertificateTable/CertificateTable";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

function Consumption(props) {
  const { setState } = useContext(AppContext);
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      otherData: {
        hotwater_included_in_heating_system: 0,
        construction_year: null,
        heating_info: [],
        yearArray: [],
        buildingData: {},
        buildingData: {},
        vacancy: [],
        noOfHeatingSystem: null,
        property: null,
        delAdd: null,
        info: {},
      },
      form_id: null,
      page: 1,
      filledPage: 1,
    }));
  }, []);
  return (
    <div>
      <CertificateTable />
    </div>
  );
}

export default Consumption;
