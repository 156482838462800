import React from "react";
import styles from "./Footer.module.css";
import drawerIcon from "../../assets/dashboard/heritageLogo.png";
import google from "../../assets/dashboard/google.png";
import footerBtm from "../../assets/dashboard/footerBtm.svg";
import { Rating } from "@mui/material";
import payment1 from "../../assets/dashboard/payment1.svg";
import payment2 from "../../assets/dashboard/payment2.svg";
import payment3 from "../../assets/dashboard/payment3.svg";
import payment4 from "../../assets/dashboard/payment4.svg";
import payment5 from "../../assets/dashboard/payment5.svg";
import payment6 from "../../assets/dashboard/payment6.svg";
import payment7 from "../../assets/dashboard/payment7.svg";

function Footer() {
  return (
    <div className={styles.allcon}>
      <div className={styles.con}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <img src={drawerIcon} />
            <p className={styles.address}>
              GREENOX GmbH
              <br />
              Hauptstätter Straße 106B <br />
              70178 Stuttgart
            </p>
          </div>
          <p className={styles.address}>
            M: info@greenox-group.de
            <br />
            T: +49 711 5405 4947
          </p>
          <div className={styles.google}>
            <img src={google} />
            <div>
              <p className={styles.gName}>Google review</p>
              <div className={styles.rate}>
                <span className={styles.address}>4.9</span>
                <Rating name="read-only" value={4.9} readOnly />
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <img src={footerBtm} />

            {/* <div className={styles.text}>
                <p className={styles.heading}></p>
            </div> */}
          </div>
        </div>
        <div className={styles.middle}>
          <p className={styles.payment}>Our Payment Methods</p>
          <div className={styles.images}>
            {payment.map((item) => {
              return <img src={item.img} />;
            })}
          </div>
        </div>
        <div className={styles.right}>
            
          <p className={styles.payment}>Energy Certificates</p>
          <p className={styles.certificate}>create a consumption certificate</p>
          <p className={styles.certificate}>create a needs certificate</p>
        </div>
      </div>
      <div className={styles.btmCon}>
        <div className={styles.hr} />
        <p className={styles.address}>Copyright © 2024</p>
      </div>
    </div>
  );
}

export default Footer;

const payment = [
  { id: 1, img: payment1 },
  { id: 2, img: payment2 },
  { id: 3, img: payment3 },
  { id: 4, img: payment4 },
  { id: 5, img: payment5 },
  { id: 6, img: payment6 },
  { id: 7, img: payment7 },
];
