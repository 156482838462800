import CryptoJS from "crypto-js";
const ivHex = "776873626468676e746b676e676d686b"; // AES_IV (16 bytes)
const keyHex = "736f6d6572616e646f6d736563726574"; // AES_SECRET_KEY (16 bytes)

export function encryptAES(data) {
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    // Convert JSON object to string
    const jsonData = JSON.stringify(data);
    // Encrypt
    const encrypted = CryptoJS.AES.encrypt(jsonData, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });

    // Convert encrypted ciphertext to Base64
    const encryptedBase64 = encrypted.toString();
    return encryptedBase64;
}

export function dycryptAES(encryptedBase64) {
    // Convert hex key and iv to WordArray
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    // Convert Base64 encoded ciphertext to WordArray
    const ciphertext = CryptoJS.enc.Base64.parse(encryptedBase64);
    // Decrypt
    const decrypted = CryptoJS.AES.decrypt(
        {
            ciphertext: ciphertext,
        },
        key,
        {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        }
    );
    // Convert decrypted WordArray to UTF-8 string
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedText);
}