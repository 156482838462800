import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

function CustomCheckBox({ data, name, selectedValues, onCheckChange }) {
  const [checkedIds, setCheckedIds] = useState(selectedValues || []); // Initialize state with selectedValues

  // Handle checkbox change
  const handleCheckChange = (event) => {
    const { value } = event.target;
    const numericValue = isNaN(value) ? value : Number(value); // Convert to number if it's a numeric string

    // Update checkedIds based on current selection
    if (checkedIds.includes(numericValue)) {
      setCheckedIds(checkedIds.filter((id) => id !== numericValue));
    } else {
      setCheckedIds([...checkedIds, numericValue]);
    }
  };

  // Sync with selectedValues prop if it changes
  useEffect(() => {
    setCheckedIds(selectedValues || []);
  }, [selectedValues]);

  // Notify parent component about the changes
  useEffect(() => {
    onCheckChange(name, checkedIds); // Call the parent's function
  }, [checkedIds]);

  return (
    <FormGroup name={name}>
      {data.map((item) => (
        <FormControlLabel
          key={item.id}
          value={item.id}
          control={
            <Checkbox
              checked={checkedIds.includes(item.id)} // Reflect selected values
              onChange={handleCheckChange}
            />
          }
          label={item.title}
        />
      ))}
    </FormGroup>
  );
}

export default CustomCheckBox;
