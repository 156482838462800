import React, { useContext } from "react";
import "./App.css";
// import Filter from './Components/Filter';
// import FileUpload from '../../component/src/Components/Greenox/FileUpload/FileUpload'
// import CertificateUpload from './Components/Greenox/CertificateUpload/CertificateUpload';
// import Login from './Components/Greenox/Login/Login';
// import LoginPage from './Components/Greenox/LoginPage/LoginPage';
// import Header from './Components/Greenox/Header/Header';
// import CustomTable from './Components/Greenox/CustomTable/CustomTable';
import CertificateTable from "./Components/Greenox/CertificateTable/CertificateTable";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { AppContext } from "./ContextApi/AppContext/AppContext";
import { routerRoutes } from "./Utility/routes";

const router = createBrowserRouter(routerRoutes);

function App() {
  const { theme } = useContext(AppContext);
  return (
    <div className="App">
      {/* <Filter /> */}
      {/* <FileUpload/> */}
      {/* <CertificateUpload/> */}
      {/* <LoginPage/> */}
      {/* <Header/> */}
      {/* <CertificateTable/> */}
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
