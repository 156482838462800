import axios from "axios";
import { handleToken } from "./CheckToken";

// Base URL Configuration
const baseURL = process.env.REACT_APP_API_URL || "http://43.204.177.46"; // Adjust to your API

const getApiBaseUrl = () => {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return "http://43.204.177.46";
  } else if (hostname.includes("greenox")) {
    return "https://prod-api.greenox-group.de";
  } else {
    return "http://43.204.177.46"; // fallback URL
  }
};
export const base_url = getApiBaseUrl();
// Create an Axios Instance with Default Configuration
export const api = axios.create({
  baseURL: base_url,
  timeout: 10000,
  headers: {
    // 'Content-Type': 'application/json', // Common header
    "Content-Type": "application/json",
    // Add other default headers if needed
  },
});

// Request Interceptor (Optional)
// api.interceptors.request.use(
//   (config) => {
//     // Modify the request config (e.g. add authorization headers)
//     // const token = localStorage.getItem('token'); // Example: Get token from local storage
//     let token = handleToken();
//     if (token && token.access) {
//       config.headers.Authorization = `Bearer ${token.access}`;
//     }
//     console.log(config.headers, "token");

//     if (config.data) {
//       // config.data = encryptAES(config.data);
//       // various ways to transform data
//       // config.data.newProperty = 'newValue';
//       // config.data = {
//       //     ...config.data,
//       //     transformedProperty: config.data.originalProperty * 2,
//       // };
//     }
//     return config;
//   },
//   (error) => {
//     // Handle request errors
//     return Promise.reject(error);
//   }
// );

// Response Interceptor (Optional - For Global Error Handling)
api.interceptors.response.use(
  (response) => {
    // Modify the response data if needed
    // if (response.status === 204) return { data: {} };
    // const dycryptedData = dycryptAES(response.data);
    return response; // By default, return data directly
  },
  (error) => {
    // Handle response errors globally
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.error('Response error:', error.response.data);
      // Handle specific error codes (e.g., 401 Unauthorized, 500 Internal Server Error)
      if (error.response && error.response.status === 401) {
        const originalRequest = error.config; // Get original request config
        const url = originalRequest.url;
        // logoutUser
        // if (url !== "/auth/login") {
        //   sessionStorage.clear();
        //   Cookies.remove(LOCAL_STRING);
        //   window.location.href = "/";
        // }
        return error.response;
        // return Promise.reject(error);
      }
      // const encryptedData = error.response.data;
      try {
        // const decryptedData = dycryptAES(encryptedData);
        // Handle the decrypted data as needed
        // console.log("Decrypted data:", decryptedData);
        // You might want to re-throw the error or take some other action
        // depending on what the decrypted data contains or indicates

        // return Promise.reject(error); // Propagate the error to your components
        return error;
      } catch (decryptError) {
        // return Promise.reject(error); // Propagate the error to your components
        return error;
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Request error:", error.request);
      // return Promise.reject(error); // Propagate the error to your components
      return error;
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message);
      // return Promise.reject(error); // Propagate the error to your components
      return error;
    }
  }
);

// Reusable API Function

export const getApi = async (endpoint) => {
  try {
    const response = await api.get(`${endpoint}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const PostApi = async (endpoint, postData) => {
  try {
    const response = await api.post(`${endpoint}`, postData);
    return response;
  } catch (error) {
    return error;
  }
};

export const PatchApi = async (endpoint, postData) => {
  try {
    const response = await api.patch(`${endpoint}`, postData);
    return response;
  } catch (error) {
    return error;
  }
};

export const PutApi = async (endpoint, postData) => {
  try {
    const response = await api.put(`${endpoint}`, postData);
    return response;
  } catch (error) {
    return error;
  }
};
export const DeleteApi = async (endpoint) => {
  try {
    const response = await api.delete(`${endpoint}`);
    return response;
  } catch (error) {
    return error;
  }
};

// Add more API functions (updateItem, deleteItem, etc.) as needed

export default api;
