import { Navigate } from "react-router-dom";
import Onboarding from "../Onboarding/Onboarding";
import DrawerComponent from "../Components/DrawerComponent/DrawerComponent";
import PageNotFound from "../Pages/PageNotFound";
import Dashboard from "../Pages/Dashboard";
import RoleBasedRouter from "../AppRouter/RoleBasedRouter/RoleBasedRouter";
import Consumption from "../Pages/Consumption/Consumption";
import Energy from "../Pages/Energy/Energy";
import ConsumptionForm from "../Pages/Consumption/ConsumptionForm/ConsumptionForm";
import NeedForm from "../Pages/Need/ConsumptionForm/ConsumptionForm";
import FormDataRestoration from "../Pages/FormDataRestoration/FormDataRestoration";
import EnergyCertificate from "../Pages/Form/EnergyCertificate/EnergyCertificate";
import NeedCertificate from "../Pages/Form/NeedCertificate/NeedCertificate";
import Form from "../Pages/Form/Form";
import Failure from "../Pages/Form/EnergyCertificate/Payment/Failure";
import Success from "../Pages/Form/EnergyCertificate/Payment/Success";
import ResumeEnergy from "../Pages/ResumeEnergy/ResumeEnergy";
import ResumeConsumption from "../Pages/ResumeConsumption/ResumeConsumption";
import FinalConsumptionCertificate from "../Pages/FinalConsumptionCertificate/FinalConsumptionCertificate";

export const routerRoutes = [
  {
    path: "/login",
    element: (
      <RoleBasedRouter>
        {" "}
        <Onboarding />
      </RoleBasedRouter>
    ),
  },
  {
    path: "/resume-form-energyneed/:uuid",
    element: <ResumeEnergy />,
  },
  {
    path: "/resume-form/:uuid",
    element: <ResumeConsumption />,
  },
  {
    path: "/form",
    element: <Form />,
    children: [
      {
        index: true,
        element: <Navigate to="/form/de/consumption-certificate" replace />,
      },
      {
        path: "/form/:type/consumption-certificate",
        element: <EnergyCertificate />,
      },
      {
        path: "/form/:type/needs-certificate",
        element: <NeedCertificate />,
      },
    ],
  },

  // {
  //   path: "/form/:unique_id",
  //   element: <FormDataRestoration />,
  // },
  {
    path: "/payment/success/:id",
    element: <Success />,
  },
  {
    path: "/payment/failure/:id",
    element: <Failure />,
  },
  {
    path: "/",
    element: (
      <RoleBasedRouter>
        <DrawerComponent />
      </RoleBasedRouter>
    ),
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Navigate to="/dashboard" replace /> },
      {
        path: "dashboard",
        element: (
          <RoleBasedRouter>
            <Dashboard />
          </RoleBasedRouter>
        ),
      },
      {
        path: "consumption-certificate",
        element: (
          <RoleBasedRouter>
            <Consumption />
          </RoleBasedRouter>
        ),
      },
      {
        path: "consumption-certificate/:id",
        element: (
          <RoleBasedRouter>
            <ConsumptionForm />
          </RoleBasedRouter>
        ),
      },
      {
        path: "consumption-certificate/:id/:pdfId",
        element: (
          <RoleBasedRouter>
            <FinalConsumptionCertificate />
          </RoleBasedRouter>
        ),
      },
      {
        path: "energy-certificate",
        element: (
          <RoleBasedRouter>
            <Energy />
          </RoleBasedRouter>
        ),
      },
      {
        path: "energy-certificate/:id",
        element: (
          <RoleBasedRouter>
            <NeedForm />
          </RoleBasedRouter>
        ),
      },
      {
        path: "energy-certificate/:id/:pdfId",
        element: (
          <RoleBasedRouter>
            <FinalConsumptionCertificate />
          </RoleBasedRouter>
        ),
      },
    ],
  },
];
