import React from "react";
import styles from "../Onboarding.module.css";
import { PRIMARYCOLOR, TERNARYCOLOR } from "../../Utility/constants";

function ImageryText({ image, primaryText, showBorder = true, ...props }) {
  return (
    <div style={{textAlign:'center'}}>
      <img
        alt="icon"
        src={image}
        style={{
          color: PRIMARYCOLOR,
          padding: "5px",
        }}
      />
      <p className={styles.logTitle} style={{ color: `${TERNARYCOLOR}` }}>
        {primaryText}
      </p>
      <p className={styles.logSecTitle} style={{ color: `${TERNARYCOLOR}` }}>
        {props.children}
      </p>
    </div>
  );
}

export default ImageryText;
