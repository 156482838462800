export const form6State = {
  step6: {
    house: "",
    postaCode: "",
    location: "",

    errors: {
      house: "",
      postaCode: "",
      location: "",
    },
  },
};
