import React from "react";
import styles from "./StepsHeader.module.css";
function StepsHeader({ image, heading, ...props }) {
  return (
    <div className={styles.top}>
      <div>
        <img src={image} />
      </div>
      <div className={styles.text}>
        <h3 className={styles.head}>{heading}</h3>
        {props.secPara && <p className={styles.desc}>{props.secPara}</p>}
      </div>
    </div>
  );
}

export default StepsHeader;
