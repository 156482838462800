import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Step.module.css";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import Heating from "../../../assets/componentAssets/Heating.png";
import info from "../../../assets/componentAssets/info.svg";
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DateField from "../../../Components/DateField/DateField";
import { PatchApi, getApi } from "../../../Utility/network";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { YYMMDD, monthYear, threeYearsBack } from "../utility/utils";
import { dateToYYYYMMDD, zdate } from "../../../Utility/helper";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";
import { errorMessage } from "../../../Utility/constants";
function Step2() {
  const { state, setState } = useContext(AppContext);
  console.log(state,'statestate')
  const [show, setShow] = useState(0);
  const [buttonError, setButtonError] = useState("");
  const [data, setData] = useState({
    number_of_heating_systems: null,
    heating_info: [],
  });
  const latestDataRef = useRef(data); // Create a ref for data

  const [form, setForm] = useState({
    Date: new Date(),
  });
  const [energy, setEnergy] = useState({ source: [], unit: [] });
  const [unit, setUnit] = useState(Array.from({ length: 4 }, () => []));
  const [pop, setPop] = useState(false);
  const [infoText, setInfoText] = useState([]);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  // Update the ref whenever data changes
  useEffect(() => {
    latestDataRef.current = data;
  }, [data]);
  useEffect(() => {
    // return () => {
    //   let data1 = JSON.stringify(latestDataRef.current);
    //   data1 = JSON.parse(data1);
    //   const updatedHeatingInfo = data1.heating_info.map((info) => ({
    //     ...info,
    //     consumption_info: info.consumption_info.map((item) => ({
    //       ...item,
    //       date: zdate(item.date), // Update the date format
    //     })),
    //   }));
    //   setState((prev) => ({
    //     ...prev,
    //     otherData: {
    //       ...prev.otherData,
    //       yearArray: updatedHeatingInfo,
    //       noOfHeatingSystem: data.number_of_heating_systems,
    //     },
    //   }));
    // };
  }, []);
  useEffect(() => {
    const { noOfHeatingSystem, yearArray } = state.otherData;
    yearArray.forEach((item, index) => {
      if (energy.source.length > 0) {
        let newIndex = energy.source.findIndex(
          (obj) => item.energy_source_id === obj.id
        );
        if (newIndex !== -1) {
          unit[index] = energy.source[newIndex].unit;
          setUnit((prev) => [...unit]);
        }
      }
    });
    if (yearArray && yearArray.length > 0) {
      setData({
        heating_info: yearArray,
        number_of_heating_systems: noOfHeatingSystem,
      });
      setShow(noOfHeatingSystem);
    }
  }, [state.otherData.noOfHeatingSystem, energy]);
  const getData = async () => {
    try {
      const source = await getApi(`/energy_consumption/energy_source`);
      const unit = await getApi(`/energy_consumption/energy_source_units`);
      setEnergy({ source: source.data.data, unit: unit.data.data });
    } catch (error) {}
  };
  const style = { width: "100%" };
  const handleClick = (_count) => {
    const dataObject = heating_info; // Object structure for each form
    const newData = [...data.heating_info]; // Copy the current data array

    // If the array length is less than the clicked number, add more objects
    if (newData.length < _count) {
      const objectsToAdd = _count - newData.length;

      for (let i = 0; i < objectsToAdd; i++) {
        newData.push({
          ...dataObject,
          index: i + 1,
          hotwater_included_in_heating_system: 1,
        });
      }

      // If the array length is more than the clicked number, remove extra objects
    } else if (newData.length > _count) {
      newData.splice(_count); // Trim the array to the clicked length
    }

    // Update the state only if there is a change
    if (newData.length !== data.length) {
      setData((prev) => ({
        ...prev,
        heating_info: newData,
        number_of_heating_systems: _count,
      }));
      setState((prev) => ({
        ...prev,
        otherData: { ...prev.otherData, yearArray: newData },
      }));
    }
  };

  const handleSubmit = async (num, type) => {
    try {
      let data1 = JSON.stringify(data);
      data1 = JSON.parse(data1);
      const updatedHeatingInfo = data1.heating_info.map((info) => ({
        ...info,
        consumption_info: info.consumption_info.map((item) => ({
          ...item,
          date: zdate(item.date), // Update the date format
        })),
      }));
      if (type === "next") {
        if (show == 0) {
          setButtonError("Please select number of heating systems installed");
          return;
        }
        setState((prev) => ({
          ...prev,
          otherData: {
            ...prev.otherData,
            yearArray: updatedHeatingInfo,
            noOfHeatingSystem: data.number_of_heating_systems,
          },
        }));
        const errors = validateHeatingInfoArray(updatedHeatingInfo);
        const checkError = isAllFieldsEmpty(errors);
        setErrors(errors);
        if (!checkError) return;
        try {
          const postData = await PatchApi(
            `/energy_consumption/form/${state.form_id}/`,
            { heating_info: updatedHeatingInfo }
          );
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              filledPage:
                prev.filledPage >= prev.page + 1
                  ? prev.filledPage
                  : prev.page + 1,
              page: prev.page + 1,
              otherData: {
                ...prev.otherData,
                yearArray: updatedHeatingInfo,
                noOfHeatingSystem: data.number_of_heating_systems,
              },
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({
          ...prev,
          page: prev.page - 1,
          otherData: {
            ...prev.otherData,
            yearArray: updatedHeatingInfo,
            noOfHeatingSystem: data.number_of_heating_systems,
          },
        }));
      } else if (type === "save") {
        await getApi(
          `/energy_consumption/form/${state.form_id}/generate_link`
        );
        // navigate("/login");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };

  const handleChange = (e, index) => {
    // setData(prev=>({...prev,heating_info:}))
    const { value, name } = e.target; // Get the input value
    setData((prevData) => {
      // Make a copy of the previous state
      const newData = [...prevData.heating_info];
      // Update the specific field of the object at the given index
      newData[index] = { ...newData[index], [name]: value };
      return { ...prevData, heating_info: newData }; // Return the updated array
    });
  };

  const handleChange1 = (e, index, index1) => {
    const { value, name } = e.target; // Get the input value

    setData((prevData) => {
      // Make a deep copy of the heating_info array
      const newData = prevData.heating_info.map((heatingItem, i) => {
        // For the specific item that needs to be updated, create a copy of its consumption_info
        if (i === index) {
          return {
            ...heatingItem,
            consumption_info: heatingItem.consumption_info.map(
              (consumptionItem, j) =>
                j === index1
                  ? { ...consumptionItem, consumption: value } // Update the specific consumption
                  : consumptionItem
            ),
          };
        }
        return heatingItem;
      });

      // Return the updated state object
      return { ...prevData, heating_info: newData };
    });
  };

  const handleDate = (_date, index) => {
    let _data = JSON.stringify(data);
    _data = JSON.parse(_data);
    const newData = _data.heating_info.map((_info, i) => {
      // Deep copy the object at the specified index
      let info = JSON.stringify(_info);
      info = JSON.parse(info);
      return {
        ...info,
        consumption_info: info.consumption_info.map((item, index) => {
          const originalDate = _date;
          const date = new Date(originalDate);
          return {
            ...item,
            // date: "2008-08-11",
            // date: YYMMDD(
            //   new Date(date.setFullYear(date.getFullYear() + index))
            // ), // Update the date
            date: new Date(date.setFullYear(date.getFullYear() + index)), // Update the date}
          };
        }),
      };
    });

    setState((prev) => ({
      ...prev,
      otherData: { ...prev.otherData, yearArray: newData },
    }));

    setData((prev) => ({ ...prev, heating_info: [...newData] }));
  };
  const handleOther = (_count) => {
    const newData = data.heating_info.map((info, i) => {
      if (i === _count - 1) {
        // Deep copy the object at the specified index
        return {
          ...info,
          consumption_info: info.consumption_info.map((item, index) => ({
            ...item,
            // date: "2008-08-11",
            // date: YYMMDD(
            //   new Date(date.setFullYear(date.getFullYear() + index))
            // ), // Update the date
          })),
        };
      }
      return info; // Return other elements unchanged
    });

    setState((prev) => ({
      ...prev,
      otherData: {
        ...prev.otherData,
        number_of_heating_systems: _count,
        yearArray: newData,
      },
    }));
  };
  const handlePopUp = (data) => {
    setPop(true);
    setInfoText(data);
  };

  const validateHeatingInfoArray = (heatingInfoArray) => {
    // Initialize errors array
    const errors = heatingInfoArray.map(() => ({
      energy_source_id: "",
      unit_id: "",
      type: "",
      installation_year: "",
      hotwater_included_in_heating_system: "",
      percentage: "",
      consumption_info: [],
    }));

    heatingInfoArray.forEach((heatingInfo, heatingIndex) => {
      const heatingErrors = errors[heatingIndex];

      // Validate energy_source_id
      if (heatingInfo.energy_source_id === null) {
        heatingErrors.energy_source_id = "Energy source is required.";
      }

      // Validate unit_id
      if (heatingInfo.unit_id === null) {
        heatingErrors.unit_id = "Unit ID is required.";
      }

      // Uncomment and use these validation checks as needed
      /*
      // Validate type
      if (typeof heatingInfo.type !== "number" || heatingInfo.type <= 0) {
        heatingErrors.type = "Type must be a positive number.";
      }
  
      // Validate installation_year
      if (
        typeof heatingInfo.installation_year !== "number" ||
        heatingInfo.installation_year <= 0
      ) {
        heatingErrors.installation_year =
          "Installation year must be a positive number.";
      }
  
      // Validate hotwater_included_in_heating_system
      if (![0, 1].includes(heatingInfo.hotwater_included_in_heating_system)) {
        heatingErrors.hotwater_included_in_heating_system =
          "Hot water included in heating system must be either 0 (no) or 1 (yes).";
      }
  
      // Validate percentage
      if (
        typeof heatingInfo.percentage !== "number" ||
        heatingInfo.percentage <= 0
      ) {
        heatingErrors.percentage = "Percentage must be a positive number.";
      }
      */
      // Validate consumption_info array
      if (
        !Array.isArray(heatingInfo.consumption_info) ||
        heatingInfo.consumption_info.length === 0
      ) {
        heatingErrors.consumption_info.push(
          "Consumption info must be a non-empty array."
        );
      } else {
        heatingInfo.consumption_info.forEach((info, idx) => {
          const consumptionErrors = heatingErrors.consumption_info[idx] || {};
          // Check if the consumption value is invalid
          if (
            info.consumption === "" ||
            info.consumption === null ||
            isNaN(info.consumption) ||
            /[-eE+]/.test(info.consumption)
          ) {
            consumptionErrors.consumption = `Consumption field is invalid. It must be a non-negative number and should not contain 'e', '-', or '+'.`;
          }

          if (idx === 1 || idx === 2) {
            const baseConsumption = parseFloat(
              heatingInfo.consumption_info[0].consumption
            );
            const currentConsumption = parseFloat(info.consumption);

            if (!isNaN(baseConsumption) && !isNaN(currentConsumption)) {
              const minLimit = 0.25 * baseConsumption; // 25% of value at index 0
              const maxLimit = 1.75 * baseConsumption; // 75% of value at index 0

              if (
                currentConsumption < minLimit ||
                currentConsumption > maxLimit
              ) {
                consumptionErrors.consumption = `Value should not be less than 25% or greater than 175% of  ${heatingInfo.consumption_info[0].consumption} i.e the value in first consumption field.`;
              }
            }
          }

          // Add the error object to consumption_info errors array
          if (Object.keys(consumptionErrors).length > 0) {
            heatingErrors.consumption_info[idx] = consumptionErrors;
          }
        });
      }
    });

    return errors;
  };
  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={Heating}
          heading={"Heating Info"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          <div className={styles.fieldDetailsCon}>
            <div>
              <p className={styles.fieldName}>
                How many heating system are installed?
              </p>
              <div className={styles.num}>
                <Button
                  variant={show === 1 ? "contained" : "outlined"}
                  onClick={() => {
                    setShow(1);
                    setButtonError("");
                    setState((prev) => ({
                      ...prev,
                      otherData: {
                        ...prev.otherData,
                        hotwater_included_in_heating_system: 1,
                      },
                    }));
                    handleClick(1);
                  }}
                >
                  <b>1</b>
                </Button>
                <Button
                  variant={show === 2 ? "contained" : "outlined"}
                  onClick={() => {
                    setShow(2);
                    setButtonError("");
                    handleClick(2);
                    setState((prev) => ({
                      ...prev,
                      otherData: {
                        ...prev.otherData,
                        number_of_heating_systems: 2,
                      },
                    }));
                  }}
                >
                  <b>2</b>
                </Button>
                <Button
                  variant={show === 3 ? "contained" : "outlined"}
                  onClick={() => {
                    setShow(3);
                    setButtonError("");
                    handleClick(3);
                    setState((prev) => ({
                      ...prev,
                      otherData: {
                        ...prev.otherData,
                        number_of_heating_systems: 3,
                      },
                    }));
                  }}
                >
                  <b>3</b>
                </Button>
                <Button
                  variant={show === 4 ? "contained" : "outlined"}
                  onClick={() => {
                    setShow(4);
                    setButtonError("");
                    handleClick(4);
                    setState((prev) => ({
                      ...prev,
                      otherData: {
                        ...prev.otherData,
                        number_of_heating_systems: 4,
                      },
                    }));
                  }}
                >
                  <b>4</b>
                </Button>
              </div>
              <p style={{ fontSize: "14px", color: `${errorMessage}` }}>
                {buttonError}
              </p>
            </div>
            <div className={styles.btms}>
              {data.heating_info.map((item, index) => {
                let _unit = unit.length < index + 1 ? unit : unit[index];
                let suffix = _unit.findIndex(
                  (obj, i) => obj.id === item.unit_id
                );
                let text = _unit[suffix] ? _unit[suffix].title : "";
                let energySourceError = errors[index]?.energy_source_id;
                let energyUnitError = errors[index]?.unit_id;
                return (
                  <div key={index}>
                    <div className={styles.fieldCom}>
                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Energy Sources
                          <img
                            src={info}
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePopUp(popData?.Energy_Sources)}
                          />
                        </p>
                        <FormControl
                          fullWidth
                          error={Boolean(energySourceError)}
                        >
                          <Select
                            style={style}
                            size="small"
                            value={item.energy_source_id}
                            onChange={(e) => {
                              handleChange(e, index);
                              let newIndex = energy.source.findIndex(
                                (obj) => e.target.value === obj.id
                              );
                              unit[index] = energy.source[newIndex].unit;
                              setUnit((prev) => [...unit]);
                            }}
                            name="energy_source_id"
                          >
                            {energy.source.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.title}
                              </MenuItem>
                            ))}
                          </Select>
                          {energySourceError && (
                            <FormHelperText
                              id={`energySourceError-${index}`}
                              style={{ color: `${errorMessage}` }}
                            >
                              {energySourceError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>

                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Unit
                          <img
                            src={info}
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePopUp(popData?.Unit)}
                          />
                        </p>
                        <FormControl
                          fullWidth
                          error={Boolean(energySourceError)}
                        >
                          <Select
                            style={style}
                            size="small"
                            onChange={(e) => handleChange(e, index)}
                            name="unit_id"
                            value={item.unit_id}
                          >
                            {_unit.map((option) => (
                              <MenuItem
                                key={`${index}` + option.id}
                                value={option.id}
                              >
                                {option.title}
                              </MenuItem>
                            ))}
                          </Select>
                          {energyUnitError && (
                            <FormHelperText
                              id={`energyUnitError-${index}`}
                              style={{ color: `${errorMessage}` }}
                            >
                              {energyUnitError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <p className={styles.text}>
                      If you do not know the primary energy factor of your
                      energy provider, please tell us the name of your provider.
                      You can leave notes<span> here .</span>
                    </p>
                    <div className={styles.visible}>
                      {item.consumption_info.map((item1, index1) => {
                        const date = new Date(item1.date);

                        // Get the month (0-based index, so add 1 to get 1-based month)
                        const month = date.getMonth();

                        // Get the year
                        const year = date.getFullYear();
                        const consumptionErrorMessage =
                          errors[index]?.consumption_info[index1];
                        return (
                          <div className={styles.fieldCom} key={index1}>
                            <div
                              className={`${styles.fieldsDate} ${
                                index1 !== 0 && styles.hideThis
                              }`}
                            >
                              <p className={styles.fieldName}>
                                Date
                                <img
                                  src={info}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handlePopUp(popData?.date)}
                                />
                              </p>
                              <DateField
                                onChange={(date) =>
                                  // setForm((prev) => ({ ...prev, Date: date }))
                                  handleDate(date, index, index1)
                                }
                                startDate={item1.date}
                                // value={item1.date}
                                style={{ width: "100%" }}
                                key={index1}
                                disabled={index !== 0 || index1 !== 0}
                              />
                            </div>
                            <div className={styles.fields}>
                              <p className={styles.fieldName}>
                                Consumption for {monthsdd[month]} {year} to{" "}
                                {monthsdd[month - 1]} {year + 1}
                                {/* date.getMonth() + 1 */}
                                <img
                                  src={info}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handlePopUp(popData?.consumption)
                                  }
                                />
                              </p>
                              <TextField
                                id={index1}
                                size="small"
                                style={style}
                                value={item1.consumption}
                                onChange={(e) =>
                                  handleChange1(e, index, index1)
                                }
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {text}
                                    </InputAdornment>
                                  ),
                                }}
                                error={consumptionErrorMessage?.consumption}
                                helperText={
                                  consumptionErrorMessage?.consumption
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {/* {data1?.map((item, index) => {
                if (show < index) return;
                return (
                  <div>
                    <div className={styles.fieldCom}>
                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Energy Sources
                          <img src={info} />
                        </p>
                        <Select style={style} size="small">
                          {energy.source.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Unit
                          <img src={info} />
                        </p>
                        <Select style={style} size="small">
                          {energy.unit.map((option) => (
                            <MenuItem
                              key={`${index}` + option.id}
                              value={option.value}
                            >
                              {option.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <p className={styles.text}>
                      If you do not know the primary energy factor of your
                      energy provider, please tell us the name of your provider.
                      You can leave notes<span> here .</span>
                    </p>
                    <div className={styles.fieldCom}>
                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Date
                          <img src={info} />
                        </p>
                        <DateField
                          onChange={(date) =>
                            setForm((prev) => ({ ...prev, Date: date }))
                          }
                          startDate={form.Date}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Consumption for Feb 2019 - Jan 2020
                          <img src={info} />
                        </p>
                        <TextField
                          size="small"
                          style={style}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Kwh
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    {data1?.map((item) => {
                      return (
                        <div className={styles.fieldCom}>
                          <div className={styles.fields}>
                            <p className={styles.fieldName}>
                              Date
                            </p>
                            <DateField
                              disbled={true}
                              onChange={(date) =>
                                setForm((prev) => ({ ...prev, Date: date }))
                              }
                              startDate={form.Date}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className={styles.fields}>
                            <p className={styles.fieldName}>
                              Consumption for Feb 2019 - Jan 2020
                            </p>
                            <TextField
                              size="small"
                              style={style}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Kwh
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
      {pop && (
        <PopUpInfo
          handleClose={() => setPop(false)}
          open={pop}
          data={infoText}
        />
      )}
    </div>
  );
}

export default Step2;
// const data = [{ id: 1 }, { id: 2 }, { id: 3 }];
const data1 = [{ id: 1 }, { id: 2 }];
const heating_info = {
  energy_source_id: null,
  unit_id: null,
  type: 1,
  installation_year: null,
  index: null,
  hotwater_included_in_heating_system: 1,
  percentage: 18,
  consumption_info: [
    {
      date: threeYearsBack(3),
      consumption: null,
    },
    {
      date: threeYearsBack(2),
      consumption: null,
    },
    {
      date: threeYearsBack(1),
      consumption: null,
    },
  ],
};

// number_of_heating_systems how many

// hotwater_included_in_heating_system 1 hot water included

const monthsdd = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const popData = {
  Energy_Sources: [
    {
      heading: "Energy Source",
      para1: "Please select your main energy source here",
    },
  ],
  Unit: [
    {
      heading: "Unit",
      para1:
        "Please enter the correct consumption unit of the energy source/fuel.",
      para2: "You can usually find this on your heating bill.",
    },
  ],
  date: [
    {
      heading: "Date",
      para1: `"To apply for a consumption certificate, consumption values ​​from the last three years must be available - that is, the first billing period must be at least three years ago. Enter the month and year of the start of the first billing period here
"`,
    },
  ],
  consumption: [
    {
      heading: "Consumption",
      para1: `Please enter the total energy consumption for each period here. A billing period is always one year long.`,
      para2: `For apartment buildings, you should state the total energy consumption of the entire building, as energy certificates always refer to the entire building.`,
      para3: `If it is a combined heat and power plant (CHP), please only state the amount of heat produced (without electricity).`,
    },
  ],
};

const isAllFieldsEmpty = (errors) => {
  // Iterate through each object in the errors array
  return errors.every((errorObj) => {
    // Check if all properties are empty strings or an empty array
    return Object.keys(errorObj).every((key) => {
      const value = errorObj[key];
      // Check if the value is an empty string or an empty array
      return (
        (typeof value === "string" && value === "") ||
        (Array.isArray(value) && value.length === 0)
      );
    });
  });
};
