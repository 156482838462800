import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditSign from "../../../assets/componentAssets/EditSign.png";
import styles from "./CustomTable.module.css";
import Skeleton from "@mui/material/Skeleton";

const CustomTable = ({
  tableHeads = [],
  tableBodys,
  edit,
  onHandleEdit = () => {},
  handleImageClick = () => {},
  onSelectionChange,
  loading,
  onHandleView = () => {},
  ...otherProps
}) => {
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setTableData([]);
    if (tableBodys && tableBodys.length > 0) {
      setTableData(tableBodys);
    }
  }, [tableBodys]);

  const renderCellContent = (item, displayData, row) => {
    if (item.id === "status") {
      if (row.status === "Submitted") {
        return <p className={styles.submmited}>{displayData}</p>;
      } else if (row.status === "Not Submitted") {
        return <p className={styles.notSubmmited}>{displayData}</p>;
      } else if (row.status === "Draft") {
        return <p className={styles.draft}>{displayData}</p>;
      }
    } else if (item.id === "certificate_status") {
      if (row.certificate_status === "Draft") {
        return <p className={styles.draft}>{displayData}</p>;
      } else if (row.certificate_status === "Issued") {
        return <p className={styles.issued}>{displayData}</p>;
      } else if (row.certificate_status === "Correction Pending") {
        return <p className={styles.pending}>{displayData}</p>;
      } else if (row.certificate_status === "Issued Version 2") {
        return <p className={styles.issue2}>{displayData}</p>;
      }
    } else if (item.id === "payment_status") {
      if (row.payment_status === 1) {
        return <p className={styles.statusPending}>Pending</p>;
      } else if (row.payment_status === 3) {
        return <p className={styles.statusFailed}>Failed</p>;
      } else if (row.payment_status === 2) {
        return <p className={styles.statusPaid}> Paid</p>;
      }
    }
    return displayData;
  };

  return (
    <TableContainer
      component={Paper}
      {...otherProps}
      style={{ overflow: "auto" }}
    >
      <Table aria-label="simple table">
        <TableHead style={{ background: "#FBFBFB" }}>
          <TableRow>
            <TableCell padding="edit" className={styles.dateTime}>
              {" "}
              <div> Edit & sign</div>
            </TableCell>
            {tableHeads.map((head, index) => (
              <TableCell
                key={index}
                style={{
                  fontSize: " 14px",
                  fontWeight: " 600",
                  color: "#00000080",
                  backgroundColor: "#FBFBFB",
                  minWidth: head?.minWidth,
                  textAlign: "center",
                }}
              >
                <div>{head.label}</div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? 
              Array(10)
                .fill(0)
                .map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell
                      padding="edit"
                      sx={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <Skeleton animation="wave" />
                    </TableCell>
                    {tableHeads.map((_, index) => (
                      <TableCell key={index}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            : tableData.length > 0 &&
              tableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell
                    padding="edit"
                    sx={{ cursor: "pointer", textAlign: "center" }}
                    onClick={() => {
                      if (row.power_consumption.hasOwnProperty("id")) {
                        if (row.power_consumption.file !== null) {
                          window
                            .open(row?.power_consumption?.file, "_blank")
                            .focus();
                        }
                      }
                    }}
                  >
                    <img src={EditSign} alt="edit" />
                  </TableCell>

                  {tableHeads.map((item, index) => {
                    const displayData = !item.key
                      ? row[item?.id]
                      : row[item?.keyName][item?.id];

                    return (
                      <TableCell
                        key={index}
                        onClick={() => onHandleView(row)}
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#000000CC",
                          textAlign: "center",
                        }}
                      >
                        <div>{renderCellContent(item, displayData, row)}</div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
