import React, { useContext, useEffect, useState } from "react";
import styles from "./OuterHeader.module.css";
import yesHead from "../../assets/componentAssets/yesHead.png";
import logo from "../../assets/componentAssets/logoGreenox.png";
import { Button } from "@mui/material";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import gec1 from "../../assets/componentAssets/gec1.svg";
import gec2 from "../../assets/componentAssets/gec2.svg";
import gec3 from "../../assets/componentAssets/gec3.svg";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { englisText } from "../../Utility/engLangObject";
import { germanText } from "../../Utility/gerLangObject";

function OuterHeader() {
  const { type } = useParams();
  const { setState } = useContext(AppContext);
  const location = useLocation();
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (type === "de") {
      setState((prev) => ({
        ...prev,
        language: type,
        headerLanguage: germanText,
      }));
    } else if (type === "en") {
      setState((prev) => ({
        ...prev,
        language: type,
        headerLanguage: englisText,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        language: "de",
        headerLanguage: germanText,
      }));
      navigate(`/form/de/consumption-certificate`);
    }
  }, [type]);
  useEffect(() => {
    const { pathname } = location;
    setActive((prev) => {
      let _active = prev;
      _active = pathname.includes("consumption-certificate") ? 1 : 2;
      return _active;
    });
  }, [location]);
  const handleNavigate = (_navigate) => {
    if (_navigate === "need") {
      navigate(`/form/${type}/needs-certificate`);
      setActive(2);
    } else {
      navigate(`/form/${type}/consumption-certificate`);
      setActive(1);
    }
  };
  return (
    <div>
      <div className={styles.contop}>
        <div className={styles.top}>
          {head?.map((item) => {
            return (
              <>
                <div
                  key={item.id}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={item.icon}
                    style={{ width: "20px", marginRight: "6px" }}
                  />
                </div>
                <p className={styles.name}>{item.name}</p>
              </>
            );
          })}
          <div
            style={{
              display: "flex",
              color: "white",
              marginLeft: "auto",
            }}
          >
            <p style={{ marginRight: "10px", cursor: "pointer" }}>Geman</p>
            <p>English</p>
          </div>
        </div>
      </div>
      <div className={styles.conbtm}>
        <div className={styles.top}>
          <div className={styles.img}>
            <img src={logo} style={{ width: "100%" }} />
          </div>
          <div className={styles.tabs}>
            <div
              style={{
                borderBottom: `2px solid ${active === 1 ? `#22A156` : "white"}`,
              }}
            >
              <Button
                className={styles.tablsName}
                sx={{
                  boxShadow: "none",
                  color: "#000",
                  "&:disabled": {
                    background: "gray",
                    color: "#ccc",
                  },
                }}
                onClick={() => handleNavigate("consumption")}
              >
                Consumption Certificate
              </Button>
            </div>
            <div
              style={{
                borderBottom: `2px solid ${active === 2 ? `#22A156` : "white"}`,
              }}
            >
              <Button
                className={styles.tablsName}
                sx={{
                  boxShadow: "none",
                  color: "#000",
                  "&:disabled": {
                    color: "#989898",
                  },
                }}
                disabled={false}
                onClick={() => handleNavigate("need")}
              >
                Need Certificate
              </Button>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <HamburgerMenu />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OuterHeader;
const head = [
  { id: 1, name: "Immediately Available", icon: gec2 },
  { id: 2, name: "Legally valid for 10 years", icon: gec1 },
  { id: 3, name: "Energy certificate according to GEG", icon: gec3 },
];
