import React from "react";
import styles from "./PopUpInfo.module.css";
import { Button, Dialog } from "@mui/material";
function PopUpInfo({ handleClose, open, data }) {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div className={styles.con}>
        {data?.map((item) => {
          return (
            <div>
              <h3 className={styles.heading}>{item?.heading}</h3>
              <p className={styles.hr} />
              <p className={styles.para1}>{item?.para1}</p>
              {item.para2 && (
                <p style={{ lineHeight: "22px" }} className={styles.para1}>
                  {item?.para2}{" "}
                </p>
              )}

              {item.para3 && (
                <p className={styles.para1} style={{ lineHeight: "22px" }}>
                  {item?.para3}{" "}
                </p>
              )}
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  padding: "12px 25px",
                  background:
                    " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                  boxShadow: "none",
                  color: "white",
                  "&:disabled": {
                    background: "gray",
                    color: "#ccc",
                  },
                }}
              >
                Close
              </Button>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
}

export default PopUpInfo;
