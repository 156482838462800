import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "../../Utility/network";

function FormDataRestoration(props) {
  const { unique_id } = useParams();

  useEffect(() => {
    getData();
  }, []);
  
  const getData = async () => {
    const response = await getApi(
      `/energy_consumption/form?unique_id=${unique_id}`
    );
    if (response.data.data[0]) {
    }
  };
  return <div></div>;
}

export default FormDataRestoration;
