// fieldType  1 = text, 2 = radio, 3 = select, 4 = multi select, 5 = date, 6 = count

import { height } from "@mui/system";

// flex 1 = row, 2 = column
export const formStep1 = [
  {
    title: "Occasion",
    id: 1,
    select: false,
    name: "occasion_id",
    type: "text",
    required: true,
    fieldType: 3,
    popData: [
      {
        heading: "Occasion",
        para1: `If you need the energy certificate for financing or a loan application, please select the reason for issue as "Other". Energy certificates with the reasons for issue as "New construction" or "Modernization" cannot be ordered according to Section 80 Sentences 1 and 2 GEG.`,
      },
    ],
  },
  {
    title: "Postal Code",
    id: 2,
    select: false,
    name: "postal_code",
    type: "number",
    required: false,
    fieldType: 1,
  },
  {
    title: "Building Type",
    id: 3,
    select: false,
    name: "building_type_id",
    type: "text",
    required: true,
    fieldType: 3,
    popData: [
      {
        heading: "Building Type",
        para1: `For mixed-use buildings, we recommend a demand certificate as this provides more accurate results.
`,
      },
    ],
  },
  {
    title: "Year of construction",
    id: 4,
    select: false,
    name: "construction_year",
    type: "number",
    required: false,
    fieldType: 1,
    popData: [
      {
        heading: "Year of construction of the building ",
        para1: `Please indicate the original year the property was built.`,
      },
    ],
  },
  {
    title: "Number of Apartments",
    id: 5,
    select: false,
    name: "number_of_apartments",
    type: "number",
    required: true,
    fieldType: 1,
    popData: [
      {
        heading: "Number of apartments",
        para1: `Since energy certificates can only be issued for the entire building and not for individual apartments, information on all apartments in the building is required. Please count granny flats as separate residential units. For single-family homes, if there are no other apartments in the building, please select 1 residential unit.`,
      },
    ],
  },
  {
    title: "E-mail",
    id: 6,
    select: false,
    name: "email",
    type: "email",
    required: true,
    fieldType: 1,
    popData: [
      {
        heading: "e-mail",
        para1: `Please provide a valid email address so that we can contact you if we have any questions regarding your information.`,
        para2: `You will also receive the order confirmation and invoice via the email address you provided.`,
      },
    ],
  },
  {
    title: "Number of floors",
    id: 8,
    select: false,
    name: "floors",
    type: "number",
    required: true,
    fieldType: 1,
    popData: [
      {
        heading: "Number Of Floors",
      },
    ],
  },
  {
    title: "Floor to Ceiling Height (in meters)",
    id: 9,
    select: false,
    name: "floor_to_ceiling_height",
    type: "number",
    required: true,
    fieldType: 1,
    popData: [
      {
        heading: "Floor To Ceiling Height",
      },
    ],
  },
  {
    title: "Is there a heated basement",
    choice: [
      {
        title: "Yes",
        value: true,
        id: 71,
      },
      {
        title: "No",
        value: false,
        id: 72,
      },
    ],
    id: 7,
    name: "is_there_heated_basement",
    fieldType: 2,
    flex: 1,
    required: true,
  },
];

export const formStep4 = [
  {
    title: "21 March 2019 - 20 February 2020",
    id: 1,
    select: false,
    name: "first",
    type: "text",
    required: true,
  },
  {
    title: "21 March 2020 - 20 February 2021",
    id: 2,
    select: false,
    name: "second",
    type: "number",
    required: true,
  },
  {
    title: "21 March 2021 - 20 February 2022",
    id: 3,
    select: false,
    name: "third",
    type: "text",
    required: true,
  },
];
export const formStep6 = [
  {
    title: "Street or House Number",
    id: 1,
    select: false,
    name: "house",
    type: "text",
    required: true,
  },
  {
    title: "Postcode",
    id: 2,
    select: false,
    name: "postCode",
    type: "number",
    required: true,
  },
  {
    title: "Location",
    id: 3,
    select: false,
    name: "location",
    type: "text",
    required: true,
  },
];

export const formAddress1 = [
  {
    title: "Phone Number",
    id: 1,
    select: false,
    name: "number",
    type: "text",
    required: true,
  },
  {
    title: "Forename",
    id: 2,
    select: false,
    name: "forename",
    type: "number",
    required: true,
  },
  {
    title: "Surname",
    id: 3,
    select: false,
    name: "surname",
    type: "text",
    required: true,
  },
  {
    title: "Street & House Number",
    id: 4,
    select: false,
    name: "street",
    type: "number",
    required: true,
  },
  {
    title: "Postcode",
    id: 5,
    select: false,
    name: "postcode",
    type: "number",
    required: true,
  },
  {
    title: "Location",
    id: 6,
    select: false,
    name: "location",
    type: "email",
    required: true,
  },
  {
    title: "Email",
    id: 6,
    select: false,
    name: "email",
    type: "email",
  },
];

export const steps = [
  {
    id: 1,
    step: "Step 1",
    info: "Building Info",
    required: true,
  },
  {
    id: 2,
    step: "Step 2",
    info: "Heating Info",
    required: true,
  },
  {
    id: 3,
    step: "Step 3",
    info: "Heat Water Info",
    required: true,
  },
  {
    id: 4,
    step: "Step 4",
    info: "Vacancy Info",
    required: true,
  },
  {
    id: 5,
    step: "Step 5",
    info: "Property Info",
    required: true,
  },
  {
    id: 6,
    step: "Step 6",
    info: "Property Address",
    required: true,
  },
  {
    id: 7,
    step: "Step 7",
    info: "Certificate",
    required: true,
  },
  {
    id: 8,
    step: "Step 8",
    info: "Billing  & Delivery Address",
    required: true,
  },
];

export const stepsGerman = [
  {
    id: 1,
    step: "Stufe 1",
    info: "Gebäude-Infos",
    required: true,
  },
  {
    id: 2,
    step: "Stufe 2",
    info: "Infos zur Heizung",
    required: true,
  },
  {
    id: 3,
    step: "Stufe 3",
    info: "Wärme Wasser Info",
    required: true,
  },
  {
    id: 4,
    step: "Stufe 4",
    info: "Infos zum Stellenangebot",
    required: true,
  },
  {
    id: 5,
    step: "Stufe 5",
    info: "Immobilien-Infos",
    required: true,
  },
  {
    id: 6,
    step: "Stufe 6",
    info: "Adresse der Immobilie",
    required: true,
  },
  {
    id: 7,
    step: "Stufe 7",
    info: "Zertifikat",
    required: true,
  },
  {
    id: 8,
    step: "Stufe 8",
    info: "Rechnungs- und Lieferadresse",
    required: true,
  },
];

export function ordinalSuffix(number) {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return number + "th";
  } else if (lastDigit === 1) {
    return number + "st";
  } else if (lastDigit === 2) {
    return number + "nd";
  } else if (lastDigit === 3) {
    return number + "rd";
  } else {
    return number + "th";
  }
}

export function arrayId(_arr = []) {
  const arrNew = _arr.map((item) => item.id);
  return arrNew;
}

export function onlyId(obj = {}) {
  let id = obj.hasOwnProperty("id") ? obj.id : null;
  return id;
}

export function formatDate(timestamp) {
  if (!timestamp) return;
  const date = new Date(timestamp);

  // Get day with ordinal suffix (st, nd, rd, th)
  const day = date.getDate();
  const dayWithSuffix =
    day +
    (day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th");

  // Get month
  const month = date.toLocaleString("default", { month: "long" });

  // Get year
  const year = date.getFullYear();

  // Format hours and minutes
  const hours = date.getHours() % 12 || 12; // Convert 24-hour format to 12-hour
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${dayWithSuffix} ${month}, ${year} | ${hours}:${minutes} ${ampm}`;
}

const certificateStatus = [
  { title: "Draft", id: 1 },
  { title: "Issued", id: 2 },
  { title: "Correction Pending", id: 3 },
  { title: "Issued Version 2", id: 4 },
];

export function getStatusById(id) {
  const data = certificateStatus.find((status) => status.id === id) || null;
  return data ? data.title : null;
}

export const statusArray = [
  { id: 1, title: "Draft" },
  { id: 2, title: "Issued" },
  { id: 3, title: "Correction Pending" },
  { id: 4, title: "Issued Version 2" },
];

export function getStatusByIds(id) {
  return statusArray.find((status) => status.id === id) || null;
}

const paymentStatus = [
  {
    title: "Pending",
    id: 1,
  },
  {
    title: "Paid",
    id: 2,
  },
  {
    title: "Unpaid",
    id: 3,
  },
  {
    title: "No payment required",
    id: 4,
  },
];
export function getPaymentStatus(id) {
  const data = paymentStatus.find((status) => status.id === id) || null;
  return data ? data.title : null;
}
