import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../../Utility/network";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

function ResumeConsumption(props) {
  const { state, setState } = useContext(AppContext);
  let navigate = useNavigate();
  const { uuid } = useParams();
  useEffect(() => {
    getData();
  }, [uuid]);
  async function getData() {
    try {
      const postData = await getApi(
        `/energy_consumption/form/?unique_id=${uuid}`
      );
      const {
        heating_info,
        building_type,
        construction_year,
        user,
        floors,
        floor_to_ceiling_height,
        postal_code,
        is_there_heated_basement,
        number_of_apartments,
        occasion,
        vacancy,
        living_space,
        building_part,
        ventilation,
        was_the_1977_thermal_insulation_regulation_met,
        does_property_have_basement,
        what_lies_below_the_ground_floor,
        are_renewable_energy_used,
        type_of_renewable_energy,
        energy_used_for,
        is_there_cooling_system,
        type_of_cooling_system_used,
        optional_information_abt_equipment,
        sun_protection,
        does_the_property_have_thermal_insulation,
        is_there_roof_or_attic_insulation,
        address,
        billing_address,
        id,
      } = postData.data.data[0];
      const formId = id;
      const new_heating_info = heating_info.map((item, index) => {
        return {
          ...item,
          energy_source_id: item.energy_source.id,
          unit_id: item.unit.id,
        };
      });
      const new_ventilation = ventilation.map((item, index) => {
        return item.id;
      });
      const new_type_of_renewable_energy = type_of_renewable_energy.map(
        (item, index) => {
          return item.id;
        }
      );
      const new_energy_used_for = energy_used_for.map((item, index) => {
        return item.id;
      });
      const new_type_of_cooling_system_used = type_of_cooling_system_used.map(
        (item, index) => {
          return item.id;
        }
      );

      let page = 1;
      if (new_heating_info && new_heating_info.length > 0) {
        page = 2;
      }
      if (new_ventilation && new_ventilation.length > 0) {
        page = 5;
      }
      if (address && address.length > 0) {
        page = 6;
      }
      if (billing_address) {
        page = 8;
      }
      if (!formId) {
        page = 1;
      }
      setState((prev) => ({
        ...prev,
        otherData: {
          ...prev.otherData,
          buildingData: {
            building_type_id: building_type?.id,
            construction_year,
            email: user?.email,
            floors,
            occasion_id: occasion?.id,
            postal_code,
            is_there_heated_basement,
            floor_to_ceiling_height,
            number_of_apartments,
          },
          yearArray: new_heating_info,
          noOfHeatingSystem: heating_info.length,
          construction_year: construction_year,
          vacancy,
          info: {
            living_space,
            building_part,
            ventilation_id: new_ventilation,
            was_the_1977_thermal_insulation_regulation_met,
            does_property_have_basement,
            what_lies_below_the_ground_floor,
            are_renewable_energy_used,
            type_of_renewable_energy_id: new_type_of_renewable_energy,
            energy_used_for_id: new_energy_used_for,
            is_there_cooling_system,
            type_of_cooling_system_used_id: new_type_of_cooling_system_used,
            optional_information_abt_equipment,
            sun_protection,
            does_the_property_have_thermal_insulation,
            is_there_roof_or_attic_insulation,
            ...(address && address.length > 0 && { ...address[0] }),
          },
        },
        form_id: formId,
        filledPage: page,
        page: page,
      }));
      navigate(`/form/consumption-certificate`);
    } catch (error) {}
  }
  return <div></div>;
}

export default ResumeConsumption;
