export const capitalise = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const emailValidation = (email) => {
  const validationError = /\S+@\S+\.\S+/.test(email) ? "" : "Invalid email id";
  return { error: validationError, status: false };
};

export function removeNonAlphabeticCharacters(str) {
  return str.replace(/[^a-zA-Z\s]/g, " ");
}

export function isNumeric(str) {
  return /^\d+$/.test(str);
}

export function isImageUrl(url) {
  return /\.(jpeg|jpg|gif|png|svg)$/.test(url);
}

export function dateToYYYYMMDD(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function zdate(_date) {
  let date = new Date(_date);
  let year = date.getUTCFullYear();
  let month = ("0" + (date.getUTCMonth() + 1)).slice(-2); // Adding 1 because getMonth returns 0-based value
  let day = ("0" + date.getUTCDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  const ordinalSuffix = getOrdinalSuffix(day);

  return `${day}${ordinalSuffix} ${month} ${year}`;
}

function getOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function getDjangoError(response) {
  let errorMessages = [];

  if (response.error && response.error.fields) {
    response.error.fields.forEach((fieldError) => {
      errorMessages.push(...fieldError.message); // Spread to add all messages
    });
  }
}
// Example usage:

// const areAllFieldsEntered = (fields) => {
//     return Object.values(fields).every(field => field.value.trim() !== '');
// };

// // Utility function to check if any field has an error
// const hasAnyError = (fields) => {
//     return Object.values(fields).some(field => field.error !== '');
// };
export const stringToYYYYMMDD = (dateString) => {
  const startDate = new Date(dateString);
  const formattedDate = startDate.toISOString().slice(0, 10);
  return formattedDate;
};