import React from "react";
import styles from "../Onboarding.module.css";
import ImageryText from "../ImageryText/ImageryText";
import ControlledButton from "../../Components/ControlledButton/ControlledButton";
import RegisterFields from "../RegisterFields/RegisterFields";
import BackIconComponent from "../BackIconComponent";
import CircularLoading from "../../Components/CircularLoading/CircularLoading";
import heritageLogo from "../../assets/onboarding/heritageLogo.png";
import { capitalise } from "../../Utility/helper";
import { ERRORCOLOR } from "../../Utility/constants";

function Step2({ id, fields, formState, handleClick, fieldsData, ...props }) {
  return (
    <div className={styles.stepContainer}>
      <ImageryText image={heritageLogo} primaryText="Forgot Password?">
        <span>
          Enter the email you used to create your account so we can send you a
          code for resetting your password.
        </span>
      </ImageryText>
      <div className={styles.formSection}>
        {fields.map((item, index) => {
          return (
            <div key={index}>
              <RegisterFields
                key={index}
                name={item.name}
                placeholder={item.placeholder}
                size="small"
                {...fieldsData[item.name]}
                style={{ width: "100%" }}
                type={item.type}
                label={capitalise(item.name)}
              />
            </div>
          );
        })}
        <p style={{ marginTop: "8px", color: ERRORCOLOR }}>{formState.error}</p>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px" }}
          onClick={() => {
            handleClick(id, "apicall");
          }}
          disabled={formState.loading}
        >
          {formState.loading && (
            <CircularLoading size={20} style={{ marginRight: "15px" }} />
          )}{" "}
          Continue
        </ControlledButton>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px", color: "black" }}
          variant="outlined"
          onClick={() => {
            handleClick(id, "redirection");
          }}
        >
          <BackIconComponent />
        </ControlledButton>
      </div>
    </div>
  );
}

export default Step2;
