export const form4State = {
  first: "",
  second: "",
  third: "",
  errors: {
    first: "",
    second: "",
    third: "",
  },
};
