export const LOCAL_STRING = "heritage_marble_admin";
export const TOKEN = "token";
export const USER = "user";
export const REMEMBER = "remember";
export const FORM = "form";
export const PAGE = "page";
export const FILLEDPAGE = "filledPage";
export const OTHERDATA = "otherData";
export const PDFID = "pdfId";
export const FORMID = "form_id";
export const UNIQUEID = "unique_id";
export const NEEDPAGE = "needPage";
export const NEEDFILLEDPAGE = "needFilledPage";
export const HEATINGSYSTEMS = "heatingSystems";
export const NEEDDATA = "needData";
export const NEEDID = "needId";
export const WALLS = "walls";
export const LANGUAGE = "language";
export const HEADERLANGUAGE = "headerLanguage";
