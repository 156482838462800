import React from "react";
import NeedCertificateTable from "../../Components/Greenox/NeedCertificateTable/NeedCertificateTable";

function Energy(props) {
  return (
    <div>
      <NeedCertificateTable />
    </div>
  );
}

export default Energy;
