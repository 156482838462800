import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Step.module.css";
import NavButton from "./NavButton";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import structure from "../../../assets/dashboard/structure.svg";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import {
  structure_type,
  structure as newStructure,
  constructionType,
  wing,
  coolingSystem,
  outsideAir,
} from "../utility/utils";
import { PatchApi, PostApi, getApi } from "../../../Utility/network";
import CustomCheckBox from "../../../Components/CustomCheckBox/CustomCheckBox";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { arrayId, onlyId } from "../../../Utility/utils";
import { useNavigate } from "react-router-dom";

function Step2() {
  const { setState, state } = useContext(AppContext);
  const navigate = useNavigate();
  const [errorsData, setErrorsData] = useState({});

  const [infoText, setInfoText] = useState([]);
  const [pop, setPop] = useState();
  const [type, setType] = useState(1);
  const [ventilation, setVentaltion] = useState([]);
  const [material, setMaterial] = useState([]);
  const [struct, setStruct] = useState({ id: null, wall_details: [] });
  const [corners, setCorners] = useState(4);
  const [submitData, setSubmitData] = useState({
    is_there_cooling_system: false,
    is_test_for_leaks_conducted: false,
    outside_air_exchange: null,
    type_of_cooling_system_used_id: null,
    average_total_floor_height: null,
    living_space: null,
    construction_type: null,
    material_id: null,
    wing: null,
    ventilation_id: [],
  });

  useEffect(() => {
    if (state?.walls?.structure_detail?.hasOwnProperty("id")) {
      const { structure_detail } = state.walls;
      const {
        average_total_floor_height,
        construction_type,
        is_test_for_leaks_conducted,
        is_there_cooling_system,
        material,
        outside_air_exchange,
        structure_type,
        wall_details,
        wing,
        ventilation,
        living_space,
        type_of_cooling_system_used,
      } = state?.walls?.structure_detail;
      console.log(wall_details, state?.walls?.structure_detail, "hello js");
      setSubmitData({
        average_total_floor_height,
        construction_type,
        is_test_for_leaks_conducted,
        is_there_cooling_system,
        material_id: onlyId(material),
        outside_air_exchange,
        wing,
        ventilation_id: arrayId(ventilation),
        type_of_cooling_system_used_id: arrayId(type_of_cooling_system_used),
        living_space,
      });
      setStruct({ id: structure_type, wall_details: wall_details });
      setType(structure_type);
    }
  }, [state]);

  const validateFields = () => {
    const errors = {};
    let hasErrors = false;

    // Combine submitData and struct
    let _data = { ...submitData, ...struct };

    // Debugging: Log the complete _data object

    // Destructure to exclude keys that need to be skipped
    const { type_of_cooling_system_used_id, outside_air_exchange, ...data } =
      _data;

    // Array of keys to skip validation
    const skipKeys = [
      "type_of_cooling_system_used_id",
      "outside_air_exchange",
      "is_there_cooling_system", // Add any other keys you want to skip
      "is_test_for_leaks_conducted",
      "living_space",
    ];

    // Iterate over each key in the data object
    for (const key in data) {
      // Debugging: Log the key being checked

      // Skip validation for specified keys
      if (skipKeys.includes(key)) {
        continue;
      }

      if (Array.isArray(data[key])) {
        // Check if the array is empty
        if (data[key].length === 0) {
          errors[key] = "This field is required";
          hasErrors = true;
        } else {
          // Check nested arrays of objects
          data[key].forEach((item, index) => {
            for (const subKey in item) {
              // Check if any subKey is null, an empty string, or false
              if (
                item[subKey] === null ||
                item[subKey] === "" ||
                item[subKey] === false
              ) {
                errors[`${key}[${index}].${subKey}`] = "This field is required";
                hasErrors = true;
              }
            }
          });
        }
      } else if (
        data[key] === null ||
        data[key] === "" ||
        data[key] === false
      ) {
        // Check if the value is null, empty string, or false
        errors[key] = "This field is required";
        hasErrors = true;
      }
    }

    // Log the errors (or handle them as needed)
    setErrorsData(errors);

    // Return true if there are errors
    return hasErrors;
  };

  const handleSubmit = async (num, type) => {
    const errors = validateFields();
    if (errors) return;
    try {
      if (type === "next") {
        const {
          material_id,
          construction_type,
          wing,
          average_total_floor_height,
          living_space,
          is_there_cooling_system,
          type_of_cooling_system_used_id,
          is_test_for_leaks_conducted,
          outside_air_exchange,
          ventilation_id,
        } = submitData;
        const _data = {
          material_id: material_id,
          structure_type: struct.id,
          construction_type: construction_type,
          wing: wing,
          average_total_floor_height: average_total_floor_height,
          ...(living_space && { living_space: living_space }),
          is_there_cooling_system: is_there_cooling_system,
          is_test_for_leaks_conducted: is_test_for_leaks_conducted,
          // outside_air_exchange: outside_air_exchange,
          ...(outside_air_exchange && {
            outside_air_exchange: outside_air_exchange,
          }),
          ...(type_of_cooling_system_used_id && {
            type_of_cooling_system_used_id: type_of_cooling_system_used_id,
          }),
          wall_details: struct.wall_details,
          ventilation_id: ventilation_id,
        };

        try {
          const postData = await PatchApi(`/energy_need/${state.needId}/`, {
            structure_detail: _data,
          });
          if (postData.status === 200 || postData.status === 201) {
            const { structure_detail } = postData.data.data;
            setState((prev) => ({
              ...prev,
              needPage: prev.needPage + 1,

              walls: {
                ...prev.walls,
                structure_detail: {
                  ...structure_detail,
                  living_space: living_space,
                },
              },
              needFilledPage:
                prev.needFilledPage >= prev.needPage + 1
                  ? prev.needFilledPage
                  : prev.needPage + 1,
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, needPage: prev.needPage - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_need/${state.needId}/generate_link/`);
        // navigate("/login");
        alert("link sent to your email");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };
  useEffect(() => {
    if (type > 5) {
      setStruct({ id: null, wall_details: [] });
    } else {
      setStruct(newStructure[type - 1]);
    }
  }, [type]);

  useEffect(() => {
    if (type == 5) {
      const arrayOfObjects = Array.from({ length: corners }, (_, i) => ({
        length: null,
        index: i + 1,
      }));
      setStruct({ id: type, wall_details: arrayOfObjects });
    }
  }, [type, corners]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "Rectangle" ||
      name === "L-Form" ||
      name === "T-Form" ||
      name === "U-Form" ||
      name === "Custom"
    ) {
      setType(value);
    } else {
      setSubmitData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const getList = async () => {
    try {
      const ven = await getApi(`/energy_consumption/ventilation`);
      const mat = await getApi(
        `/energy_need/material_types?type=1&year=${state?.needData.construction_year}`
      );
      const source = await getApi(`/energy_consumption/energy_source`);

      // const cool = await getApi(`/energy_consumption/colling_system_types`);
      // const renew = await getApi(`/energy_consumption/renewable_energy_types`);
      // const renewFor = await getApi(`/energy_consumption/renewable_energy_for`);
      setVentaltion(ven.data.data);
      setMaterial(mat.data.data);
      // setCoolings(cool.data.data);
      // setRenewable(renew.data.data);
      // setRenewableFor(renewFor.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getList();
  }, []);
  const handleCheckboxChange = (name, checkedIds) => {
    if (name === "ventilation_id") {
      setSubmitData((prev) => ({ ...prev, ventilation_id: checkedIds }));
    } else if (name === "type_of_cooling_system_used_id") {
      setSubmitData((prev) => ({
        ...prev,
        type_of_cooling_system_used_id: checkedIds,
      }));
    }
  };
  const handleChangeButton = (e, index) => {
    // setData(prev=>({...prev,heating_info:}))
    const { value, name } = e.target; // Get the input value

    setStruct((prevData) => {
      // Make a copy of the previous state
      const newData = prevData.wall_details;
      newData[index][name] = value;
      return { ...prevData, wall_details: newData }; // Return the updated array
      // Update the specific field of the object at the given index
      // newData[index] = { ...newData[index], [name]: value };
      // return { ...prevData, [field]: newData }; // Return the updated array
    });
  };
  return (
    <div>
      <div className={styles.con}>
        {" "}
        <StepsHeader
          image={structure}
          heading={"Structure Details"}
          secPara="Provide your proper information."
        />
        <div className={styles.fieldDetails}>
          <p>
            To accurately determine the property's energy requirements, we need
            the following information from you.
          </p>
          <p>
            <b>Note:</b> Please provide only the shape and external dimensions
            of your building section.
          </p>
          <p>As per your floor plan</p>
          <div className={styles.fieldDetailsCon}>
            <div
              className={styles.Radio}
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {structure_type.map((item, index) => {
                return (
                  <FormControl>
                    <RadioGroup
                      name={item.title} // Name of the radio group
                      value={type}
                      onChange={handleChange} // Handle value changes
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <div>
                          {item.img ? (
                            <img src={item.img} className={styles.imageClass} />
                          ) : (
                            <p style={{ margin: "10px" }}>Custom</p>
                          )}
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value={item.id}
                            control={<Radio />}
                            // label="yes"
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                );
              })}
            </div>
            <div>
              {type == 5 && (
                <div className={styles.fieldCom}>
                  <p className={styles.fieldName} style={{ marginTop: "11px" }}>
                    Corners
                  </p>
                  <TextField
                    style={{ width: "100%" }}
                    size="small"
                    value={corners}
                    name="corners"
                    onChange={(e) => {
                      setCorners(e.target.value);
                    }}
                  />
                </div>
              )}
              <div className={styles.fieldCom}>
                {struct.wall_details.map((item1, index1) => {
                  const value = item1.length ? item1.length : "";
                  const letter = String.fromCharCode(65 + index1); // 65 is the ASCII code for 'A'
                  if (type != 5) return;
                  return (
                    <div className={styles.fields}>
                      <p
                        className={styles.fieldName}
                        style={{ marginTop: "11px" }}
                      >
                        Wall Length {letter}
                      </p>
                      <TextField
                        onChange={(e) => handleChangeButton(e, index1)}
                        showInfo={false}
                        style={{ width: "100%" }}
                        value={value}
                        name="length"
                        size="small"
                        endIcon="meter"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              meter
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {struct.wall_details.map((item1, index1) => {
                const value = item1.length ? item1.length : "";
                const letter = String.fromCharCode(65 + index1); // 65 is the ASCII code for 'A'
                if (type == 5) return;
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "15px",
                    }}
                    key={item1.index}
                  >
                    <div style={{ width: "20%" }}>
                      <img src={item1.img} className={styles.indiImage}/>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p
                        className={styles.fieldName}
                        style={{ marginTop: "11px" }}
                      >
                        Wall Length {letter}
                      </p>
                      <TextField
                        onChange={(e) => handleChangeButton(e, index1)}
                        showInfo={false}
                        style={{ width: "100%" }}
                        value={value}
                        name="length"
                        size="small"
                        endIcon="meter"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              meter
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.fieldCom}>
            <div className={styles.fields}>
              <p className={styles.fieldName} style={{ marginTop: "11px" }}>
                Average total floor height (incl. ceiling)
              </p>
              <TextField
                style={{ width: "100%" }}
                size="small"
                value={submitData.average_total_floor_height}
                name="average_total_floor_height"
                onChange={handleChange}
                error={errorsData?.average_total_floor_height}
                helperText={errorsData?.average_total_floor_height}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName} style={{ marginTop: "11px" }}>
                Living Space
              </p>
              <TextField
                style={{ width: "100%" }}
                size="small"
                value={submitData.living_space}
                name="living_space"
                onChange={handleChange}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Construction type</p>
              <FormControl
                fullWidth
                error={Boolean(errorsData.construction_type)}
              >
                <Select
                  size="small"
                  value={submitData.construction_type}
                  name="construction_type"
                  onChange={(e) => handleChange(e)}
                >
                  {constructionType.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorsData?.construction_type && (
                <FormHelperText error={errorsData.construction_type}>
                  {errorsData?.construction_type}
                </FormHelperText>
              )}
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Material used</p>
              <FormControl fullWidth error={Boolean(errorsData.material_id)}>
                <Select
                  size="small"
                  value={submitData.material_id}
                  name="material_id"
                  onChange={(e) => handleChange(e)}
                >
                  {material.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorsData?.material_id && (
                <FormHelperText error={errorsData.material_id}>
                  {errorsData?.material_id}
                </FormHelperText>
              )}
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Part of the building</p>
              <FormControl fullWidth error={Boolean(errorsData.wing)}>
                <Select
                  size="small"
                  value={submitData.wing}
                  name="wing"
                  onChange={(e) => handleChange(e)}
                >
                  {wing.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorsData?.wing && (
                <FormHelperText error={errorsData.wing}>
                  {errorsData?.wing}
                </FormHelperText>
              )}
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName} style={{ marginTop: "15px" }}>
                Type of Ventilation
                {/* <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.ventilation)}
                /> */}
              </p>
              {errorsData?.ventilation_id && (
                <FormHelperText error={errorsData.ventilation_id}>
                  {errorsData?.ventilation_id}
                </FormHelperText>
              )}
              <CustomCheckBox
                data={ventilation}
                name="ventilation_id"
                onCheckChange={handleCheckboxChange}
                selectedValues={submitData.ventilation_id}
              />
            </div>
          </div>
          <div className={styles.fieldDetailsCon}>
            <p className={styles.fieldName}>Is there a cooling system?</p>
            <RadioGroup
              name="is_there_cooling_system" // Name of the radio group
              value={submitData.is_there_cooling_system} // Bind the state value
              onChange={handleChange} // Handle value changes
            >
              <div className={styles.Radio}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="no"
                />
              </div>
            </RadioGroup>
            {(submitData.is_there_cooling_system == "true" ||
              submitData.is_there_cooling_system == true) && (
              <CustomCheckBox
                data={coolingSystem}
                name="type_of_cooling_system_used_id"
                onCheckChange={handleCheckboxChange}
                selectedValues={submitData.type_of_cooling_system_used_id}
              />
            )}
            {/* <RadioGroup
              name="type_of_cooling_system_used" // Name of the radio group
              value={submitData.type_of_cooling_system_used} // Bind the state value
              onChange={handleChange} // Handle value changes
            >
              <div className={styles.Radio1}>
                {coolingSystem.map((item, index) => {
                  return (
                    <FormControlLabel
                      value={`${item.id}`}
                      control={<Radio />}
                      label={item.title}
                    />
                  );
                })}
              </div>
            </RadioGroup> */}
          </div>
          <div className={styles.fieldDetailsCon}>
            <p className={styles.fieldName}>
              Has a test for leaks been conducted{" "}
              <span style={{ fontWeight: "400" }}>(Blower door Test)</span>?
            </p>
            <RadioGroup
              name="is_test_for_leaks_conducted" // Name of the radio group
              value={submitData.is_test_for_leaks_conducted} // Bind the state value
              onChange={handleChange} // Handle value changes
            >
              <div className={styles.Radio}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="no"
                />
              </div>
            </RadioGroup>
            {/* {submitData.is_test_for_leaks_conducted && (
              <div className={styles.fields}>
                <CustomCheckBox
                  data={outsideAir}
                  name="outside_air_exchange"
                  onCheckChange={handleCheckboxChange}
                  selectedValues={submitData.outside_air_exchange}
                />
              </div>
            )} */}
            {(submitData.is_test_for_leaks_conducted == "true" ||
              submitData.is_test_for_leaks_conducted == true) && (
              <RadioGroup
                name="outside_air_exchange" // Name of the radio group
                value={submitData.outside_air_exchange} // Bind the state value
                onChange={handleChange} // Handle value changes
              >
                <div className={styles.Radio1}>
                  {outsideAir.map((item, index) => {
                    return (
                      <FormControlLabel
                        value={item.id}
                        control={<Radio />}
                        label={item.title}
                      />
                    );
                  })}
                </div>
              </RadioGroup>
            )}
          </div>{" "}
        </div>
        {pop && (
          <PopUpInfo
            handleClose={() => setPop(false)}
            open={pop}
            data={infoText}
          />
        )}
      </div>
      <NavButton handleSubmit={handleSubmit} />
    </div>
  );
}

export default Step2;

const wallDetails = [
  {
    length: 20,

    index: 1,
  },

  {
    length: 10,

    index: 2,
  },

  {
    length: 10,

    index: 3,
  },

  {
    length: 10,

    index: 4,
  },
];
