export const formState = {
  step1: {
    occasion_id: "",
    postal_code: "",
    building_type_id: "",
    construction_year: "",
    number_of_apartments: "",
    email: "",
    floors: "",
    floor_to_ceiling_height: "",
    is_there_heated_basement: false,
    // errors: {
    //   occasion_id: "",
    //   postal_code: "",
    //   building_type_id: "",
    //   construction_year: "",
    //   number_of_apartments: "",
    //   email: "",
    //   floor_to_ceiling_height: "",
    //   floors: "",
    //   is_there_heated_basement: "",
    // },
  },
};
