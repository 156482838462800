import React, { useContext, useEffect, useState } from "react";

import styles from "./Payment.module.css";
import StepsHeader from "../../../../Components/StepsHeader/StepsHeader";
import ordering from "../../../../assets/componentAssets/ordering.png";
import Billing from "../../../../Components/Billing/Billing";
import success from "../../../../assets/componentAssets/success.png";
import OuterHeader from "../../../../Components/OuterHeader/OuterHeader";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";
import { getApi } from "../../../../Utility/network";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

function Success(props) {
  const { id } = useParams();
  const { state } = useContext(AppContext);
  const [data, setData] = useState({});
  useEffect(() => {
    getData(id);
  }, [id]);
  const getData = async (localData) => {
    try {
      const payData = await getApi(`/payment/checkout/${localData}`);
      setData(payData.data.data);
    } catch (error) {}
  };
  const redirect = (url) => {
    if (!url) return;
    window.open(url, "_blank").focus();
  };
  return (
    <div>
      <OuterHeader />
      <div className={styles.con}>
        <div
          style={{
            boxShadow: " 0px 0px 80px 0px rgba(0, 0, 0, 0.24)",
            paddingBottom: "20px",
          }}
        >
          <StepsHeader image={ordering} heading={"Thank you for ordering"} />
          <Billing
            status={"succes"}
            img={success}
            statusText={"Your payment has been successfully received."}
            desc={`We have received your order  and shall get started with the processing.You will receive your final certificate on the email shared by you.`}
          />
          <div
            style={{
              width: "50%",
              margin: "auto",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            <p>
              Order Id:{" "}
              <span style={{ color: `#25a356`, fontWeight: "bold" }}>
                {data?.payment_intent_id}
              </span>
            </p>
            <Button
              variant="outlined"
              onClick={() => redirect(data?.receipt)}
              style={{ marginRight: "15px" }}
            >
              Receipt
            </Button>
            <Button
              variant="outlined"
              onClick={() => redirect(data?.invoice_file)}
            >
              Invoice
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
