import React, { useContext, useEffect, useState } from "react";
import styles from "./HeaderNew.module.css";
import { Button } from "@mui/material";
import { steps } from "../utility/utils";
import tick from "../../../assets/dashboard/tick.png";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";

function HeaderNew({ showDetails = false, ...props }) {
  const { state, setState } = useContext(AppContext);
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(state.needFilledPage);
  }, [state]);
  const handleClick = (_type, _index) => {
    if (_type === "past" || _type === "active") {
      setState((prev) => ({ ...prev, needPage: _index }));
    }
  };
  return (
    <div className={styles.header}>
      {showDetails && (
        <div className={styles.headDEtails}>
          <div className={styles.left}>
            <p className={styles.name}>{state?.user?.username}</p>
            <p className={styles.dates}>25th July, 2024 | 07:04 PM</p>
          </div>
          <div className={styles.right}>
            <div className={styles.certificate}>
              <p className={styles.sname}>Certificate</p>
              <div className={styles.status}>
                <span>Draft</span>
              </div>
            </div>
            <div className={styles.certificate}>
              <p className={styles.sname}>Payment Status</p>
              <p className={styles.status1}>Pending</p>
            </div>

            <Button variant="contained" size="large">
              Issue
            </Button>
          </div>
        </div>
      )}
      <div className={styles.stepsCon}>
        {steps.map((steps, index) => {
          let ui;
          if (index + 1 === page) {
            ui = "active";
          } else if (index + 1 < page) {
            ui = "past";
          } else {
            ui = "future";
          }
          return (
            <div
              className={
                ui === "past"
                  ? `${styles.steps} ${styles.stepsa}`
                  : `${styles.steps} ${styles.stepsin}`
              }
            >
              <div className={styles.stepContainer}>
                <div
                  className={styles.circle}
                  style={{
                    border: `${
                      ui === "past"
                        ? "3px solid #008C76"
                        : ui === "future"
                        ? "3px solid #DDCA2380"
                        : "3px solid #ddca23"
                    }`,
                    background: `${
                      ui === "past"
                        ? "#008C76"
                        : ui === "future"
                        ? "#DDCA2380"
                        : ""
                    }`,
                    cursor: `${
                      ui === "past" || ui === "active" ? "pointer" : "auto"
                    }`,
                  }}
                  onClick={() => handleClick(ui, index + 1)}
                >
                  {ui === "active" && (
                    <div
                      className={styles.innerCircle}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></div>
                  )}
                  {ui === "past" && (
                    <img src={tick} className={styles.imgWid} />
                  )}
                </div>
              </div>
              <div className={styles.about}>
                <p className={styles.step}>{steps.step}</p>
                {steps.info.split("\n").map((line, index) => (
                  <p className={styles.stepName} key={index}>
                    {line}
                  </p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HeaderNew;
