export const reducer = (state, action) => {

  switch (action.type) {
    case "UPDATE_STEP1":
      return {
        ...state,
        step1: { ...state.step1, ...action.payload },
        errors: {
          ...state.errors,
          [action.payload.name]: "",
        },
      };
    case "UPDATE_STEP6":
      return {
        ...state,
        step6: { ...state.step6, ...action.payload },
        errors: {
          ...state.errors,
          [action.payload.name]: "",
        },
      };
    case "BULK_STEP1":
      return {
        ...state,
        step1: { ...state.step1, ...action.payload },
      };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: action.payload.error,
        },
      };
    default:
      return state;
  }
};
