export const threeYearsBack = (sub = 1) => {
  const currentDate = new Date(); // Get the current date
  const threeYearsBack = new Date(); // Create a new Date object
  return new Date(threeYearsBack.setFullYear(currentDate.getFullYear() - sub)); // Subtract 3 years
};

export const incrementByOneYear = (sub = 1, date) => {
  const toDate = new Date(date);

  // Increment the year by 1
  toDate.setFullYear(toDate.getFullYear() + sub);
  // Convert the Date object back to a string in the format YYYY-MM-DD
  const incrementedDate = toDate.toISOString().split("T")[0];
  // Update the to_date field in the data object
  return incrementedDate;
};

export const monthYear = (date) => {
  // const myDate = date.getMonth();
  // const myYear = date.getFullYear();
  // return `${months[myDate]}  ${myYear}`;
};

// Create an array of month names
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const YYMMDD = (date) => {
  const yy = date.getFullYear().toString();
  const mm = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const dd = date.getDate().toString().padStart(2, "0");

  const formattedDate = `${yy}-${mm}-${dd}`;
  return formattedDate;
};
