import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./HeaderNew.module.css";
import { Button } from "@mui/material";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import {
  formatDate,
  getPaymentStatus,
  getStatusById,
  getStatusByIds,
  steps,
} from "../../../Utility/utils";
import tick from "../../../assets/dashboard/tick.png";
import { PatchApi, getApi } from "../../../Utility/network";

function HeaderEnergy({ showDetails = false, ...props }) {
  let { id: _id } = useParams();
  const [item, setItem] = useState({});
  const { state, setState } = useContext(AppContext);
  const [page, setPage] = useState(0);
  let navigate = useNavigate();
  useEffect(() => {
    setPage(state.needFilledPage);
  }, [state]);

  const handleClick = (_type, _index) => {
    if (_type === "past" || _type === "active") {
      setState((prev) => ({ ...prev, needPage: _index }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = await getApi(`/energy_need/user_energy_need/${_id}`);
        setItem(postData.data);
        const {
          structure_detail,
          windowDimension,
          basement_detail,
          insulation_details,
          roof_detail,
          window_details,
          insulation,
          heating_systems,
          hot_water_systems,
          are_renewable_energy_used,
          do_u_have_solar_collector,
          unobstructed_collector_area,
          address,
          billing_address,
          building_type,
          construction_year,
          user,
          number_of_floors,
          occasion,
          number_of_apartments,
          floor_details,
        } = postData.data;
        let page = 1;

        if (structure_detail && structure_detail.hasOwnProperty("id")) {
          page = 2;
        }

        if (basement_detail) {
          page = 3;
        }
        if (heating_systems && heating_systems.length > 0) {
          page = 4;
        }
        if (address) {
          page = 5;
        }
        if (billing_address) {
          page = 8;
        }

        setState((prev) => ({
          ...prev,
          needPage: page,
          needFilledPage: page,
          needId: postData.data.id,
          needData: {
            ...prev.needData,
            structure_detail: structure_detail ? structure_detail : null,
            basement_detail,
            insulation_details,
            roof_detail,
            window_details,
            insulation,
            //   active: windowsNew.active,
            windowDimension,
            heating_systems,
            hot_water_systems,
            are_renewable_energy_used,
            do_u_have_solar_collector,
            unobstructed_collector_area,
            address,
            billing_address,

            floor_details,
          },
          otherData: {
            ...prev.otherData,
            buildingData: {
              building_type_id: building_type?.id,
              construction_year,
              email: user?.email,
              number_of_floors,
              occasion_id: occasion?.id,
              number_of_apartments,
            },
          },
        }));
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchData();
  }, [_id]);

  const handleIssue = async (type) => {
    // setState((prev) => ({ ...prev, page: prev.page + 1 }));
    const postEnergy = await getApi(
      `energy_need/${state.needId}/calculate_energy_consumption/`
    );

    if (postEnergy.status === 200 || postEnergy.status === 201) {
      const generate_pdf = await getApi(
        `records/${postEnergy?.data?.data?.id}/generate_energy_certificate/`
      );
      if (generate_pdf.status === 200 || generate_pdf.status === 201) {
        setState((prev) => ({
          ...prev,
          pdfId: postEnergy?.data?.data?.id,
        }));
        if (type !== 2) {
          patchData(type, postEnergy);
        } else {
          navigate(
            `/consumption-certificate/${state.needId}/${postEnergy?.data?.data?.id}`
          );
        }
      }
    }
  };

  const patchData = async (type, postEnergy) => {
    try {
      const postData = await PatchApi(`/energy_need/${state.needId}/`, {
        certificate_status: 2,
      });
      if (postData.status === 200 || postData.status === 201) {
        navigate(
          `/consumption-certificate/${state.needId}/${postEnergy?.data?.data?.id}`
        );
      }
    } catch (error) {}
  };

  return (
    <div className={styles.header}>
      {showDetails && item && (
        <div className={styles.headDEtails}>
          <div className={styles.left}>
            <p className={styles.name}>
              {item?.user?.first_name} {item?.user?.last_name} |{" "}
              {item?.user?.email}
            </p>
            <p className={styles.submitted}>Submitted On</p>
            <p className={styles.dates}>{formatDate(item.created)}</p>
          </div>
          <div className={styles.right}>
            <div className={styles.certificate}>
              <p className={styles.sname}>Certificate</p>
              <div className={styles.status}>
                <span>{getStatusById(item?.certificate_status)}</span>
              </div>
            </div>
            <div className={styles.certificate}>
              <p className={styles.sname}>Payment Status</p>
              <p
                className={styles.status1}
                style={{
                  backgroundColor: `${
                    item?.payment_status == 2
                      ? "linear-gradient(90deg, #22a156 0%, #afdb28 100%)"
                      : "#ffffff"
                  }`,
                }}
              >
                {getPaymentStatus(item?.payment_status)}
              </p>{" "}
            </div>

            <Button
              variant="contained"
              size="large"
              onClick={() =>
                handleIssue(
                  item?.certificate_status?.id
                    ? item.certificate_status?.id
                    : null
                )
              }
              disabled={
                !(
                  item?.payment_status == 2 &&
                  item?.recommendations.length > 0 &&
                  item?.serial_number.length > 0
                )
              }
              sx={{
                padding: "12px 25px",
                background: " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                boxShadow: "none",
                borderRadius: "10px",
                color: "white",
                "&:disabled": {
                  background: "gray",
                  color: "#ccc",
                },
              }}
            >
              {getStatusByIds(item?.certificate_status)?.id !== null &&
              getStatusByIds(item?.certificate_status)?.id !== 2
                ? "Issue"
                : "Issued"}
            </Button>
          </div>
        </div>
      )}
      <div className={styles.stepsCon}>
        {steps.map((steps, index) => {
          let ui;
          if (index + 1 === page) {
            ui = "active";
          } else if (index + 1 < page) {
            ui = "past";
          } else {
            ui = "future";
          }
          return (
            <div
              className={
                ui === "past"
                  ? `${styles.steps} ${styles.stepsa}`
                  : `${styles.steps} ${styles.stepsin}`
              }
            >
              <div className={styles.stepContainer}>
                <div
                  className={styles.circle}
                  style={{
                    border: `${
                      ui === "past"
                        ? "3px solid #008C76"
                        : ui === "future"
                        ? "3px solid #DDCA2380"
                        : "3px solid #ddca23"
                    }`,
                    background: `${
                      ui === "past"
                        ? "#008C76"
                        : ui === "future"
                        ? "#DDCA2380"
                        : ""
                    }`,
                    cursor: `${
                      ui === "past" || ui === "active" ? "pointer" : "auto"
                    }`,
                  }}
                  onClick={() => handleClick(ui, index + 1)}
                >
                  {ui === "active" && (
                    <div
                      className={styles.innerCircle}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></div>
                  )}
                  {ui === "past" && (
                    <img src={tick} className={styles.imgWid} />
                  )}
                </div>
              </div>
              <div className={styles.about}>
                <p className={styles.step}>{steps.step}</p>
                {steps.info.split("\n").map((line, index) => (
                  <p className={styles.stepName} key={index}>
                    {line}
                  </p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HeaderEnergy;
