import React from "react";
import styles from "./BuildingTabs.module.css";
function BuildingTabs({ type, setType = () => {}, ...props }) {
  return (
    <div>
      <div className={styles.itemContainer}>
        {buildingData.map((item, index) => {
          return (
            <div
              className={`${styles.itemTitle} ${
                item.id === type && styles.itemTitleActive
              }`}
              onClick={() => setType(item.id)}
            >
              {item.title}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BuildingTabs;

const buildingData = [
  {
    title: "Roof",
    id: 1,
  },
  {
    title: "Window",
    id: 2,
  },
  {
    title: "Basement",
    id: 3,
  },
  {
    title: "Insulation",
    id: 4,
  },
];
