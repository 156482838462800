import React, { useContext, useEffect, useState } from "react";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import styles from "./Step.module.css";
import { PatchApi, PostApi, getApi } from "../../../Utility/network";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckBoxService from "../../../Components/CustomCheckBoxService/CustomCheckBoxService";
import info from "../../../assets/componentAssets/info.svg";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";
import {
  validateEmail,
  validateGermanPhoneNumber,
  validateGermanVAT,
  validatePostalCode,
} from "../utility/validations";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import billing from "../../../assets/componentAssets/billing.svg";

function Step8({ showForm = false, props }) {
  const location = useLocation();
  const [isCertificate, setIsCertificate] = useState(false);
  useEffect(() => {
    const isConsumptionCertificate = location.pathname.includes(
      "/form/"
    );
    setIsCertificate(isConsumptionCertificate);
  }, [location]);
  const style = { width: "100%" };
  const navigate = useNavigate();
  const [breakdown, setBreakDown] = useState({
    sub_total: null,
    total: null,
    add_on_service: [],
  });
  const [checked, setChecked] = useState({ check: false });
  const { state, setState } = useContext(AppContext);
  const [showPop, setShowPop] = useState(false);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState([]);
  const [services, setServices] = useState([]);
  const [pop, setPop] = useState(false);
  const [infoText, setInfoText] = useState([]);
  const [data, setData] = useState({
    order_type: 1,
    first_name: "",
    last_name: "",
    phone: null,
    postcode: null,
    location: "",
    email: "",
    company_name: "",
    vat_id: "",
    street: "",
  });
  useEffect(() => {
    getbreakDown();
    getServices();
  }, []);
  useEffect(() => {
    const { lastStep } = state.otherData;
    if (lastStep) {
      setData(lastStep);
    }
  }, [state.otherData]);

  const getServices = async () => {
    try {
      const responseData = await getApi(`energy_consumption/add_on_services`);
      setServices(responseData.data.data);
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const error = validateFields(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const validateFields = (name, value) => {
    let error = "";
    const fieldName = "";
    // formStep1.find((field) => field.name === name)?.title || name;
    switch (name) {
      case "email":
        error = validateEmail(value);
        break;
      case "postcode":
        error = validatePostalCode(value);
        break;
      case "first_name":
      case "last_name":
      case "company_name":
      case "location":
      case "street":
        error = validateRequiredField(value, fieldName, "");
        break;
      case "phone":
        error = validateGermanPhoneNumber(value);
        break;
      case "vat_id":
        error = validateGermanVAT(value);
        break;
      default:
        break;
    }
    return error;
  };

  const validateRequiredField = (value, fieldName, type) => {
    if (type === "phone") {
      if (!value) {
        return `The ${fieldName} is required as a number.`;
      }
      if (/[-eE+]/.test(value)) {
        return `${fieldName} should not contain '-', 'e', or '+'.`;
      }
      return "";
    } else {
      return value ? "" : `this field is required`;
    }
  };

  const handleSubmit = async (num, type) => {
    try {
      if (type === "next") {
        try {
          const order_type = data.order_type;
          const { company_name, vat_id, ...rest } = data;
          let newData = { ...rest };
          const { company_name: company, vat_id: vat, ...rests } = errors;
          let newError = { ...rests };
          if (order_type == 2) {
            newData = { ...newData, company_name, vat_id };
            newError = { ...newError, company_name: company, vat: vat_id };
          } else if (order_type == 3) {
            newData = { ...newData, company_name };
            newError = { ...newError, company_name: company };
          }

          const validateFields = (object) => {
            const errors = {};
            Object.keys(object).forEach((key) => {
              const value = object[key];
              // Check if the value is either an empty string or null
              if (value === "" || value === null) {
                errors[key] = `this field cannot be empty.`;
              }
            });
            return errors;
          };
          const errorss = validateFields(newData);
          if (Object.keys(errorss).length === 0) {
          } else {
            setErrors(errorss);
            return;
          }
          const postData = await PatchApi(
            `/energy_consumption/form/${state.form_id}/`,
            { billing_address: newData }
          );
          if (postData.status === 200 || postData.status === 201) {
            const postEnergy = await getApi(
              `energy_consumption/form/${state.form_id}/calculate_energy_consumption/`
            );
            const payData = await PostApi(`/payment/checkout/`, {
              energy_consumption_id: state.form_id,
            });
            localStorage.setItem("purchase_id", payData.data.data.id);

            window.open(payData.data.data.gateway_url, "_blank").focus();
            setState((prev) => ({
              ...prev,
              otherData: {
                hotwater_included_in_heating_system: 0,
                construction_year: null,
                heating_info: [],
                yearArray: [],
                buildingData: {},
                buildingData: {},
                vacancy: [],
                noOfHeatingSystem: null,
                property: null,
                delAdd: null,
                info: {},
              },
              form_id: null,
              page: 1,
              filledPage: 1,
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({
          ...prev,
          page: prev.page - 1,
          otherData: { ...prev.otherData, lastStep: data },
        }));
      } else if (type === "save") {
        await getApi(`/energy_consumption/form/${state.form_id}/generate_link`);
        // navigate("/login");
      } else if (type === "submit-save") {
        try {
          const order_type = data.order_type;
          const { company_name, vat_id, ...rest } = data;
          let newData = { ...rest };
          const { company_name: company, vat_id: vat, ...rests } = errors;
          let newError = { ...rests };
          if (order_type == 2) {
            newData = { ...newData, company_name, vat_id };
            newError = { ...newError, company_name: company, vat: vat_id };
          } else if (order_type == 3) {
            newData = { ...newData, company_name };
            newError = { ...newError, company_name: company };
          }

          const validateFields = (object) => {
            const errors = {};
            Object.keys(object).forEach((key) => {
              const value = object[key];
              // Check if the value is either an empty string or null
              if (value === "" || value === null) {
                errors[key] = `this field cannot be empty.`;
              }
            });
            return errors;
          };
          const errorss = validateFields(newData);
          if (Object.keys(errorss).length === 0) {
          } else {
            setErrors(errorss);
            return;
          }
          const postData = await PatchApi(
            `/energy_consumption/form/${state.form_id}/`,
            { billing_address: newData }
          );
          if (postData.status === 200 || postData.status === 201) {
            setShowPop(true);
          }
        } catch (error) {
          alert("something went wrong");
        }
      }
    } catch (error) {
      alert("something went wrong");
    }
  };
  const getbreakDown = async () => {
    try {
      const responseData = await getApi(
        `energy_consumption/form/${state.form_id}/breakdown`
      );
      setBreakDown(responseData.data.data);
    } catch (error) {}
  };

  const handleCheckboxChange = async (name, checkedIds) => {
    // if (name === "ventilation_id") {
    //   setSubmitData((prev) => ({ ...prev, ventilation_id: checkedIds }));
    // }
    setSelected(checkedIds);
    const data = {
      service_id: checkedIds,
    };
    try {
      const responseData = await PatchApi(
        `energy_consumption/form/${state.form_id}/add_on_services/`,
        data
      );
      if (
        responseData.status === 200 ||
        responseData.status === 201 ||
        responseData.status === 204
      ) {
        getbreakDown();
      }
    } catch (error) {}
  };
  const handlePopUp = (data) => {
    setPop(true);
    setInfoText(data);
  };
  return (
    <div>
      <StepsHeader
        image={billing}
        heading={"Billing and Delivery Address"}
        secPara="Please provide proper information"
      />
      <div className={styles.billingCon}>
        <div className={styles.order}>
          <div className={styles.fieldCom}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Order Type
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.order)}
                />
              </p>
              <FormControl>
                <RadioGroup
                  name="order_type" // Name of the radio group
                  value={data.order_type} // Bind the state value
                  onChange={handleChange} // Handle value changes
                >
                  <div className={styles.Radio1}>
                    {orderType.map((item, index) => {
                      return (
                        <FormControlLabel
                          value={item.id}
                          control={<Radio />}
                          label={item.title}
                        />
                      );
                    })}
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Phone Number</p>
              <TextField
                style={style}
                size="small"
                name="phone"
                value={data.phone}
                onChange={handleChange} // Handle value changes
                type="number"
                error={errors?.phone}
                helperText={errors?.phone}
              />
            </div>
            {data.order_type != 1 && (
              <React.Fragment>
                <div
                  className={styles.fields}
                  style={{ width: `${data.order_type == 3 ? "100%" : "48%"}` }}
                >
                  <p className={styles.fieldName}>Company Name</p>
                  <TextField
                    size="small"
                    style={style}
                    value={data.company_name}
                    name="company_name"
                    onChange={handleChange} // Handle value changes
                    error={errors?.company_name}
                    helperText={errors?.company_name}
                  />
                </div>
                {data.order_type == 2 && (
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>VAT ID</p>
                    <TextField
                      size="small"
                      style={style}
                      value={data.vat_id}
                      name="vat_id"
                      onChange={handleChange} // Handle value changes
                      error={errors?.vat_id}
                      helperText={errors?.vat_id}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            <div className={styles.fields}>
              <p className={styles.fieldName}>First Name</p>
              <TextField
                size="small"
                style={style}
                value={data.first_name}
                name="first_name"
                onChange={handleChange} // Handle value changes
                error={errors?.first_name}
                helperText={errors?.first_name}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Surname</p>
              <TextField
                size="small"
                style={style}
                name="last_name"
                onChange={handleChange} // Handle value changes
                value={data.last_name}
                error={errors?.last_name}
                helperText={errors?.last_name}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Street & House Number
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.number)}
                />
              </p>
              <TextField
                size="small"
                style={style}
                name="street"
                onChange={handleChange} // Handle value changes
                value={data.street}
                error={errors?.street}
                helperText={errors?.street}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Postcode
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.Postcode)}
                />
              </p>
              <TextField
                size="small"
                style={style}
                name="postcode"
                onChange={handleChange} // Handle value changes
                value={data.postcode}
                error={errors?.postcode}
                helperText={errors?.postcode}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Location
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.Location)}
                />
              </p>
              <TextField
                size="small"
                style={style}
                name="location"
                onChange={handleChange} // Handle value changes
                value={data.location}
                error={errors?.location}
                helperText={errors?.location}
              />
            </div>
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Email
                <img
                  src={info}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePopUp(popData?.email)}
                />
              </p>
              <TextField
                size="small"
                style={style}
                name="email"
                onChange={handleChange} // Handle value changes
                value={data.email}
                error={errors?.email}
                helperText={errors?.email}
              />
            </div>
          </div>
          <div>
            <h3>Add On Services</h3>
            <CustomCheckBoxService
              data={services}
              name="service_id"
              onCheckChange={handleCheckboxChange}
              selectedValues={selected}
            />
          </div>
        </div>
        {isCertificate && (
          <div className={styles.summary}>
            <div style={{ borderBottom: "1px solid #22A156" }}>
              <p
                style={{
                  color: "#22A156",
                  fontWeight: "900",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                Order Summary
              </p>
            </div>
            <div style={{ borderBottom: "1px solid #22A156" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "80%" }}>
                  <p
                    style={{
                      marginBottom: "5px",
                      fontWeight: "900",
                      fontSize: "16px",
                    }}
                  >
                    <b>Energy Performance Certificate</b>
                  </p>
                  <p
                    style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                      fontSize: "14px",
                    }}
                  >
                    Consumption Certificate for Residential Buildings
                  </p>
                </div>
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "900",
                    fontSize: "16px",
                  }}
                >
                  {breakdown.sub_total} &#8364;
                </p>
              </div>
            </div>
            {breakdown.add_on_service.map((item, index) => {
              return (
                <div style={{ borderBottom: "1px solid #22A156" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "80%" }}>
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "16px",
                          fontWeight: "900",
                        }}
                      >
                        <b>{item.title}</b>
                      </p>
                      {/* <p
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        fontSize: "14px",
                      }}
                    >
                      Consumption Certificate for Residential Buildings
                    </p> */}
                    </div>
                    <p
                      style={{
                        marginBottom: "5px",
                        fontWeight: "900",
                        fontSize: "16px",
                      }}
                    >
                      {item.amount} &#8364;
                    </p>
                  </div>
                </div>
              );
            })}
            <div
              style={{ borderBottom: "1px solid #22A156", margin: "10px 0px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "30px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.check} // Reflect selected values
                          onChange={() => {
                            setChecked((prev) => ({ check: !prev.check }));
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </div>
                <div>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    I agree to the terms and conditions and have has taken note
                    of the provisions on data protection and cancellation policy
                  </p>{" "}
                  <a
                    style={{
                      margin: "0px",
                      fontSize: "14px",
                      lineHeight: "20px",
                      textDecoration: "underline",
                      color: "#22A156",
                    }}
                    href="https://greenox-group.de/datenschutz"
                    target="_blank"
                    // className={styles.terms}
                  >
                    Terms & conditions
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #22A156",
              }}
            >
              <p style={{ fontWeight: "900" }}>Total</p>
              <p>
                <b>{breakdown.total} &#8364;</b>
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ marginTop: "10px", width: "100%" }}
                variant="contained"
                onClick={() => handleSubmit(8, "next")}
                sx={{
                  padding: "12px 25px",
                  fontWeight: "900",
                  borderRadius: "10px",
                  background:
                    " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                  boxShadow: "none",
                  color: "white",
                  "&:disabled": {
                    background: "gray",
                    color: "#ccc",
                  },
                }}
                disabled={!checked.check}
              >
                Order Now
              </Button>
            </div>
          </div>
        )}
      </div>
      <NavButton
        handleSubmit={handleSubmit}
        show={false}
        showPrint={!isCertificate}
        showPop={showPop}
      />
      {pop && (
        <PopUpInfo
          handleClose={() => setPop(false)}
          open={pop}
          data={infoText}
        />
      )}
    </div>
  );
}

export default Step8;

const object = {
  billing_address: {
    order_type: 1,
    first_name: "dsd",
    last_name: "dsd",
    phone: "9146917230",
    email: "kiratwad@gmail.com",
    postcode: "12345",
    street: "sa",
    location: "sas",
  },
};

const orderType = [
  {
    title: "Private Owners",
    id: 1,
  },
  {
    title: "Commercial",
    id: 2,
  },
  {
    title: "Commercial (excluding USt.ID)",
    id: 3,
  },
];

const popData = {
  number: [
    {
      heading: "Street Or House Number",
      para1: `Please enter the address that should appear on the invoice. If you choose the optional postal delivery, we will also send your energy certificate to this address.
`,
      para2: `If you need different addresses for shipping and the invoice, please enter the additional address in the comments field or contact our customer service directly.
`,
      para3: `If your address is abroad, you can also enter a foreign address. We also ship abroad.`,
    },
  ],
  Postcode: [
    {
      heading: "Postcode",
      para1: `Please enter the address that should appear on the invoice. If you choose the optional postal delivery, we will also send your energy certificate to this address.
`,
      para2: `If you need different addresses for shipping and the invoice, please enter the additional address in the comments field or contact our customer service directly.
`,
      para3: `If your address is abroad, you can also enter a foreign address. We also ship abroad.`,
    },
  ],
  Location: [
    {
      heading: "Location",
      para1: `Please enter the address that should appear on the invoice. If you choose the optional postal delivery, we will also send your energy certificate to this address.
`,
      para2: `If you need different addresses for shipping and the invoice, please enter the additional address in the comments field or contact our customer service directly.
`,
      para3: `If your address is abroad, you can also enter a foreign address. We also ship abroad.`,
    },
  ],
  order: [
    {
      heading: "Order Type",
      para1: `Please select whether you are submitting the application as a private individual or as a company. If you are ordering as a business person with a VAT ID, select 'Business'. If you are ordering as a business person without a VAT ID, select either 'Private' or 'Commercial without VAT ID'.`,
    },
  ],
  email: [
    {
      heading: "Email",
      para1: `Please provide a valid email address so that we can contact you if we have any questions regarding your information.`,
      para2: `You will also receive the order confirmation and invoice via the email address you provided.`,
    },
  ],
};
