import React from 'react';
import arrow_left from '../assets/onboarding/arrow_left.svg';

function BackIconComponent(props) {
    return (
        <React.Fragment>
            <img alt='back' src={arrow_left} style={{ marginRight: '10px' }} /> Back to Log in
        </React.Fragment>
    );
}

export default BackIconComponent;