import React, { useContext, useEffect, useReducer, useState } from "react";
import styles from "./Step.module.css";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import Vacancy from "../../../assets/componentAssets/Vacancy.png";
import { InputAdornment, TextField } from "@mui/material";
import info from "../../../assets/componentAssets/info.svg";

import CustomInput from "../../../Components/CustomInput/CustomInput";
import { reducer } from "../consumptionReducer";
import { form4State } from "../InitialState/form4State";
import { formStep4 } from "../../../Utility/utils";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { PatchApi, getApi } from "../../../Utility/network";
import { incrementByOneYear, monthYear } from "../utility/utils";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";

function Step4() {
  const { state: contextState, setState } = useContext(AppContext);
  console.log(contextState, "statestate");

  const [state, dispatch] = useReducer(reducer, form4State);
  const [year, setYear] = useState();
  const [showData, setShowData] = useState([]);
  const [vacancyData, setVacancyData] = useState([]);
  const [pop, setPop] = useState(false);
  const [infoText, setInfoText] = useState([]);
  const [errors, setErrors] = useState([]);
  const validateHeatingInfoArray = (heatingInfoArray) => {
    // Initialize errors array
    const errors = vacancyData.map(() => ({
      from_date: "",
      to_date: "",
      percentage: "",
      climate_factor: "",
    }));

    vacancyData.forEach((vac, vaccIndex) => {
      const heatingErrors = errors[vaccIndex];
      if (
        vac.percentage == null ||
        vac.percentage === "" ||
        isNaN(vac.percentage) ||
        vac.percentage < 0
      ) {
        heatingErrors.percentage =
          "Percentage must be a positive number or zero.";
      }
    });
    console.log(errors, "errors");
    return errors;
  };
  const handleSubmit = async (num, type) => {
    const errors = validateHeatingInfoArray(vacancyData);
    const checkError = isAllFieldsEmpty(errors);
    setErrors(errors);
    if (!checkError) return;
    try {
      if (type === "next") {
        try {
          const postData = await PatchApi(
            `/energy_consumption/form/${contextState.form_id}/`,
            { vacancy: vacancyData }
          );
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              filledPage:
                prev.filledPage >= prev.page + 1
                  ? prev.filledPage
                  : prev.page + 1,
              page: prev.page + 1,
              otherData: { ...prev.otherData, vacancy: vacancyData },
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, page: prev.page - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_consumption/form/${contextState.form_id}/generate_link`);
        // navigate("/login");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };
  const style = { width: "100%" };
  useEffect(() => {
    // if (contextState.otherData.construction_year) {
    //   const data = vacancy.map((item, index) => {
    //     let addNum =
    //       Number(contextState.otherData.construction_year) + index + 1;
    //     return {
    //       ...item,
    //       from_date: addNum,
    //       to_date: addNum + 1,
    //     };
    //   });
    //   setShowData(data);
    // }
  }, [contextState.otherData.construction_year]);
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
      const datass = [...vacancyData];
      datass[index].percentage = e.target.value;
      setVacancyData(datass);
    }
    // const data = [...showData];
    // data[index].percentage = e.target.value;
    // console.log(data, "percent");
    // setShowData(data);
  };

  useEffect(() => {
    if (contextState.otherData && contextState.otherData.yearArray) {
      const newArr = contextState?.otherData?.yearArray?.map((item, index) => {
        return { ...item, show: 1 };
      });
      setShowData(newArr);
    }
  }, [contextState.otherData.yearArray]);

  useEffect(() => {
    setVacancyData((prev) => {
      const datas = [];
      const { vacancy } = contextState.otherData;
      if (vacancy && vacancy.length > 0) {
        return vacancy;
      } else {
        showData.forEach((item, index) => {
          if (index !== 0) return;
          item.consumption_info.forEach((item1, index1) => {
            const dd = {
              ...vacancy1,
              from_date: item1.date,
              to_date: incrementByOneYear(1, item1.date),
            };
            datas.push(dd);
          });
        });
        return datas;
      }
    });
  }, [showData, contextState.otherData]);

  useEffect(() => {
    const { vacancy } = contextState.otherData;
    if (vacancy) {
      setVacancyData(vacancy);
    }
  }, [contextState.otherData]);

  const handlePopUp = (data) => {
    setPop(true);
    setInfoText(data);
  };
  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={Vacancy}
          heading={"Vacancy Info"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          <p className={styles.text}>
            If the property was not fully occupied during the entire period, you
            can enter the vacancy rate in percent here.
          </p>
          <div className={styles.fieldCom}>
            {vacancyData.map((item, index) => {
              const date = new Date(item.from_date);
              // Get the month (0-based index, so add 1 to get 1-based month)
              const month = date.getMonth();
              // Get the year
              const year = date.getFullYear();
              const percentageErrorMessage = errors[index]?.percentage;
              return (
                <div className={styles.vacancyInput}>
                  <p className={styles.fieldName}>
                    {/* {monthYear(item1.date)} */}
                    Consumption for {monthsdd[month]} {year} to{" "}
                    {monthsdd[month - 1]} {year + 1}
                    <img
                      src={info}
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePopUp(popData?.vacancy)}
                    />
                  </p>
                  <TextField
                    size="small"
                    style={style}
                    onChange={(e) => handleChange(e, index)}
                    type="number"
                    // value={item.percentage}
                    value={item.percentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    error={percentageErrorMessage}
                    helperText={percentageErrorMessage}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
      {pop && (
        <PopUpInfo
          handleClose={() => setPop(false)}
          open={pop}
          data={infoText}
        />
      )}
    </div>
  );
}

export default Step4;

const vacancy = [
  {
    from_date: "2020-03-10",
    to_date: "2021-02-10",
    percentage: null,
    climate_factor: 1.14,
  },
  {
    from_date: "2021-03-10",
    to_date: "2022-02-10",
    percentage: null,
    climate_factor: 1.14,
  },
  {
    from_date: "2022-03-10",
    to_date: "2023-02-10",
    percentage: null,
    climate_factor: 1.18,
  },
];

const vacancy1 = {
  from_date: "2020-03-10",
  to_date: "2021-02-10",
  percentage: 0,
  climate_factor: 1.14,
};

const monthsdd = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const popData = {
  vacancy: [
    {
      heading: "Vacancy Percentage",
      para1:
        "Please enter the vacancy rate in % for an entire year and for the entire area.",
      para2: `Calculating the vacancy rate You add up the vacancy rate over the months and divide this by 12. Then multiply the value by 100 and you get the percentage.`,
      para3: `Example If the property was vacant for 3 months in 12 months, this would be 25%.
Calculation: 3 / 12 * 100 = 25%`,
    },
  ],
};
const isAllFieldsEmpty = (errors) => {
  // Iterate through each object in the errors array
  return errors.every((errorObj) => {
    // Check if all properties are empty strings (ignore arrays)
    return Object.keys(errorObj).every((key) => {
      const value = errorObj[key];
      // Check if the value is an empty string (ignore arrays)
      return typeof value === "string" && value === "";
    });
  });
};
