// src/reducers/formReducer.js
export const initialState1 = {
  loading: false,
  error: "",
  loggedin: false,
  userId: null,
  validateToken: null,
  data: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: true, error: "" };
    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };
    case "SET_LOGGED_IN":
      return {
        ...state,
        loggedin: true,
        loading: false,
        error: "",
        data: action.payload,
      };
    case "NEXT_STEP":
      return {
        ...state,
        loggedin: false,
        loading: false,
        error: "",
        validateToken: action.payload,
      };
    case "RESET":
      return initialState1;
    default:
      return state;
  }
};
