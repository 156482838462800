import React, { Fragment, useContext, useEffect, useState } from "react";
import styles from "./Step.module.css";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import Hot from "../../../assets/componentAssets/Hot.png";
import info from "../../../assets/componentAssets/info.svg";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import DateField from "../../../Components/DateField/DateField";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { PatchApi, getApi } from "../../../Utility/network";
import {
  atticTypes,
  belowGroundFloor,
  booleanValue,
  celingConstructionType,
  degreeArr,
  heatedPercent,
  insulationFields,
  monthYear,
  structure_type,
} from "../utility/utils";
import { zdate } from "../../../Utility/helper";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";
import { errorMessage } from "../../../Utility/constants";
import buildingComponent from "../../../assets/dashboard/buildingComponent.svg";
import BuildingTabs from "../BuildingTabs/BuildingTabs";
import CustomCheckBox from "../../../Components/CustomCheckBox/CustomCheckBox";
import CustomInput from "../../../Components/CustomInput/CustomInput";

function Step3() {
  const { state, setState } = useContext(AppContext);
  const [infoText, setInfoText] = useState([]);
  const [type, setType] = useState(1);
  const [glazingData, setGlazingData] = useState([]);
  const [windows, setWindows] = useState(null);
  const [structure, setStructure] = useState([]);
  const [errorsData, setErrorsData] = useState({ error: "" });
  const [windowsNew, setWindowsNew] = useState({
    structure_type: null,
    wall_details: [],
    window_details: [],
    active: {},
  });
  const [submitData, setSubmitData] = useState({
    ventilation: false,
    insulation: false,
    insulation_details: [],
    what_lies_below_ground_floor: "",
    ceiling_construction: "",
    window_details: [],
    construction: null,
    attic: null,
    roof_height: null,
    is_attic_heated: false,
    roof_pitch: null,
    does_property_have_knee_wall_or_seal: false,
    knee_wall: null,
    heating_percentage: null,
    is_dimension_available_for_every_window: false,
    is_there_second_attic: false,
    is_second_attic_heated: false,

    heating_percentage_of_second_attic: null,
    second_attic_height: null,
    heating_percentage_1: 0,

    ridge_length: null,
    ridge_width: null,
  });

  const { structure_detail } = state?.walls;
  const { structure_type, wall_details } = structure_detail;
  console.log(submitData, "windowsNew");
  useEffect(() => {
    let window_details = [];
    window_details = wall_details?.map((item) => ({
      window_type_id: null,
      attached_wall_id: item.id,
      is_dimension_available_for_every_window: false,
      percentage_of_window_area_on_the_wall: 0,
    }));
    console.log(window_details, wall_details, "submitData");

    setWindowsNew((prev) => ({
      ...prev,
      structure_type,
      window_details,
      wall_details,
      active: wall_details && wall_details.length > 0 ? wall_details[0] : {},
    }));
  }, [state.walls.structure_detail]);
  useEffect(() => {
    let window_details = [];

    if (submitData.is_dimension_available_for_every_window == false) {
      // window_details = Array.from(
      //   { length: windowsNew?.wall_details.length },
      //   (v, i) => {
      //     return {
      //       window_type_id: null,
      //       attached_wall_id:
      //         wall_details && wall_details.length > 0 ? wall_details[0].id : {},
      //       is_dimension_available_for_every_window: false,
      //       percentage_of_window_area_on_the_wall: null,
      //     };
      //   }
      // );
      if (windowsNew?.wall_details.length !== 0) {
        window_details = windowsNew?.wall_details?.map((item) => ({
          window_type_id: null,
          attached_wall_id: item.id,
          is_dimension_available_for_every_window: false,
          percentage_of_window_area_on_the_wall: 0,
        }));
      }
    } else {
      window_details = windowsNew?.wall_details?.map((item) => ({
        window_type_id: null,
        attached_wall_id: item.id,
        is_dimension_available_for_every_window: true,
        length: null,
        width: null,
        count: null,
      }));
    }

    setWindowsNew((prev) => ({
      ...prev,
      ...(window_details.length > 0 && { window_details }),
    }));
  }, [submitData.is_dimension_available_for_every_window]);
  useEffect(() => {
    if (submitData.insulation == "true" || submitData.insulation == true) {
      setSubmitData((prev) => ({
        ...prev,
        insulation_details: insulationFields,
      }));
    } else {
      setSubmitData((prev) => ({
        ...prev,
        insulation_details: [],
      }));
    }
  }, [submitData.insulation]);

  const [roof, setRoof] = useState([]);
  const [roofType, setRoofType] = useState({});
  const [pop, setPop] = useState(false);
  console.log(roofType, "roofType");
  function validateFields() {
    const errors = {};
    let hasErrors = false;
    const { id } = roofType;
    const { window_details } = windowsNew;
    const {
      construction,
      attic,
      is_attic_heated,
      roof_pitch,
      roof_height,

      is_dimension_available_for_every_window,

      what_lies_below_ground_floor,
      ceiling_construction,
      heating_percentage,

      insulation,
      insulation_details,
    } = submitData;
    const _data = {
      construction,
      ...(id != 3 && {
        attic,
        is_attic_heated,
        roof_pitch,
        roof_height,
      }),

      what_lies_below_ground_floor,
      ceiling_construction,
      ...(what_lies_below_ground_floor == 2 && {
        heating_percentage,
      }),

      insulation_details,

      id,

      window_details,
    };
    for (const key in _data) {
      if (Array.isArray(_data[key])) {
        if (key === "window_details") {
        }
      } else if (
        _data[key] === null ||
        _data[key] === "" ||
        _data[key] === false
      ) {
        errors[key] = "This field is required";
        hasErrors = true;
      }
    }
    console.log(errors, "errorserrors");
    setErrorsData({
      error: "Please enter details in Window, Basement, Insulation section",
    });
    return hasErrors;
  }
  const handleSubmit = async (num, _type) => {
    const errors = validateFields();
    if (errors) return;
    // "roof_shape_id": 1,

    // "construction": 10,

    // "attic": 1,

    // "is_attic_heated": true,

    // "roof_pitch": 121,

    // "roof_height": 12,

    // "does_property_have_knee_wall_or_seal": true,

    // "knee_wall": 12,

    // "number_of_attics": 1
    try {
      if (_type === "next") {
        let filteredData = windowsNew.window_details.filter(
          (item) => item.window_type_id !== null
        );
        const {
          construction,
          attic,
          is_attic_heated,
          roof_pitch,
          roof_height,
          does_property_have_knee_wall_or_seal,
          knee_wall,
          window_details,
          what_lies_below_ground_floor,
          ceiling_construction,
          insulation_details,
          is_there_second_attic,
          is_second_attic_heated,
          heating_percentage_of_second_attic,
          second_attic_height,
          heating_percentage,
          heating_percentage_1,
          ridge_length,
          ridge_width,
        } = submitData;
        const _data = {
          roof_detail: {
            roof_shape_id: roofType?.id,
            construction: construction,
            attic: attic,
            is_attic_heated: is_attic_heated == 1 ? true : false,
            roof_pitch: roof_pitch,
            roof_height: roof_height,
            does_property_have_knee_wall_or_seal:
              does_property_have_knee_wall_or_seal,
            ...(knee_wall == 1 && {
              knee_wall: knee_wall,
            }),
            number_of_attics: is_there_second_attic ? 2 : 1,
            is_there_second_attic,
            is_second_attic_heated,
            ...(heating_percentage_of_second_attic && {
              heating_percentage_of_second_attic:
                heating_percentage_of_second_attic,
            }),
            second_attic_height,
            ...(is_attic_heated == 1 && {
              heating_percentage: heating_percentage_1,
            }),
          },
          window_details: filteredData,
          insulation_details: insulation_details,
          ...(roofType?.id === 5 && { ridge_length: ridge_length }),
          ...(roofType?.id === 5 && { ridge_width: ridge_width }),

          basement_detail: {
            what_lies_below_ground_floor: what_lies_below_ground_floor,
            ceiling_construction: ceiling_construction,
            ...(what_lies_below_ground_floor == 2 && {
              heating_percentage: heating_percentage,
            }),
          },
        };
        try {
          const postData = await PatchApi(`/energy_need/${state.needId}/`, {
            ..._data,
          });
          if (postData.status === 200 || postData.status === 201) {
            const {
              basement_detail,
              insulation_details,
              roof_detail,
              window_details,
            } = postData.data.data;
            setState((prev) => ({
              ...prev,
              needPage: prev.needPage + 1,
              needData: {
                ...prev.needData,
                basement_detail,
                insulation_details,
                roof_detail,
                window_details,
                insulation: submitData.insulation,
                active: windowsNew.active,
                windowDimension:
                  submitData.is_dimension_available_for_every_window,
              },
              needFilledPage:
                prev.needFilledPage >= prev.needPage + 1
                  ? prev.needFilledPage
                  : prev.needPage + 1,
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (_type === "prev") {
        setState((prev) => ({ ...prev, needPage: prev.needPage - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_need/${state.needId}/generate_link/`);
        // navigate("/login");
        alert("link sent to your email");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "is_dimension_available_for_every_window" ||
      name == "is_second_attic_heated"
    ) {
      setSubmitData((prev) => ({
        ...prev,
        [name]: value == "true" ? true : false,
      }));
    } else {
      setSubmitData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleChangeIndex = (e, index) => {
    const { name, value } = e.target;
    setSubmitData((prev) => {
      const _data = prev.window_details;
      _data[index][name] = value;

      return { ...prev, window_details: _data };
    });
  };
  const handleChangeInsulation = (e, index) => {
    const { name, value } = e.target;
    setSubmitData((prevData) => {
      // Make a copy of the previous state
      const newData = [...prevData["insulation_details"]];
      // Update the specific field of the object at the given index
      newData[index] = { ...newData[index], [name]: value };
      return { ...prevData, insulation_details: newData }; // Return the updated array
    });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const {
      basement_detail,
      insulation_details,
      roof_detail,
      window_details,
      windowDimension,
      insulation,
    } = state.needData;
    if (roof_detail.hasOwnProperty("id")) {
      const {
        roof_shape,
        construction,
        attic,
        is_attic_heated,
        roof_pitch,
        roof_height,
        is_there_second_attic,
        is_second_attic_heated,
        heating_percentage_of_second_attic,
        second_attic_height,
        does_property_have_knee_wall_or_seal,
        knee_wall,
        heating_percentage: heating_percentage_1,
      } = roof_detail;
      // const { windowDimension } = window_details;
      const {
        what_lies_below_ground_floor,
        ceiling_construction,
        heating_percentage,
      } = basement_detail;
      setRoofType(roof_shape);
      setSubmitData((prev) => ({
        ...prev,
        construction,
        attic,
        is_attic_heated: is_attic_heated === true ? 1 : 2,
        roof_pitch,
        roof_height,
        is_there_second_attic,
        is_second_attic_heated,
        heating_percentage_of_second_attic,
        second_attic_height,
        does_property_have_knee_wall_or_seal,
        knee_wall,
        is_dimension_available_for_every_window: windowDimension,
        what_lies_below_ground_floor,
        ceiling_construction,
        heating_percentage,
        insulation_details,
        insulation,
        heating_percentage_1,
      }));
      // setWindowsNew((prev) => {
      //   const _data = prev.window_details;
      //   _data.push({
      //     attached_wall_id: attached_wall_id,
      //     is_dimension_available_for_every_window:
      //       is_dimension_available_for_every_window,
      //     count: null,
      //     length: null,
      //     width: null,
      //     window_type_id: null,
      //   });
      //   return { ...prev, window_details: _data };
      // });
    }
  }, [state.needData]);
  useEffect(() => {
    if (windows) {
      const result = Array.from({ length: windows }, (_, index) => {
        return { id: null, value: `Item ${index + 1}` };
      });
      const convertedArray = result.map((item) => ({
        window_type_id: item.id,
        length: null,
        width: null,
      }));
      setSubmitData((prev) => ({ ...prev, window_details: convertedArray }));
    } else {
      setSubmitData((prev) => ({ ...prev, window_details: [] }));
    }
  }, [windows]);
  const getData = async () => {
    try {
      const responseData = await getApi(`/energy_need/glazing_types/`);
      const responseRoof = await getApi(`/energy_need/roof_shapes/`);
      const matResponse = await getApi(
        `/energy_need/material_types?type=2&year=${state?.needData.construction_year}`
      );
      setGlazingData(responseData?.data?.data);
      setRoof(responseRoof.data.data);
      setStructure(matResponse.data.data);
      if (responseRoof?.data?.data && responseRoof?.data?.data?.length > 0) {
        setRoofType(responseRoof.data.data[0]);
      }
    } catch (error) {}
  };
  const handleClick = (item) => {
    setRoofType(item);
  };
  const handleChangeWindow = (e, index, type) => {
    const { name, value } = e.target;
    setWindowsNew((prev) => {
      const _data = prev.window_details;
      _data[index][name] = value;
      return { ...prev, window_details: _data };
    });
  };
  const handleAdd = (item) => {
    const { attached_wall_id, is_dimension_available_for_every_window } = item;
    setWindowsNew((prev) => {
      const _data = prev.window_details;
      _data.push({
        attached_wall_id: attached_wall_id,
        is_dimension_available_for_every_window:
          is_dimension_available_for_every_window,
        count: null,
        length: null,
        width: null,
        window_type_id: null,
      });
      return { ...prev, window_details: _data };
    });
  };

  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={buildingComponent}
          heading={"Building Component"}
          secPara="Provide your proper information."
        />
        <div className={styles.fieldDetails}>
          <BuildingTabs type={type} setType={setType} />
          <p style={{ color: "#d32f2f" }}>{errorsData.error}</p>

          {type === 1 && (
            <div>
              <p className={styles.fieldName}>Roof Shape</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {roof.map((item, index) => {
                  return (
                    <div
                      style={{
                        background: "#AFDB2840",
                        display: "flex",
                        padding: "8px",
                        borderRadius: "10px",
                        alignItems: "center",
                        border: `${
                          item.id === roofType?.id
                            ? "2px solid #22A156"
                            : "2px solid white"
                        }`,
                        cursor: "pointer",
                      }}
                      onClick={() => handleClick(item)}
                    >
                      <img src={item.image} style={{ marginRight: "10px" }} />
                      <p style={{ margin: "0px" }}>{item.title}</p>
                    </div>
                  );
                })}
              </div>
              <div>
                <div>
                  <div className={styles.fieldCom}>
                    <div className={styles.fields}>
                      <p className={styles.fieldName}>Structure</p>
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          // value={submitData.material_id}
                          name="construction"
                          value={submitData.construction}
                          onChange={(e) => handleChange(e)}
                        >
                          {structure.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {roofType?.is_attic_required && (
                      <>
                        <div className={styles.fields}>
                          <p className={styles.fieldName}>Attic</p>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              // value={submitData.material_id}
                              name="attic"
                              value={submitData.attic}
                              onChange={(e) => handleChange(e)}
                            >
                              {atticTypes.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className={styles.fields}>
                          <p className={styles.fieldName}>Attic Type</p>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              // value={submitData.material_id}
                              name="is_attic_heated"
                              value={submitData.is_attic_heated}
                              onChange={(e) => handleChange(e)}
                              submitData
                            >
                              {booleanValue.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className={styles.fields}>
                          <p className={styles.fieldName}>Roof pitch</p>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              // value={submitData.material_id}
                              name="roof_pitch"
                              value={submitData.roof_pitch}
                              onChange={(e) => handleChange(e)}
                            >
                              {degreeArr.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className={styles.fields}>
                          <p className={styles.fieldName}>Attic Height</p>
                          <TextField
                            style={{ width: "100%" }}
                            size="small"
                            name="roof_height"
                            onChange={(e) => handleChange(e)}
                            value={submitData.roof_height}
                          />
                        </div>

                        {roofType?.id === 5 && (
                          <React.Fragment>
                            <div className={styles.fields}>
                              <p className={styles.fieldName}>Ridge length</p>
                              <TextField
                                style={{ width: "100%" }}
                                size="small"
                                name="ridge_length"
                                onChange={(e) => handleChange(e)}
                                value={submitData.ridge_length}
                              />
                            </div>

                            <div className={styles.fields}>
                              <p className={styles.fieldName}>Ridge Width</p>
                              <TextField
                                style={{ width: "100%" }}
                                size="small"
                                name="ridge_width"
                                onChange={(e) => handleChange(e)}
                                value={submitData.ridge_width}
                              />
                            </div>
                          </React.Fragment>
                        )}
                        {submitData.is_attic_heated === 1 && (
                          <div className={styles.fields}>
                            <p className={styles.fieldName}>
                              Heating Percentage
                            </p>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              name="heating_percentage_1"
                              onChange={(e) => handleChange(e)}
                              value={submitData.heating_percentage_1}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {roofType?.id !== 3 && (
                <Fragment>
                  {roofType?.id === 1 && (
                    <div className={styles.fieldDetailsCon}>
                      <p className={styles.fieldName}>Is there second attic</p>
                      <RadioGroup
                        name="is_there_second_attic" // Name of the radio group
                        value={submitData.is_there_second_attic} // Bind the state value
                        onChange={(e) => handleChange(e)}
                      >
                        <div className={styles.Radio}>
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="no"
                          />
                        </div>
                      </RadioGroup>
                      {submitData.is_there_second_attic && (
                        <div>
                          <p className={styles.fieldName}>
                            Is there second attic heated
                          </p>
                          <RadioGroup
                            name="is_second_attic_heated" // Name of the radio group
                            value={submitData.is_second_attic_heated} // Bind the state value
                            onChange={(e) => handleChange(e)}
                          >
                            <div className={styles.Radio}>
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="yes"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="no"
                              />
                            </div>
                          </RadioGroup>
                          <div className={styles.fieldCom}>
                            {submitData.is_second_attic_heated && (
                              <div className={styles.fields}>
                                <p className={styles.fieldName}>
                                  Heating percentage of second attic
                                </p>
                                <TextField
                                  style={{ width: "100%" }}
                                  size="small"
                                  name="heating_percentage_of_second_attic"
                                  onChange={(e) => handleChange(e)}
                                  value={
                                    submitData.heating_percentage_of_second_attic
                                  }
                                />
                              </div>
                            )}
                            <div className={styles.fields}>
                              <p className={styles.fieldName}>
                                Second Attic height
                              </p>{" "}
                              <TextField
                                style={{ width: "100%" }}
                                size="small"
                                name="second_attic_height"
                                onChange={(e) => handleChange(e)}
                                value={submitData.second_attic_height}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className={styles.fieldDetailsCon}>
                    <p className={styles.fieldName}>
                      Does property have a knee wall or sill?
                    </p>
                    <RadioGroup
                      name="does_property_have_knee_wall_or_seal" // Name of the radio group
                      value={submitData.does_property_have_knee_wall_or_seal} // Bind the state value
                      onChange={(e) => handleChange(e)}
                    >
                      <div className={styles.Radio}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="no"
                        />
                      </div>
                    </RadioGroup>

                    {(submitData.does_property_have_knee_wall_or_seal == true ||
                      submitData.does_property_have_knee_wall_or_seal ==
                        "true") && (
                      <div>
                        <p className={styles.fieldName}>
                          Knee wall / sill height
                        </p>
                        <TextField
                          name="knee_wall"
                          onChange={handleChange}
                          value={submitData.knee_wall}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}
          {type === 2 && (
            <div>
              <div className={styles.fieldCom}>
                <div
                  style={{
                    background: "#AFDB2840",
                    marginTop: "15px",
                    padding: "10px",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <p>
                    The reported age of your windows, 47 years, seems unusually
                    high. Please verify this information. If it is accurate, you
                    may proceed.
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <p className={styles.fieldName}>
                    Do you have dimensions of each window?
                  </p>
                  <RadioGroup
                    name="is_dimension_available_for_every_window" // Name of the radio group
                    value={submitData.is_dimension_available_for_every_window} // Bind the state value
                    onChange={(e) => handleChange(e)}
                  >
                    <div className={styles.Radio}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="no"
                      />
                    </div>
                  </RadioGroup>
                  <div
                    style={{
                      background: "#AFDB2840",
                      marginTop: "15px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <p className={styles.fieldName}>
                      Please enter all the windows in Wall A
                    </p>
                    <p style={{ lineHeight: "24px" }}>
                      Since May 2021, the Building Energy Act (GEG) requires
                      detailed images of the renovation status, building
                      envelope, and system technology (such as heat generators)
                      to be submitted. However, only the image of the building
                      is shown on the energy certificate.
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "15px",
                    }}
                  >
                    {windowsNew?.wall_details?.map((item, index) => {
                      const value = item.length ? item.length : "";
                      const letter = String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
                      return (
                        <div
                          style={{
                            border: `${
                              windowsNew?.active?.id === item.id
                                ? "1px solid #22A156"
                                : "1px solid #22A156"
                            }`,
                            background: `${
                              windowsNew?.active?.id === item.id
                                ? "#22A156"
                                : "white"
                            }`,
                            color: `${
                              windowsNew?.active?.id !== item.id
                                ? "#22A156"
                                : "white"
                            }`,
                            cursor: "pointer",
                            padding: "10px",
                            marginRight: "10px",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            setWindowsNew((prev) => ({
                              ...prev,
                              active: item,
                            }));
                          }}
                        >
                          <p style={{ margin: "0px" }}> Wall {letter}</p>
                        </div>
                      );
                    })}
                  </div>
                  {!submitData.is_dimension_available_for_every_window ? (
                    <div className={styles.fields}>
                      {windowsNew.window_details?.map((item, index) => {
                        console.log(item, "hello item");
                        if (windowsNew?.active?.id == item.attached_wall_id)
                          return (
                            <div style={{ display: "flex" }}>
                              <div>
                                <p className={styles.fieldName}>Window type</p>
                                <FormControl fullWidth>
                                  <Select
                                    size="small"
                                    // value={submitData.material_id}
                                    name="window_type_id"
                                    value={item.window_type_id}
                                    style={{
                                      width: "300px",
                                      marginRight: "15px",
                                    }}
                                    onChange={(e) =>
                                      handleChangeWindow(e, index)
                                    }
                                  >
                                    {glazingData.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <div>
                                <p className={styles.fieldName}>
                                  Percentage of Window Area on the Wall
                                </p>
                                <TextField
                                  value={
                                    item.percentage_of_window_area_on_the_wall
                                  }
                                  onChange={(e) => handleChangeWindow(e, index)}
                                  name="percentage_of_window_area_on_the_wall"
                                  size="small"
                                  style={{ minWidth: "400px" }}
                                />
                              </div>
                            </div>
                          );
                      })}
                    </div>
                  ) : (
                    <div>
                      <div className={styles.fields}>
                        {windowsNew.window_details?.map((item, index) => {
                          if (windowsNew?.active?.id == item.attached_wall_id)
                            return (
                              <div style={{ display: "flex" }}>
                                <div>
                                  <p className={styles.fieldName}>Type</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      // value={submitData.material_id}
                                      name="window_type_id"
                                      value={item.window_type_id}
                                      style={{
                                        width: "300px",
                                        marginRight: "15px",
                                      }}
                                      onChange={(e) =>
                                        handleChangeWindow(e, index)
                                      }
                                    >
                                      {glazingData.map((option) => (
                                        <MenuItem
                                          key={option.id}
                                          value={option.id}
                                        >
                                          {option.title}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div>
                                  <p className={styles.fieldName}>Length</p>
                                  <TextField
                                    name="length"
                                    value={item.length}
                                    size="small"
                                    style={{
                                      width: "200px",
                                      marginRight: "15px",
                                    }}
                                    onChange={(e) =>
                                      handleChangeWindow(e, index)
                                    }
                                  />
                                </div>
                                <div>
                                  <p className={styles.fieldName}>Width</p>
                                  <TextField
                                    name="width"
                                    value={item.width}
                                    size="small"
                                    style={{
                                      width: "200px",
                                      marginRight: "15px",
                                    }}
                                    onChange={(e) =>
                                      handleChangeWindow(e, index)
                                    }
                                  />
                                </div>
                                <div>
                                  <p className={styles.fieldName}>Count</p>
                                  <TextField
                                    name="count"
                                    value={item.count}
                                    size="small"
                                    style={{
                                      width: "200px",
                                      marginRight: "15px",
                                    }}
                                    onChange={(e) =>
                                      handleChangeWindow(e, index)
                                    }
                                  />
                                </div>
                                <div>
                                  <p className={styles.fieldName}>&nbsp;</p>
                                  <Button
                                    variant="contained"
                                    onClick={() => handleAdd(item)}
                                  >
                                    +
                                  </Button>
                                </div>
                              </div>
                            );
                        })}
                      </div>
                    </div>
                  )}

                  {/* <div>
                    <TextField
                      size="small"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setWindows(e.target.value);
                      }}
                    />
                  </div> */}
                  <div className={styles.fields}>
                    {}
                    {/* {submitData.window_details.map((item, index) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div>
                            <p className={styles.fieldName}>Type</p>
                            <div>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  // value={submitData.material_id}
                                  name="window_type_id"
                                  value={item.window_type_id}
                                  onChange={(e) => handleChangeIndex(e, index)}
                                  style={{
                                    width: "300px",
                                    marginRight: "15px",
                                  }}
                                >
                                  {glazingData.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div>
                            <p className={styles.fieldName}>Length</p>
                            <TextField
                              name="length"
                              value={item.length}
                              size="small"
                              style={{ width: "200px", marginRight: "15px" }}
                              onChange={(e) => handleChangeIndex(e, index)}
                            />
                          </div>
                          <div>
                            <p className={styles.fieldName}>Width</p>
                            <TextField
                              name="width"
                              value={item.width}
                              size="small"
                              style={{ width: "200px", marginRight: "15px" }}
                              onChange={(e) => handleChangeIndex(e, index)}
                            />
                          </div>
                        </div>
                      );
                    })} */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {type === 3 && (
            <div>
              <div className={styles.fieldCom}>
                <div className={styles.fields}>
                  <p className={styles.fieldName}>
                    What lies below the ground floor?
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      // value={submitData.material_id}
                      name="what_lies_below_ground_floor"
                      value={submitData.what_lies_below_ground_floor}
                      onChange={(e) => handleChange(e)}
                    >
                      {belowGroundFloor.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.fields}>
                  <p className={styles.fieldName}>Floor slab</p>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      // value={submitData.material_id}
                      name="ceiling_construction"
                      value={submitData.ceiling_construction}
                      onChange={(e) => handleChange(e)}
                    >
                      {celingConstructionType.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {submitData.what_lies_below_ground_floor == "2" && (
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Heated part of basement</p>

                    <FormControl fullWidth>
                      <Select
                        size="small"
                        // value={submitData.material_id}
                        name="heating_percentage"
                        value={submitData.heating_percentage}
                        onChange={(e) => handleChange(e)}
                      >
                        {heatedPercent.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
          )}
          {type === 4 && (
            <div>
              <div className={styles.fieldCon}>
                <p className={styles.fieldName}>Is the property insulated?</p>
                <RadioGroup
                  name="insulation" // Name of the radio group
                  value={submitData.insulation} // Bind the state value
                  onChange={handleChange} // Handle value changes
                >
                  <div className={styles.Radio}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="no"
                    />
                  </div>
                </RadioGroup>
                {(submitData.insulation == "true" || submitData.insulation) && (
                  <div className={styles.fields}>
                    {submitData.insulation_details.map((item, index) => {
                      return (
                        <div>
                          <p className={styles.fieldName}>{item.title}</p>
                          <TextField
                            style={{ width: "400px" }}
                            size="small"
                            showInfo={false}
                            name="length"
                            endIcon="cm"
                            onChange={(e) => handleChangeInsulation(e, index)}
                            value={item.length}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
      {pop && (
        <PopUpInfo
          handleClose={() => setPop(false)}
          open={pop}
          data={infoText}
        />
      )}
    </div>
  );
}

export default Step3;
