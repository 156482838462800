import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const HamburgerMenu = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {isMobile && (
          <AppBar
            position="static"
            sx={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <Toolbar sx={{ justifyContent: "center" }}>
              <IconButton
                edge="start"
                color="black"
                aria-label="menu"
                onClick={handleToggle}
              >
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Toolbar>
          </AppBar>
        )}

        {open && (
          <Box
            sx={{
              position: "absolute",
              top: "80px", // Adjust this based on your AppBar height
              left: 0,
              right: 0,
              bgcolor: "background.paper",
              boxShadow: 3,
              zIndex: 1,
              justifyContent: "center",
              fontSize: "16px",
            }}
          >
            <List>
              {["Consumtion Certificate", "Need Certificate"].map(
                (text, index) => (
                  <ListItem button key={text}>
                    <ListItemText
                      onClick={() => {
                        index === 0 && setOpen(false);
                      }}
                      primary={text}
                      style={{ color: `${index === 0 ? "#22a156" : "grey"}` }}
                    />
                  </ListItem>
                )
              )}
            </List>
          </Box>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default HamburgerMenu;
