import React, { useContext, useEffect, useReducer, useState } from "react";
import styles from "./Step.module.css";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import PropertyAddress from "../../../assets/componentAssets/properttyAddress.png";
import info from "../../../assets/componentAssets/info.svg";
import defaultImage from "../../../assets/componentAssets/defaultImage.svg";
import { FormHelperText, InputAdornment, TextField } from "@mui/material";
import { reducer } from "../consumptionReducer";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import { formStep6 } from "../../../Utility/utils";
import { form6State } from "../InitialState/form6State";
import BuildingData from "../../../Components/BuildingData/BuildingData";
import UpdatedFile from "../../../Components/Greenox/UpdatedFile/UpdatedFile";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { PatchApi, base_url, getApi } from "../../../Utility/network";
import UploadImage from "../../../Components/UploadImage/UploadImage";
import axios from "axios";
import uploadIm from "../../../assets/componentAssets/upload.svg";

function Step6() {
  const { state: contextState, setState } = useContext(AppContext);
  const [image, setImage] = useState();
  const [image1, setImage1] = useState();
  const [errorsData, setErrorsData] = useState({});
  const [buildingImg, setBuildingImg] = useState([]);
  const style = { width: "100%" };

  const handleSubmit = async (num, type) => {
    if (buildingImg.length === 0) {
      setErrorsData({ building: "please upload image" });
      return;
    } else {
      try {
        if (type === "next") {
          setState((prev) => ({
            ...prev,
            needPage: prev.needPage + 1,
            needFilledPage:
              prev.needFilledPage >= prev.needPage + 1
                ? prev.needFilledPage
                : prev.needPage + 1,
          }));
          try {
            // setState((prev) => ({ ...prev, page: prev.page + 1 }));
          
          } catch (error) {
            alert(error);
          }
        } else if (type === "prev") {
          setState((prev) => ({ ...prev, needPage: prev.needPage - 1 }));
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (image) {
      uploadImageApi(1, image);
    }
  }, [image]);

  useEffect(() => {
    if (image1) {
      uploadImageApi(3, image1);
    }
  }, [image1]);
  useEffect(() => {
    getData();
  }, []);

  const uploadImageApi = (_type, _image) => {
    const formData = new FormData();
    formData.append("image", _image);
    formData.append("type", _type);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .patch(
        `${base_url}/energy_need/${contextState.needId}/upload_building_photos/`,
        formData,
        axiosConfig
      )
      .then((response) => {
        setImage();
        getData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getData = async () => {
    try {
      const response = await getApi(`/energy_need/${contextState.needId}/`);
      setBuildingImg(response.data.data.building_images);
    } catch (error) {}
  };

  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={uploadIm}
          heading={"Property Address"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          <div
            style={{
              background: "#AFDB2840",
              marginTop: "15px",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <p className={styles.fieldName}>Information on file upload</p>
            <p>
              Since May 2021, the Building Energy Act (GEG) requires detailed
              images of the renovation status, building envelope, and system
              technology (such as heat generators) to be submitted. However,
              only the image of the building is shown on the energy certificate.
            </p>
          </div>
          <div></div>
          <div>
            <p>
              <b>Photo of the heater </b>
            </p>
            <UploadImage getImage={setImage} imgs={image} />
            <div style={{ marginTop: "20px" }}>
              {buildingImg.map((item) => {
                const filename = item.image.split("/").pop();
                if (item.type !== 1) return;

                return (
                  <div
                    style={{ background: "#AFDB2826" }}
                    className={styles.imageContainer}
                  >
                    <div>
                      <img
                        src={item.image}
                        style={{ marginRight: "15px", width: "80px" }}
                      />
                    </div>
                    <div>{filename}</div>
                    <div></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.fieldDetails}>
          <div>
            <p>
              <b>Photo of the Building </b>
            </p>
            <UploadImage getImage={setImage1} imgs={image1} />
            <div style={{ marginTop: "20px" }}>
              {buildingImg.map((item) => {
                const filename = item.image.split("/").pop();
                if (item.type !== 3) return;
                return (
                  <div
                    style={{ background: "#AFDB2826" }}
                    className={styles.imageContainer}
                  >
                    <div>
                      <img
                        src={item.image}
                        style={{ marginRight: "15px", width: "80px" }}
                      />
                    </div>
                    <div>{filename}</div>
                    <div></div>
                  </div>
                );
              })}
            </div>
          </div>
          {errorsData?.building && (
            <FormHelperText error={errorsData.building}>
              {errorsData?.building}
            </FormHelperText>
          )}
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
    </div>
  );
}

export default Step6;
const address = {
  number: [
    {
      heading: "Street Or House Number",
      para1:
        " Please enter the address of the building for which the energy certificate is to be created.",
      para2:
        " You can enter the billing and delivery address in the next step.",
    },
  ],
  Postcode: [
    {
      heading: "Postcode",
      para1:
        " Please enter the address of the building for which the energy certificate is to be created.",
      para2:
        " You can enter the billing and delivery address in the next step.",
    },
  ],
  Location: [
    {
      heading: "Location",
      para1:
        " Please enter the address of the building for which the energy certificate is to be created.",
      para2:
        " You can enter the billing and delivery address in the next step.",
    },
  ],
};
