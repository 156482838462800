import React, { useContext, useEffect, useState } from "react";
import styles from "./Step.module.css";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import Hot from "../../../assets/componentAssets/Hot.png";
import info from "../../../assets/componentAssets/info.svg";
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DateField from "../../../Components/DateField/DateField";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { PatchApi, getApi } from "../../../Utility/network";
import { monthYear } from "../utility/utils";
import { zdate } from "../../../Utility/helper";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";
import { errorMessage } from "../../../Utility/constants";

function Step3() {
  const { state, setState } = useContext(AppContext);
  const [hotWater, setHotWater] = useState([]);
  console.log(hotWater, "statestate");
  const [unit, setUnit] = useState(Array.from({ length: 4 }, () => []));
  const [energy, setEnergy] = useState({ source: [], unit: [] });
  const [show, setShow] = useState(1);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({
    Date: new Date(),
  });
  const [pop, setPop] = useState(false);
  const [infoText, setInfoText] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      const source = await getApi(`/energy_consumption/energy_source`);
      const unit = await getApi(`/energy_consumption/energy_source_units`);
      setEnergy({ source: source.data.data, unit: unit.data.data });
    } catch (error) {}
  };

  const handleSubmit = async (num, type) => {
    const updatedHeatingInfo = hotWater.map((info) => {
      if (info.hasOwnProperty("hot_water_data")) {
        // hot_water_data exists, proceed with the transformation
        return {
          ...info,
          hot_water_data: {
            ...info.hot_water_data,
            consumption_info: info.hot_water_data.consumption_info.map(
              (item) => ({
                ...item,
                date: zdate(item.date), // Update the date format
              })
            ),
          },
        };
      } else {
        // hot_water_data doesn't exist, return the original info
        return info;
      }
    });

    try {
      if (type === "next") {
        const errors = validateHeatingInfoArray(updatedHeatingInfo);
        const checkError = isAllFieldsEmpty(errors);
        setErrors(errors);

        if (!checkError) return;
        try {
          const postData = await PatchApi(
            `/energy_consumption/form/${state.form_id}/`,
            { heating_info: updatedHeatingInfo }
          );
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              page: prev.page + 1,
              filledPage:
                prev.filledPage >= prev.page + 1
                  ? prev.filledPage
                  : prev.page + 1,
              otherData: {
                ...prev.otherData,
                yearArray: updatedHeatingInfo,
              },
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, page: prev.page - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_consumption/form/${state.form_id}/generate_link`);
        // navigate("/login");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };

  useEffect(() => {
    // const arr = [];
    // const numberOfElements =
    //   state.otherData.hotwater_included_in_heating_system; // Assuming this is a number

    // // Initialize a new array of the desired length and fill it with a placeholder value (like null)
    // const waterObject = Array.from({ length: numberOfElements }).map(
    //   (_, index) => {
    //     arr.push(obj); // Add the object to `arr`
    //     return obj; // Return the object to the new array
    //   }
    // );

    if (state.otherData && state.otherData.yearArray) {
      state.otherData.yearArray.forEach((item, index) => {
        if (energy.source.length > 0) {
          if (item.hot_water_data) {
            let newIndex = energy.source.findIndex(
              (obj) => item.hot_water_data.energy_source_id === obj.id
            );
            unit[index] = energy.source[newIndex].unit;

            setUnit((prev) => [...unit]);
          }
        }
      });
      const newArr = state?.otherData?.yearArray?.map((item, index) => {
        return { ...item, type: item.type ? item.type : 1 };
      });
      setHotWater(newArr);
    }
  }, [state.otherData.yearArray, energy]);
  const handleClick = (_type, pos) => {
    const newArr = hotWater?.map((item, index) => {
      if (pos == index) {
        if (_type === 1) {
          const { hot_water_data, ...rest } = item; // Destructure to omit hot_water_data
          return {
            ...rest,
            type: _type,
            hotwater_included_in_heating_system: _type,
          };
        } else {
          let newData = [...item.consumption_info];
          const _data = newData.map((itm, idx) => {
            return { ...itm, consumption: "" };
          });
          return {
            ...item,
            type: _type,
            hot_water_data: {
              ...item,
              consumption_info: _data,
              energy_source_id: null,
              unit_id: null,
            },
          };
        }
      } else {
        return item;
      }
    });
    setHotWater(newArr);
  };
  const options = [];
  const style = { width: "100%" };
  const handleChange = (e, index, _type) => {
    // setData(prev=>({...prev,heating_info:}))
    const { value, name } = e.target; // Get the input value
    setHotWater((prevData) => {
      // Make a copy of the previous state
      const newData = [...prevData];
      // Update the specific field of the object at the given index
      newData[index].hot_water_data[name] = value;
      return newData; // Return the updated array
    });
  };
  const handleChange1 = (e, index, index1) => {
    const { value, name } = e.target; // Get the input value

    setHotWater((prevData) => {
      const newData = [...prevData];
      // Directly access the nested object property and update it
      newData[index].hot_water_data.consumption_info[index1].consumption =
        value;
      // console.log(newData, "hello data");
      return newData;
    });
  };
  const handleChangeIn = (e, index) => {
    const { name, value } = e.target;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
      setHotWater((prevData) => {
        // Make a copy of the previous state
        const newData = [...prevData];
        // Update the specific field of the object at the given index
        newData[index] = { ...newData[index], [name]: value };
        return newData; // Return the updated array
      });
    }
  };

  const handlePopUp = (data) => {
    setPop(true);
    setInfoText(data);
  };
  const handleDate = () => {};

  const validateHeatingInfoArray = (heatingInfoArray) => {
    // Initialize errors array
    const errors = heatingInfoArray.map(() => ({
      energy_source_id: "",
      unit_id: "",
      type: "",
      installation_year: "",
      hotwater_included_in_heating_system: "",
      percentage: "",
      consumption_info: [],
      hot_water_data: {
        energy_source_id: "",
        unit_id: "",
        type: "",
        installation_year: "",
        hotwater_included_in_heating_system: "",
        percentage: "",
        consumption_info: [],
      },
    }));

    heatingInfoArray.forEach((heatingInfo, heatingIndex) => {
      const heatingErrors = errors[heatingIndex];
      // Validate energy_source_id
      if (heatingInfo.energy_source_id === null) {
        heatingErrors.energy_source_id = "Energy source is required.";
      }

      // Validate unit_id
      if (heatingInfo.unit_id === null) {
        heatingErrors.unit_id = "Unit ID is required.";
      }

      // Uncomment and use these validation checks as needed
      /*
      // Validate type
      if (typeof heatingInfo.type !== "number" || heatingInfo.type <= 0) {
        heatingErrors.type = "Type must be a positive number.";
      }
  
      // Validate installation_year
      if (
        typeof heatingInfo.installation_year !== "number" ||
        heatingInfo.installation_year <= 0
      ) {
        heatingErrors.installation_year =
          "Installation year must be a positive number.";
      }
  
      // Validate hotwater_included_in_heating_system
      if (![0, 1].includes(heatingInfo.hotwater_included_in_heating_system)) {
        heatingErrors.hotwater_included_in_heating_system =
          "Hot water included in heating system must be either 0 (no) or 1 (yes).";
      }
  
     
      */
      // Validate percentage

      if (heatingInfo.hasOwnProperty("hot_water_data")) {
        if (heatingInfo.hot_water_data.energy_source_id === null) {
          heatingErrors.hot_water_data.energy_source_id =
            "Energy source is required.";
        }

        // Validate unit_id
        if (heatingInfo.hot_water_data.unit_id === null) {
          heatingErrors.hot_water_data.unit_id = "Unit ID is required.";
        }
        if (
          !Array.isArray(heatingInfo.hot_water_data.consumption_info) ||
          heatingInfo.hot_water_data.consumption_info.length === 0
        ) {
          heatingErrors.hot_water_data.consumption_info.push(
            "Consumption info must be a non-empty array."
          );
        } else {
          heatingInfo.hot_water_data.consumption_info.forEach((info, idx) => {
            const consumptionErrors =
              heatingErrors.hot_water_data.consumption_info[idx] || {};
            // Check if the consumption value is invalid
            if (
              info.consumption === "" ||
              info.consumption === null ||
              isNaN(info.consumption) ||
              /[-eE+]/.test(info.consumption)
            ) {
              consumptionErrors.consumption = `Consumption field is invalid. It must be a non-negative number and should not contain 'e', '-', or '+'.`;
            }

            if (idx === 1 || idx === 2) {
              const baseConsumption = parseFloat(
                heatingInfo.hot_water_data.consumption_info[0].consumption
              );
              const currentConsumption = parseFloat(info.consumption);

              if (!isNaN(baseConsumption) && !isNaN(currentConsumption)) {
                const minLimit = 0.25 * baseConsumption; // 25% of value at index 0
                const maxLimit = 1.75 * baseConsumption; // 75% of value at index 0

                if (
                  currentConsumption < minLimit ||
                  currentConsumption > maxLimit
                ) {
                  consumptionErrors.consumption = `Value should not be less than 25% or greater than 175% of  ${heatingInfo.consumption_info[0].consumption} i.e the value in first consumption field.`;
                }
              }
            }

            // Add the error object to consumption_info errors array
            if (Object.keys(consumptionErrors).length > 0) {
              heatingErrors.hot_water_data.consumption_info[idx] =
                consumptionErrors;
            }
          });
        }
      } else {
        if (heatingInfo.percentage == null || heatingInfo.percentage == "") {
          heatingErrors.percentage = "Percentage must be a positive number.";
        }
      }
      // Validate consumption_info array
      if (
        !Array.isArray(heatingInfo.consumption_info) ||
        heatingInfo.consumption_info.length === 0
      ) {
        heatingErrors.consumption_info.push(
          "Consumption info must be a non-empty array."
        );
      } else {
        heatingInfo.consumption_info.forEach((info, idx) => {
          const consumptionErrors = heatingErrors.consumption_info[idx] || {};
          // Check if the consumption value is invalid
          if (
            info.consumption === "" ||
            info.consumption === null ||
            isNaN(info.consumption) ||
            /[-eE+]/.test(info.consumption)
          ) {
            consumptionErrors.consumption = `Consumption field is invalid. It must be a non-negative number and should not contain 'e', '-', or '+'.`;
          }

          if (idx === 1 || idx === 2) {
            const baseConsumption = parseFloat(
              heatingInfo.consumption_info[0].consumption
            );
            const currentConsumption = parseFloat(info.consumption);

            if (!isNaN(baseConsumption) && !isNaN(currentConsumption)) {
              const minLimit = 0.25 * baseConsumption; // 25% of value at index 0
              const maxLimit = 1.75 * baseConsumption; // 75% of value at index 0

              if (
                currentConsumption < minLimit ||
                currentConsumption > maxLimit
              ) {
                consumptionErrors.consumption = `Value should not be less than 25% or greater than 175% of  ${heatingInfo.consumption_info[0].consumption} i.e the value in first consumption field.`;
              }
            }
          }

          // Add the error object to consumption_info errors array
          if (Object.keys(consumptionErrors).length > 0) {
            heatingErrors.consumption_info[idx] = consumptionErrors;
          }
        });
      }
    });

    return errors;
  };
  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={Hot}
          heading={"Hot Water Info"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          {/* {show === 1 && (
            <div className={styles.fields}>
              <p className={styles.fieldName}>
                Percentage share of hot water
                <img src={info} />
              </p>
              <TextField
                size="small"
                style={style}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </div>
          )} */}
          {hotWater.map((item, index) => {
            let _unit = unit.length < index + 1 ? unit : unit[index];
            let suffix = _unit.findIndex((obj, i) => obj.id === item.unit_id);
            let text = _unit[suffix] ? _unit[suffix].title : "";
            let energySourceError =
              errors[index]?.hot_water_data.energy_source_id;
            let energyUnitError = errors[index]?.hot_water_data.unit_id;
            let percentageError = errors[index]?.percentage;

            return (
              <div key={index}>
                <div className={styles.fields}>
                  <p className={styles.fieldName}>
                    Is hot water included in heating system ({index + 1})
                    <img
                      src={info}
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePopUp(popData?.included)}
                    />
                  </p>
                  <div className={styles.num}>
                    <Button
                      variant={`${item.type === 1 ? "contained" : "outlined"}`}
                      onClick={() => {
                        handleClick(1, index);
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant={`${item.type === 2 ? "contained" : "outlined"}`}
                      onClick={() => {
                        handleClick(2, index);
                      }}
                    >
                      No
                    </Button>
                    <Button
                      variant={`${item.type === 3 ? "contained" : "outlined"}`}
                      onClick={() => handleClick(3, index)}
                      disabled={true}
                    >
                      Consumption is unknown
                    </Button>
                  </div>
                </div>
                {item.type === 1 && (
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>
                      Percentage share of hot water
                      <img
                        src={info}
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePopUp(popData?.stystem)}
                      />
                    </p>
                    <TextField
                      size="small"
                      style={style}
                      onChange={(e) => handleChangeIn(e, index)}
                      name="percentage"
                      value={item.percentage}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      error={percentageError}
                      helperText={percentageError}
                    />
                  </div>
                )}
                {item.type === 2 && (
                  <div>
                    <div className={styles.fieldCom}>
                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Energy Sources
                          <img
                            src={info}
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePopUp(popData?.Energy_Sources)}
                          />
                        </p>
                        <FormControl
                          fullWidth
                          error={Boolean(energySourceError)}
                        >
                          <Select
                            style={style}
                            size="small"
                            onChange={(e) => {
                              handleChange(e, index, "inner");
                              let newIndex = energy.source.findIndex(
                                (obj) => e.target.value === obj.id
                              );
                              unit[index] = energy.source[newIndex].unit;
                              setUnit((prev) => [...unit]);
                            }}
                            value={
                              item?.hot_water_data
                                ? item?.hot_water_data?.energy_source_id
                                : ""
                            }
                            name="energy_source_id"
                          >
                            {energy.source.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.title}
                              </MenuItem>
                            ))}
                          </Select>
                          {energySourceError && (
                            <FormHelperText
                              id={`energySourceError-${index}`}
                              style={{ color: `${errorMessage}` }}
                            >
                              {energySourceError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>

                      <div className={styles.fields}>
                        <p className={styles.fieldName}>
                          Unit
                          <img
                            src={info}
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePopUp(popData?.Unit)}
                          />
                        </p>
                        <FormControl
                          fullWidth
                          error={Boolean(energySourceError)}
                        >
                          <Select
                            style={style}
                            size="small"
                            onChange={(e) => handleChange(e, index, "inner")}
                            name="unit_id"
                            value={
                              item?.hot_water_data
                                ? item?.hot_water_data?.unit_id
                                : _unit.length > 0
                                ? _unit[0]
                                : ""
                            }
                          >
                            {_unit.map((option) => (
                              <MenuItem
                                key={`${index}` + option.id}
                                value={option.id}
                              >
                                {option.title}
                              </MenuItem>
                            ))}
                          </Select>
                          {energyUnitError && (
                            <FormHelperText
                              id={`energyUnitError-${index}`}
                              style={{ color: `${errorMessage}` }}
                            >
                              {energyUnitError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <p className={styles.text}>
                      If you do not know the primary energy factor of your
                      energy provider, please tell us the name of your provider.
                      You can leave notes<span> here .</span>
                    </p>
                    {item.hot_water_data.consumption_info.map(
                      (item1, index1) => {
                        const date = new Date(item1.date);

                        // Get the month (0-based index, so add 1 to get 1-based month)
                        const month = date.getMonth();

                        // Get the year
                        const year = date.getFullYear();
                        const consumptionErrorMessage =
                          errors[index]?.hot_water_data.consumption_info[
                            index1
                          ];
                        return (
                          <div className={styles.fieldCom} key={index1}>
                            <div
                              className={styles.fields}
                              style={{ width: "40%" }}
                            >
                              <p className={styles.fieldName}>
                                Date
                                <img
                                  src={info}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handlePopUp(popData?.date)}
                                />
                              </p>
                              <DateField
                                onChange={(date) =>
                                  // setForm((prev) => ({ ...prev, Date: date }))
                                  handleDate(date, index, index1)
                                }
                                startDate={item1.date}
                                value={item1.date}
                                style={{ width: "90%" }}
                                key={index1}
                                disabled={true}
                              />
                            </div>
                            <div className={styles.fields}>
                              <p className={styles.fieldName}>
                                Consumption for {monthsdd[month]} {year} to{" "}
                                {monthsdd[month - 1]} {year + 1}
                                {/* date.getMonth() + 1 */}
                                <img
                                  src={info}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handlePopUp(popData?.consumption)
                                  }
                                />
                              </p>
                              <TextField
                                size="small"
                                style={style}
                                onChange={(e) =>
                                  handleChange1(e, index, index1)
                                }
                                value={item1?.consumption}
                                // value={item1.consumption}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {text}
                                    </InputAdornment>
                                  ),
                                }}
                                error={consumptionErrorMessage?.consumption}
                                helperText={
                                  consumptionErrorMessage?.consumption
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {item.type === 3 && (
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>
                      Energy Sources
                      <img src={info} />
                    </p>
                    <Select
                      style={style}
                      size="small"
                      onChange={(e) => handleChange(e, index)}
                      name="energy_source_id"
                    >
                      {energy.source.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            );
          })}
          <div className={styles.hr} />
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
      {pop && (
        <PopUpInfo
          handleClose={() => setPop(false)}
          open={pop}
          data={infoText}
        />
      )}
    </div>
  );
}

export default Step3;
const data1 = [{ id: 1 }, { id: 2 }];

const obj = {
  energy_source_id: 1,
  unit_id: 2,
  installation_year: 2008,
  index: 3,
  consumption_info: [
    {
      date: "2020-03-10",
      consumption: 300,
    },
    {
      date: "2021-03-10",
      consumption: 400,
    },
    {
      date: "2022-03-10",
      consumption: 550,
    },
  ],
};
const monthsdd = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const popData = {
  Energy_Sources: [
    {
      heading: "Energy Source",
      para1: "Please select your main energy source here",
    },
  ],
  Unit: [
    {
      heading: "Unit",
      para1:
        "Please enter the correct consumption unit of the energy source/fuel.",
      para2: "You can usually find this on your heating bill.",
    },
  ],
  date: [
    {
      heading: "Date",
      para1: `"To apply for a consumption certificate, consumption values ​​from the last three years must be available - that is, the first billing period must be at least three years ago. Enter the month and year of the start of the first billing period here
"`,
    },
  ],
  consumption: [
    {
      heading: "Consumption",
      para1: `Please enter the total energy consumption for each period here. A billing period is always one year long.`,
      para2: `For apartment buildings, you should state the total energy consumption of the entire building, as energy certificates always refer to the entire building.`,
      para3: `If it is a combined heat and power plant (CHP), please only state the amount of heat produced (without electricity).`,
    },
  ],
  included: [
    {
      heading: "Is Hot Water Included In Heating System ",
      para1: `Please indicate here whether the hot water energy consumption is already included in the consumption stated above or not. You can also specify the hot water energy consumption separately.`,
    },
  ],
  stystem: [
    {
      heading: "Is Hot Water Included In Heating System",
      para1: `Enter the share of hot water supply here. The default value is 18%.`,
    },
  ],
};
const isAllFieldsEmpty = (errors) => {
  const checkIfEmpty = (value) => {
    if (typeof value === "string") {
      return value === ""; // Check if the string is empty
    }
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return true; // Empty array is considered empty
      }

      // Check if consumption_info array has more than 1 object
      if (value.length > 0 && typeof value[0] === "object") {
        return value.every((obj) => {
          return (
            obj.hasOwnProperty("date") &&
            obj.hasOwnProperty("consumption") &&
            obj.date === "" &&
            obj.consumption === ""
          ); // Check if each object in the array has empty date and consumption fields
        });
      }

      return false; // Non-empty array is considered not empty
    }

    if (typeof value === "object" && value !== null) {
      // Recursively check nested objects
      return Object.keys(value).every((key) => checkIfEmpty(value[key]));
    }

    return false; // Other types are considered not empty
  };

  // Iterate through each error object
  return errors.every((errorObj) => {
    return Object.keys(errorObj).every((key) => {
      const value = errorObj[key];
      return checkIfEmpty(value); // Use recursive function to check each key
    });
  });
};
