import ufrom from "../../../assets/dashboard/uform.svg";
import rectangle from "../../../assets/dashboard/rectangle.svg";
import lform from "../../../assets/dashboard/lform.svg";
import tform from "../../../assets/dashboard/tform.svg";
import ra from "../../../assets/dashboard/ra.svg";
import rb from "../../../assets/dashboard/rb.svg";
import la from "../../../assets/dashboard/la.svg";
import lb from "../../../assets/dashboard/lb.svg";
import lc from "../../../assets/dashboard/lc.svg";
import ld from "../../../assets/dashboard/ld.svg";

import ta from "../../../assets/dashboard/ta.svg";
import tb from "../../../assets/dashboard/tb.svg";
import tc from "../../../assets/dashboard/tc.svg";
import td from "../../../assets/dashboard/td.svg";

import ua from "../../../assets/dashboard/ua.svg";
import ub from "../../../assets/dashboard/ub.svg";
import uc from "../../../assets/dashboard/uc.svg";
import ud from "../../../assets/dashboard/ud.svg";

export const threeYearsBack = (sub = 1) => {
  const currentDate = new Date(); // Get the current date
  const threeYearsBack = new Date(); // Create a new Date object
  return new Date(threeYearsBack.setFullYear(currentDate.getFullYear() - sub)); // Subtract 3 years
};

export const incrementByOneYear = (sub = 1, date) => {
  const toDate = new Date(date);

  // Increment the year by 1
  toDate.setFullYear(toDate.getFullYear() + sub);
  // Convert the Date object back to a string in the format YYYY-MM-DD
  const incrementedDate = toDate.toISOString().split("T")[0];
  // Update the to_date field in the data object
  return incrementedDate;
};

export const monthYear = (date) => {
  // const myDate = date.getMonth();
  // const myYear = date.getFullYear();
  // return `${months[myDate]}  ${myYear}`;
};

// Create an array of month names
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const YYMMDD = (date) => {
  const yy = date.getFullYear().toString();
  const mm = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const dd = date.getDate().toString().padStart(2, "0");

  const formattedDate = `${yy}-${mm}-${dd}`;
  return formattedDate;
};

export const formStep1 = [
  {
    title: "Occasion",
    id: 1,
    select: false,
    name: "occasion_id",
    type: "text",
    required: true,
    fieldType: 3,
    popData: [
      {
        heading: "Occasion",
        para1: `If you need the energy certificate for financing or a loan application, please select the reason for issue as "Other". Energy certificates with the reasons for issue as "New construction" or "Modernization" cannot be ordered according to Section 80 Sentences 1 and 2 GEG.`,
      },
    ],
  },

  {
    title: "Building Type",
    id: 3,
    select: false,
    name: "building_type_id",
    type: "text",
    required: true,
    fieldType: 3,
    popData: [
      {
        heading: "Building Type",
        para1: `For mixed-use buildings, we recommend a demand certificate as this provides more accurate results.
`,
      },
    ],
  },

  {
    title: "Number of Apartments",
    id: 5,
    select: false,
    name: "number_of_apartments",
    type: "number",
    required: true,
    fieldType: 1,
    popData: [
      {
        heading: "Number of apartments",
        para1: `Since energy certificates can only be issued for the entire building and not for individual apartments, information on all apartments in the building is required. Please count granny flats as separate residential units. For single-family homes, if there are no other apartments in the building, please select 1 residential unit.`,
      },
    ],
  },
  {
    title: "Year of construction",
    id: 4,
    select: false,
    name: "construction_year",
    type: "number",
    required: false,
    fieldType: 1,
    popData: [
      {
        heading: "Year of construction of the building ",
        para1: `Please indicate the original year the property was built.`,
      },
    ],
  },
  {
    title: "No. of floors",
    id: 2,
    select: false,
    name: "number_of_floors",
    type: "number",
    required: false,
    fieldType: 1,
    popData: [
      {
        heading: "Year of construction of the building ",
        para1: `Please indicate the original year the property was built.`,
      },
    ],
  },
  {
    title: "E-mail",
    id: 6,
    select: false,
    name: "email",
    type: "email",
    required: true,
    fieldType: 1,
    popData: [
      {
        heading: "e-mail",
        para1: `Please provide a valid email address so that we can contact you if we have any questions regarding your information.`,
        para2: `You will also receive the order confirmation and invoice via the email address you provided.`,
      },
    ],
  },
];

export const steps = [
  {
    id: 1,
    step: "Step 1",
    info: "Building Data",
    required: true,
  },
  {
    id: 2,
    step: "Step 2",
    info: "Structure Details",
    required: true,
  },
  {
    id: 3,
    step: "Step 3",
    info: "Building Component",
    required: true,
  },
  {
    id: 4,
    step: "Step 4",
    info: "Heat Generator details",
    required: true,
  },
  {
    id: 5,
    step: "Step 5",
    info: "Property Address",
    required: true,
  },
  {
    id: 6,
    step: "Step 6",
    info: "File Upload",
    required: true,
  },
  {
    id: 7,
    step: "Step 7",
    info: "Certificate",
    required: true,
  },
  {
    id: 8,
    step: "Step 8",
    info: "Billing  & Delivery Address",
    required: true,
  },
];

export const structure_type = [
  {
    title: "Rectangle",
    id: 1,
    img: rectangle,
  },
  {
    title: "L-Form",
    id: 2,
    img: lform,
  },
  {
    title: "T-Form",
    id: 3,
    img: tform,
  },
  {
    title: "U-Form",
    id: 4,
    img: ufrom,
  },
  {
    title: "Custom",
    id: 5,
    img: null,
  },
];

export const structure = [
  {
    id: 1,
    wall_details: [
      {
        length: null,
        index: 1,
        img: ra,
        name: "a",
      },
      {
        length: null,
        index: 2,
        img: rb,
        name: "b",
      },
    ],
  },
  {
    id: 2,
    wall_details: [
      {
        length: null,
        index: 1,
        img: la,
      },
      {
        length: null,
        index: 2,
        img: lb,
      },
      {
        length: null,
        index: 3,
        img: lc,
      },
      {
        length: null,
        index: 4,
        img: ld,
      },
    ],
  },
  {
    id: 3,
    wall_details: [
      {
        length: null,
        index: 1,
        img: ta,
      },
      {
        length: null,
        index: 2,
        img: tb,
      },
      {
        length: null,
        index: 3,
        img: tc,
      },
      {
        length: null,
        index: 4,
        img: td,
      },
    ],
  },
  {
    id: 4,
    wall_details: [
      {
        length: null,
        index: 1,
        img: ua,
      },
      {
        length: null,
        index: 2,
        img: ub,
      },
      {
        length: null,
        index: 3,
        img: uc,
      },
      {
        length: null,
        index: 4,
        img: ud,
      },
    ],
  },
  {
    id: 5,
    wall_details: [
      {
        length: null,
        index: 1,
        img: ua,
      },
      {
        length: null,
        index: 2,
        img: ub,
      },
      {
        length: null,
        index: 3,
        img: uc,
      },
      {
        length: null,
        index: 4,
        img: ud,
      },
    ],
  },
];

export const constructionType = [
  {
    value: "solid_construction",
    id: 1,
    title: "Solid Construction",
  },
  {
    value: "wooden_construction",
    id: 2,
    title: "Wooden Construction",
  },
];

export const wing = [
  {
    value: "total_building",
    id: 1,
    title: "Total Building",
  },
  {
    value: "residential_part",
    id: 2,
    title: "Residential part of mixed building",
  },
];

export const coolingSystem = [
  {
    value: "from_electricity",
    id: 1,
    title: "From Electricity",
  },
  {
    value: "delivered_cold",
    id: 2,
    title: "Delivered cold",
  },
  {
    value: "passive_cooling",
    id: 3,
    title: "Passive cooling",
  },
  {
    value: "cooling_from_heat",
    id: 4,
    title: "Cooling from heat",
  },
];

export const outsideAir = [
  {
    value: "compliance_with_tightness",
    id: 1,
    title: "Compliance with tightness",
  },
  {
    value: "without_leak_test",
    id: 2,
    title: "Without leak test",
  },
  {
    value: "obvious_leaks",
    id: 4,
    title: "Obvious leaks",
  },
  {
    value: "other",
    id: 3,
    title: "Other",
  },
];

export const insulationFields = [
  {
    insulation_on: 1,
    length: null,
    title: "Insulation (Exterior Walls)",
  },
  {
    insulation_on: 2,
    length: null,
    title: "Insulation (Roof or Top ceiling)",
  },
  {
    insulation_on: 3,
    length: null,
    title: "Insulation (Floor / Basement)",
  },
  {
    insulation_on: 4,
    length: null,
    title: "Insulation (Basement walls)",
  },
];

export const celingConstructionType = [
  {
    id: 1,
    value: "reinforced_concrete_construction",
    title: "Reinforced concrete construction",
  },
  {
    id: 2,
    value: "wooden_construction",
    title: "Wooden construction",
  },
  {
    id: 3,
    value: "other",
    title: "Other",
  },
];

export const belowGroundFloor = [
  {
    id: 1,
    value: "unheated_basement",
    title: "Unheated basement",
  },
  {
    id: 2,
    value: "heated_cellar",
    title: "Heated cellar",
  },
  {
    id: 3,
    value: "no_basement",
    title: "No basement",
  },
];

export const heatedPercent = [
  { id: 0, value: 0, title: "0" },
  { id: 5, value: 5, title: "5" },
  { id: 10, value: 10, title: "10" },
  { id: 15, value: 15, title: "15" },
  { id: 20, value: 20, title: "20" },
  { id: 25, value: 25, title: "25" },
  { id: 30, value: 30, title: "30" },
  { id: 35, value: 35, title: "35" },
  { id: 40, value: 40, title: "40" },
  { id: 45, value: 45, title: "45" },
  { id: 50, value: 50, title: "50" },
  { id: 55, value: 55, title: "55" },
  { id: 60, value: 60, title: "60" },
  { id: 65, value: 65, title: "65" },
  { id: 70, value: 70, title: "70" },
  { id: 75, value: 75, title: "75" },
  { id: 80, value: 80, title: "80" },
  { id: 85, value: 85, title: "85" },
  { id: 90, value: 90, title: "90" },
  { id: 95, value: 95, title: "95" },
  { id: 100, value: 100, title: "100" },
];

export const distributionHeating = [
  {
    id: 1,
    title: "Central distribution",
  },
  {
    id: 2,
    title: "Apartment-wise distribution",
  },
  {
    id: 3,
    title: "Decentralized Distribution",
  },
];

export const typesOfRenewableEnergy = [
  {
    id: 1,
    title: "Unheated Basement",
  },
  {
    id: 2,
    title: "Photovoltaics",
  },
  {
    id: 3,
    title: "Heat Pump",
  },
  {
    id: 4,
    title: "Biomass",
  },
];

export const directions = [
  {
    id: 1,
    title: "North",
  },
  {
    id: 2,
    title: "North East",
  },
  {
    id: 3,
    title: "East",
  },
  {
    id: 4,
    title: "South East",
  },
  {
    id: 5,
    title: "South",
  },
  {
    id: 6,
    title: "South West",
  },
  {
    id: 7,
    title: "West",
  },
  {
    id: 8,
    title: "North West",
  },
];

const windowDetails = {
  window_type_id: null,
  length: null,
  width: null,
};

export const atticTypes = [
  {
    id: 1,
    title: "Solid Ceiling",
  },
  {
    id: 2,
    title: "Wodden Ceiling",
  },
];

export const degreeArr = [
  { id: 0, title: 0 },
  { id: 5, title: 5 },
  { id: 10, title: 10 },
  { id: 15, title: 15 },
  { id: 20, title: 20 },
  { id: 25, title: 25 },
  { id: 30, title: 30 },
  { id: 35, title: 35 },
  { id: 40, title: 40 },
  { id: 45, title: 45 },
  { id: 50, title: 50 },
  { id: 55, title: 55 },
  { id: 60, title: 60 },
  { id: 65, title: 65 },
  { id: 70, title: 70 },
  { id: 75, title: 75 },
  { id: 80, title: 80 },
  { id: 85, title: 85 },
  { id: 90, title: 90 },
];

export const booleanValue = [
  {
    id: 1,
    title: "Heated Attic",
  },
  {
    id: 2,
    title: "Unheated Attic",
  },
];
