import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import useInput from "../hooks/useInput";
import styles from "./Onboarding.module.css";
import { emailValidation } from "../Utility/helper";
import { handleApiCall, stepsArray, validatePassword } from "./utility";
import { initialState1, reducer } from "./reducers/formReducer";
import { AppContext } from "../ContextApi/AppContext/AppContext";
import { useNavigate } from "react-router-dom";

function Onboarding(props) {
  const navigate = useNavigate();
  const [formState, dispatch] = useReducer(reducer, initialState1);
  const { setState } = useContext(AppContext);
  const { data } = formState;
  const [step, setStep] = useState(1);
  const email = useInput("", emailValidation); // useInput hook to handle input changes
  const name = useInput("", null);
  const otp = useInput("", null);
  const password = useInput("", null);
  const new_password = useInput("", validatePassword);
  const confirm_password = useInput("", null);
  const fieldsData = {
    email,
    password,
    otp,
    new_password,
    confirm_password,
    name,
  }; // grouping of all fields in one object to access it throughout the pages
  useEffect(() => {
    if (data && data.data.access) {
      const { access, refresh, ...rest } = data.data;
      setState((prev) => ({
        ...prev,
        token: { access: access, refresh: refresh },
        user: { ...rest },
      }));
      navigate("/");
    }
  }, [data]);

  const handleClick = (_id, _type) => {
    switch (step) {
      case 1: // Login Step
        if (_type === "apicall") {
          loginCall();
        } else {
          setStep(2);
        }
        return; // Exit early after handling step 1

      case 2: // Send OTP Step
        if (_type === "apicall") {
          sendOTP();
        } else {
          setStep(1);
        }
        return;

      case 3: // Validate OTP Step
        if (_type === "apicall") {
          validateOTP();
        } else {
          setStep(1);
        }
        return;

      case 4: // Reset Password Step
        if (_type === "apicall") {
          resetPassword();
        } else {
          setStep(1);
        }
        return;

      case 5: // Assuming a success/completion step
        setStep(1);
        return;

      default:
        console.error("Invalid step:", step);
    }
  };

  const CurrentComponent = useMemo(() => {
    // to render component based on current step
    dispatch({ type: "SET_ERROR", payload: "" });
    const component = stepsArray.find((item, index) => {
      return item.id === step;
    }).component;
    return component;
  }, [step]);

  const loginCall = async () => {
    // user login
    const postData = {
      username: name.value,
      password: password.value,
    };
    handleApiCall("login", postData, "SET_LOGGED_IN", dispatch);
  };

  const sendOTP = async () => {
    // generate otp
    const postData = {
      username: email.value,
    };
    handleApiCall(
      "sendOTP",
      postData,
      () => {
        setStep(3);
      },
      dispatch
    );
  };

  const validateOTP = async () => {
    // validating otp
    dispatch({ type: "SET_LOADING" });
    const postData = {
      userName: email.value,
      otp: otp.value,
    };
    handleApiCall(
      "validateOTP",
      postData,
      () => {
        setStep(4);
      },
      dispatch
    );
  };

  const resetPassword = async () => {
    // for resetting password
    dispatch({ type: "SET_LOADING" });
    const postData = {
      userName: email.value,
      password: confirm_password.value,
    };
    handleApiCall(
      "resetPassword",
      postData,
      () => {
        setStep(5);
      },
      dispatch,
      formState.validateToken
    );
  };

  return (
    <div>
      {!formState.loggedin && (
        <div className={styles.pageContainer}>
          <div className={styles.formContainer}>
            {stepsArray.map((item, index) => {
              if (step === item.id) {
                return (
                  <CurrentComponent
                    formState={formState}
                    {...fieldsData[item.name]}
                    key={index}
                    id={item.id}
                    handleClick={handleClick}
                    {...(item.id !== 5
                      ? { fields: item.fields, fieldsData }
                      : null)}
                  />
                );
              } else return null;
            })}
          </div>
          <div className={styles.imageContainer}></div>
        </div>
      )}
    </div>
  );
}

export default Onboarding;
