import React, { useContext, useEffect, useRef, useState } from "react";
import Step1 from "../../Need/ConsumptionForm/Step1.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { steps } from "../../../Utility/utils.js";
import Step2 from "../../Need/ConsumptionForm/Step2.jsx";
import Step3 from "../../Need/ConsumptionForm/Step3.jsx";
import Step4 from "../../Need/ConsumptionForm/Step4.jsx";
import Step5 from "../../Need/ConsumptionForm/Step5.jsx";
import Step6 from "../../Need/ConsumptionForm/Step6.jsx";
import { AppContext } from "../../../ContextApi/AppContext/AppContext.jsx";
import Step7 from "../../Need/ConsumptionForm/Step7.jsx";
import Step8 from "../../Need/ConsumptionForm/Step8.jsx";
import styles from "./EnergyCertificate.module.css";
import OuterHeader from "../../../Components/OuterHeader/OuterHeader.jsx";
import HeaderNew from "../../Need/HeaderNew/HeaderNew.jsx";
import Footer from "../../../Components/Footer/Footer.jsx";
function NeedCertificate() {
  const scrollRef = useRef(null);
  const { state, setState } = useContext(AppContext);
  const { needPage: currentPage } = state;
  const { page, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the top of the element
    });
    const pageNumber = Number(page);
    if (isNaN(pageNumber) || pageNumber < 1 || pageNumber > steps.length) {
      navigateToPage(1);
    } else {
      // setCurrentPage(pageNumber);
    }
  }, [page]);

  const navigateToPage = (pageNumber) => {
    // setCurrentPage(pageNumber);
    // navigate(`/form`);
  };

  const handleNavigation = (direction) => {
    navigateToPage(currentPage + direction);
  };

  return (
    <div ref={scrollRef}>
      <div className={styles.con}>
        <HeaderNew />
        {currentPage === 1 && <Step1 steps={steps} />}
        {currentPage === 2 && <Step2 />}
        {currentPage === 3 && <Step3 />}
        {currentPage === 4 && <Step4 />}
        {currentPage === 5 && <Step5 />}
        {currentPage === 6 && <Step6 />}
        {/* {currentPage === 7 && <Address />} */}
        {currentPage === 7 && <Step7 />}
        {currentPage === 8 && <Step8 />}
      </div>
      <Footer />
    </div>
  );
}

export default NeedCertificate;
