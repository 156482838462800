import { InputAdornment, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import info from "../../assets/componentAssets/info.svg";
import PopUpInfo from "../PopUpInfo/PopUpInfo";
import styles from "./CustomInput.module.css";
function CustomInput({
  value = "",
  label = "",
  style = { width: "100%" },
  onChange = () => {},
  options = [],
  endIcon,
  required = true,
  error = false,
  helperText = "",
  popData,
  showInfo = true,
  fieldType,
  ...props
}) {
  const [pop, setPop] = useState(false);
  const handlePopUp = () => {
    setPop(true);
  };
  return (
    <>
      <p className={styles.label}>
        {label}
        {showInfo && required && (
          <img src={info} onClick={handlePopUp} style={{ cursor: "pointer" }} />
        )}
      </p>
      <TextField
        size="small"
        style={style}
        select={fieldType === 3 ? true : false}
        value={value === "" ? (fieldType == 3 ? 0 : "") : value}
        onChange={(e) => onChange(e.target.name, e.target.value, props)}
        required={required}
        name={props.name}
        error={error}
        helperText={helperText}
        type={props.type}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }}
      >
        {fieldType === 3 &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
      </TextField>
      {pop && (
        <PopUpInfo
          handleClose={() => setPop(false)}
          open={pop}
          data={popData}
        />
      )}
    </>
  );
}

export default CustomInput;
