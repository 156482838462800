export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return "Invalid email address";
  }
  return "";
};

export const validatePostalCode = (postalCode) => {
  const postalCodeRegex = /^[0-9]{5}$/;
  if (!postalCodeRegex.test(postalCode)) {
    return "Invalid postal code";
  }
  return "";
};

export function validateGermanVAT(vatId) {
  // Remove any non-alphanumeric characters
  vatId = vatId.replace(/[^a-zA-Z0-9]/g, "");

  // Check if VAT ID starts with 'DE' and has exactly 9 digits after 'DE'
  const vatRegex = /^DE[0-9]{9}$/;

  if (!vatRegex.test(vatId)) {
    return false;
  }

  // Extract the numeric part of the VAT ID
  const vatDigits = vatId.substring(2);

  // Perform checksum validation (simple sum, no official algorithm)
  const validChecksum = validateChecksum(vatDigits);

  return validChecksum ? "not a valid vat id" : "";
}

function validateChecksum(vatDigits) {
  // Checksum logic (simplified version for example)
  // Sum the digits and check if it's divisible by 9 as a basic rule
  const sum = vatDigits
    .split("")
    .reduce((acc, digit) => acc + parseInt(digit), 0);

  return sum % 9 === 0;
}

export function validateGermanPhoneNumber(phoneNumber) {
  // // Remove spaces, dashes, and other non-numeric characters except '+'
  // phoneNumber = phoneNumber.replace(/[^0-9+]/g, "");

  // // Regular expression for German phone numbers
  // const phoneRegex = /^(?:\+49|0049|0)(\d{2,5})(\d{3,9})$/;

  // // Test the phone number against the regex
  // const isValid = phoneRegex.test(phoneNumber);
  const isValid = phoneNumber.length > 7 ? "" : "not a valid phone number";
  return isValid;
}

export const validateConstruction = (date) => {
  // Convert to number (to handle cases where 'date' might be a string)
  const year = Number(date);

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Check if the date is a valid year and falls between 1850 and the current year
  if (isNaN(year)) {
    return "Invalid year, please enter a numeric value.";
  }

  if (year < 1850 || year > currentYear) {
    return `Year must be between 1850 and ${currentYear}.`;
  }

  return ""; // Validation passed
};
