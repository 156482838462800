import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { steps } from "../../../Utility/utils";
import styles from "./Step.module.css";
import GenerateCertifiacte from "../../../Components/GenerateCertifiacte/GenerateCertifiacte";

function NavButton({
  handleSubmit,
  disabled = false,
  show = true,
  showPrint = false,
  showPop = false,
  ...props
}) {
  const { state } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const showButton = () => {
    handleSubmit(null, "submit-save");
  };
  useEffect(() => {
    if (showPop) {
      setShowForm((prev) => !prev);
    }
  }, [showPop]);
  return (
    <>
      <div className={styles.desk}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
          className={styles.buttonClass}
        >
          <Button
            variant="outlined"
            disabled={state.page <= 1}
            onClick={() => handleSubmit(state.page - 1, "prev")}
            sx={{
              padding: "12px 25px",
              borderRadius: "10px",
              background: "white",
              visibility: `${state.page <= 1 ? "hidden" : "visible"}`,
              // boxShadow: "none",
              // color: "white",
              // "&:disabled": {
              //   background: "gray",
              //   color: "#ccc",
              // },
            }}
          >
            <b> Previous</b>
          </Button>
          <div>
            {showPrint ? (
              <Button
                variant="contained"
                disabled={state.page <= 1}
                onClick={() => showButton(null, "save")}
                sx={{
                  padding: "12px 25px",
                  background:
                    " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                  boxShadow: "none",
                  borderRadius: "10px",
                  color: "white",
                  "&:disabled": {
                    background: "gray",
                    color: "#ccc",
                  },
                }}
              >
                <b>Save Changes</b>
              </Button>
            ) : (
              <div>
                {/* <Button
                  variant="outlined"
                  disabled={state.page <= 1}
                  onClick={() => handleSubmit(null, "save")}
                  sx={{
                    padding: "12px 25px",
                    borderRadius: "10px",
                    background: "white",
                    // boxShadow: "none",
                    // color: "white",
                    // "&:disabled": {
                    //   background: "gray",
                    //   color: "#ccc",
                    // },
                    marginRight: "10px",
                  }}
                >
                  <b>Save & Resume Later</b>
                </Button> */}
              </div>
            )}
            {show && (
              <Button
                variant="contained"
                disabled={disabled || state.page === steps.length}
                onClick={() => handleSubmit(state.page + 1, "next")}
                sx={{
                  padding: "12px 25px",
                  background:
                    " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                  boxShadow: "none",
                  borderRadius: "10px",
                  color: "white",
                  "&:disabled": {
                    background: "gray",
                    color: "#ccc",
                  },
                }}
              >
                <b>Next</b>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
          className={styles.buttonClass}
        >
          <Button
            variant="outlined"
            disabled={state.page <= 1}
            onClick={() => handleSubmit(state.page - 1, "prev")}
            sx={{
              padding: "12px 25px",
              borderRadius: "10px",
              background: "white",
              // boxShadow: "none",
              // color: "white",
              // "&:disabled": {
              //   background: "gray",
              //   color: "#ccc",
              // },
            }}
          >
            <b> Previous</b>
          </Button>
          <div>
            {show && (
              <Button
                variant="contained"
                disabled={disabled || state.page === steps.length}
                onClick={() => handleSubmit(state.page + 1, "next")}
                sx={{
                  padding: "12px 25px",
                  background:
                    " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                  boxShadow: "none",
                  borderRadius: "10px",
                  color: "white",
                  "&:disabled": {
                    background: "gray",
                    color: "#ccc",
                  },
                }}
              >
                <b>Next</b>
              </Button>
            )}
          </div>
        </div>

        <div className={styles.save}>
          {showPrint ? (
            <Button
              variant="contained"
              disabled={state.page <= 1}
              onClick={() => showButton(null, "save")}
              sx={{
                padding: "12px 25px",
                background: " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                boxShadow: "none",
                borderRadius: "10px",
                color: "white",
                "&:disabled": {
                  background: "gray",
                  color: "#ccc",
                },
              }}
            >
              <b>Save Changes</b>
            </Button>
          ) : (
            <Button
              variant="outlined"
              disabled={state.page <= 1}
              onClick={() => handleSubmit(null, "save")}
              sx={{
                padding: "12px 25px",
                borderRadius: "10px",
                background: "white",
                // boxShadow: "none",
                // color: "white",
                // "&:disabled": {
                //   background: "gray",
                //   color: "#ccc",
                // },
                marginRight: "10px",
              }}
            >
              <b>Save & Resume Later</b>
            </Button>
          )}
        </div>
        <GenerateCertifiacte show={showForm} setShow={setShowForm} />
      </div>
    </>
  );
}

export default NavButton;
