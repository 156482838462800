import React, { useEffect, useState } from "react";

import warn from "../../../../assets/componentAssets/warningpayment.png";
import StepsHeader from "../../../../Components/StepsHeader/StepsHeader";
import Billing from "../../../../Components/Billing/Billing";
import failed from "../../../../assets/componentAssets/failed.png";
import styles from "./Payment.module.css";
import { useParams } from "react-router-dom";
import { PostApi, getApi } from "../../../../Utility/network";
import { Button } from "@mui/material";
function Failure() {
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    getData(id);
  }, [id]);
  const getData = async (localData) => {
    try {
      const payData = await getApi(`/payment/checkout/${localData}`);
      setData(payData.data.data);
    } catch (error) {}
  };

  const retry = async () => {
    let _id = null;
    if (data.type === 1) {
      _id = data.energy_consumption.id;
      const payData = await PostApi(`/payment/checkout/`, {
        energy_consumption_id: _id,
      });
      window.open(payData.data.data.gateway_url, "_blank").focus();
    } else {
      _id = data.energy_need.id;
      const payData = await PostApi(`/payment/checkout/`, {
        energy_need_id: _id,
      });
      window.open(payData.data.data.gateway_url, "_blank").focus();
    }
  };

  console.log(data, "data");
  return (
    <div className={styles.con}>
      <StepsHeader image={warn} heading={"Payment Failed – Action Required"} />
      <Billing
        status={"failed"}
        img={failed}
        statusText={"Your payment was not successfully processed."}
        desc={`We have received your order but were unable to process the payment.
Once the payment is successfully processed, we will begin processing your order and 
send the final certificate to the email address you provided.`}
      />
      <div style={{ textAlign: "center" }}>
        <Button variant="outlined" onClick={() => retry()}>
          Retry Payment
        </Button>
      </div>
    </div>
  );
}

export default Failure;
