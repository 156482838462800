import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Drawer, DrawerHeader, drawerList } from "./utility";
import drawerIcon from "../../assets/dashboard/heritageLogo.png";
import styles from "./DrawerComponent.module.css";
import { PRIMARYCOLOR } from "../../Utility/constants";
import { NavLink, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CustomSnackBar from "../CustomSnackBar/CustomSnackBar";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import Breadcrumbs from "../BreadCrumbs/BreadCrumbs";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function MiniDrawer() {
  let location = useLocation();
  const { showSnackbar, state, handleCloseSnackbar } = useContext(AppContext);
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState(1);
  // useEffect(() => {
  //   console.log(token)
  //   if (token.access === "") handleLogout();
  // }, [token]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    const currentObject = drawerList.find((item, index) => {
      let str = location.pathname.replace(/^\/+/, "");
      return item.path === str;
    });
    if (currentObject) {
      setActive(currentObject.id);
    }
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          <div className={styles.headerContainer}>
            <div></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AccountCircleIcon
                fontSize="large"
                style={{ marginRight: "10px" }}
              />
              <p style={{ textTransform: "capitalize", margin: "0px" }}>
                {state?.user?.firstName}
              </p>
            </div>
          </div>
        </Typography>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <div style={{ color: "" }}>
          <div className={styles.imagelogoC}>
            <img
              alt="sama logo"
              src={drawerIcon}
              className={styles.imageLogo}
            />
          </div>
          <div className={styles.listingCon}>
            <div>
              {drawerList.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    className={({ isActive }) =>
                      [
                        styles.listingInd,
                        isActive ? styles.activeDiv : styles.inActiveDiv,
                      ].join(" ")
                    }
                    style={({ isActive }) => {
                      return {
                        color: `${isActive ? PRIMARYCOLOR : ""}`,
                        borderLeft: `${
                          isActive
                            ? `5px solid ${PRIMARYCOLOR}`
                            : "5px solid white"
                        }`,
                      };
                    }}
                    to={item.path}
                  >
                    <div className={styles.imageCon}>
                      {active === item.id ? (
                        <img
                          alt=""
                          src={item.active_icon}
                          style={{ width: "30px" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={item.inactive_icon}
                          style={{ width: "30px" }}
                        />
                      )}
                    </div>
                    <div>
                      <span>
                        <b>{item.title}</b>
                      </span>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </div>
        </div>
      </Drawer>
      <div style={{ width: "100%" }}>
        <div className={styles.outletContainer}>
          <Breadcrumbs />
          <Outlet />
        </div>
      </div>
      <CustomSnackBar open={state.snackbar} handleClose={handleCloseSnackbar} />
    </Box>
  );
}
