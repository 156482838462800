import React, { useContext, useEffect, useState } from "react";
import styles from "./Step.module.css";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import Vacancy from "../../../assets/componentAssets/Vacancy.png";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import PopUpInfo from "../../../Components/PopUpInfo/PopUpInfo";
import { PatchApi, getApi } from "../../../Utility/network";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import { distributionHeating, typesOfRenewableEnergy } from "../utility/utils";
import CustomCheckBox from "../../../Components/CustomCheckBox/CustomCheckBox";
import { ordinalSuffix } from "../../../Utility/utils";
import generatorIcon from '../../../assets/componentAssets/generatorIcon.svg';

function Step4() {
  const { state, setState } = useContext(AppContext);
  const [submitData, setSubmitData] = useState({
    are_renewable_energy_used: false,
    type_of_renewable_energy_id: [],
    unobstructed_collector_area: 1,
    do_u_have_solar_collector: false,
  });
  const [drop, setDrop] = useState({
    heating: [],
    water: [],
    energy: [],
    distributed: [],
  });
  const [data, setData] = useState({
    heating_systems: [],
    hot_water: [],
  });
  const [show, setShow] = useState(0);
  const [show1, setShow1] = useState(0);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setSubmitData((prev) => ({
      ...prev,
      [name]:
        name === "are_renewable_energy_used"
          ? value == "true"
            ? true
            : false
          : value,
    }));
  };

  useEffect(() => {
    const {
      heating_systems,
      are_renewable_energy_used,
      do_u_have_solar_collector,
      hot_water_systems,
      unobstructed_collector_area,
    } = state.needData;
    if (hot_water_systems.length > 0 && heating_systems.length > 0) {
      setShow(heating_systems.length);
      setShow1(hot_water_systems.length);
      setData({ heating_systems, hot_water: hot_water_systems });
      setSubmitData({
        ...submitData,
        are_renewable_energy_used,
        do_u_have_solar_collector,
        unobstructed_collector_area,
      });
    }
  }, [state.needData.hot_water_systems, state.needData.heating_systems]);

  const handleSubmit = async (num, type) => {
    try {
      if (type === "next") {
        const {
          are_renewable_energy_used,
          do_u_have_solar_collector,
          unobstructed_collector_area,
        } = submitData;
        const { heating_systems, hot_water } = data;
        const _data = {
          heating_systems: heating_systems,
          hot_water_systems: hot_water,
          are_renewable_energy_used: are_renewable_energy_used,
          do_u_have_solar_collector: do_u_have_solar_collector,
          unobstructed_collector_area: unobstructed_collector_area,
        };
        try {
          const postData = await PatchApi(`/energy_need/${state.needId}/`, {
            ..._data,
          });
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              needPage: prev.needPage + 1,
              needData: {
                ...prev.needData,
                heating_systems,
                hot_water_systems: hot_water,
                are_renewable_energy_used,
                do_u_have_solar_collector,
                unobstructed_collector_area,
              },
              needFilledPage:
                prev.needFilledPage >= prev.needPage + 1
                  ? prev.needFilledPage
                  : prev.needPage + 1,
            }));
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, needPage: prev.needPage - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_need/${state.needId}/generate_link/`);
        // navigate("/login");
        alert("link sent to your email");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const heating = await getApi(`/energy_need/heating_systems/`);
      const water = await getApi(`/energy_need/hot_water_types/`);

      const energy = await getApi(`/energy_consumption/energy_source`);
      setDrop({
        heating: heating.data.data,
        water: water.data.data,
        energy: energy.data.data,
        distributed: distributionHeating,
      });
    } catch (error) {}
  };

  const handleClick = (_count) => {
    const dataObject = heat; // Object structure for each form
    const newData = [...data.heating_systems]; // Copy the current data array

    // If the array length is less than the clicked number, add more objects
    if (newData.length < _count) {
      const objectsToAdd = _count - newData.length;

      for (let i = 0; i < objectsToAdd; i++) {
        newData.push({
          ...dataObject,
          index: i + 1,
        });
      }

      // If the array length is more than the clicked number, remove extra objects
    } else if (newData.length > _count) {
      newData.splice(_count); // Trim the array to the clicked length
    }

    // Update the state only if there is a change
    if (newData.length !== data.length) {
      setData((prev) => ({
        ...prev,
        heating_systems: newData,
        number_of_heating_systems: _count,
      }));
      setState((prev) => ({
        ...prev,
        needData: { ...prev.needData, heatingSystemsCount: newData },
      }));
    }
  };
  const handleCheckboxChange = (name, checkedIds) => {
    if (name === "type_of_renewable_energy_id") {
      setSubmitData((prev) => ({
        ...prev,
        type_of_renewable_energy_id: checkedIds,
      }));
    }
  };

  const handleClick1 = (_count) => {
    const dataObject = heat; // Object structure for each form
    const newData = [...data.hot_water]; // Copy the current data array

    // If the array length is less than the clicked number, add more objects
    if (newData.length < _count) {
      const objectsToAdd = _count - newData.length;

      for (let i = 0; i < objectsToAdd; i++) {
        newData.push({
          ...dataObject,
          index: i + 1,
        });
      }

      // If the array length is more than the clicked number, remove extra objects
    } else if (newData.length > _count) {
      newData.splice(_count); // Trim the array to the clicked length
    }

    // Update the state only if there is a change
    if (newData.length !== data.length) {
      setData((prev) => ({
        ...prev,
        hot_water: newData,
        number_of_heating_systems: _count,
      }));
      setState((prev) => ({
        ...prev,
        needData: { ...prev.needData, hotSystemsCount: newData },
      }));
    }
  };
  const handleChange1 = (name, value, item) => {
    setSubmitData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChangeButton = (e, index, field) => {
    // setData(prev=>({...prev,heating_info:}))
    const { value, name } = e.target; // Get the input value
    setData((prevData) => {
      // Make a copy of the previous state
      const newData = [...prevData[field]];
      // Update the specific field of the object at the given index
      newData[index] = { ...newData[index], [name]: value };
      return { ...prevData, [field]: newData }; // Return the updated array
    });
  };
  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={generatorIcon}
          heading={"Heat Generator Details"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          <p className={styles.fieldName}>
            How many heating system are installed?
          </p>
          <div className={styles.num}>
            <Button
              variant={show === 1 ? "contained" : "outlined"}
              onClick={() => {
                setShow(1);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    heatingSystemsCount: 1,
                  },
                }));
                // setButtonError("");
                handleClick(1);
              }}
            >
              <b>1</b>
            </Button>
            <Button
              variant={show === 2 ? "contained" : "outlined"}
              onClick={() => {
                setShow(2);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    heatingSystemsCount: 2,
                  },
                }));
                // setButtonError("");
                handleClick(2);
              }}
            >
              <b>2</b>
            </Button>
            <Button
              variant={show === 3 ? "contained" : "outlined"}
              onClick={() => {
                setShow(3);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    heatingSystemsCount: 3,
                  },
                }));
                // setButtonError("");
                handleClick(3);
              }}
            >
              <b>3</b>
            </Button>
            <Button
              variant={show === 4 ? "contained" : "outlined"}
              onClick={() => {
                setShow(4);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    heatingSystemsCount: 4,
                  },
                }));
                // setButtonError("");
                handleClick(4);
              }}
            >
              <b>4</b>
            </Button>
          </div>
          <div>
            {data.heating_systems.map((item, index) => {
              const suffixNumber = ordinalSuffix(index + 1);
              return (
                <div className={styles.fieldCom}>
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Energy Source</p>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        fieldType={3}
                        name="energy_source_id"
                        value={item.energy_source_id}
                        onChange={(e) =>
                          handleChangeButton(e, index, "heating_systems")
                        }
                      >
                        {drop.energy.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Heating System</p>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={item.heating_system_id}
                        name="heating_system_id"
                        onChange={(e) =>
                          handleChangeButton(e, index, "heating_systems")
                        }
                      >
                        {drop.heating.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>{" "}
                  <div className={styles.fields}>
                    <p
                      className={styles.fieldName}
                      style={{ marginTop: "11px" }}
                    >
                      year of {suffixNumber} heating system was installed
                    </p>
                    <TextField
                      size="small"
                      style={{ width: "100%" }}
                      value={item.installation_year}
                      name="installation_year"
                      onChange={(e) =>
                        handleChangeButton(e, index, "heating_systems")
                      }
                    />
                  </div>{" "}
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Distribution Heating</p>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={item.distribution_heating}
                        name="distribution_heating"
                        onChange={(e) =>
                          handleChangeButton(e, index, "heating_systems")
                        }
                      >
                        {drop.distributed.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>{" "}
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Share of heating</p>
                    <TextField
                      size="small"
                      style={{ width: "100%" }}
                      value={item.share_of_heating}
                      name="share_of_heating"
                      label=""
                      onChange={(e) =>
                        handleChangeButton(e, index, "heating_systems")
                      }
                    />{" "}
                  </div>
                  {/* <div className={styles.fields}>
                    <p className={styles.fieldName}>Water Heating</p>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={item.water_heating_id}
                        name="water_heating_id"
                        onChange={(e) =>
                          handleChangeButton(e, index, "heating_systems")
                        }
                      >
                        {drop.water.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>{" "} */}
                  {/* <div className={styles.fields}>
                    <p className={styles.fieldName}>
                      Source of energy for heating water
                    </p>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={item.water_heating_energy_source_id}
                        name="water_heating_energy_source_id"
                        onChange={(e) =>
                          handleChangeButton(e, index, "heating_systems")
                        }
                      >
                        {drop.energy.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}
                  <div className={styles.hr}></div>
                </div>
              );
            })}
          </div>

          <p className={styles.fieldName}>
            How many hot water system are installed?
          </p>
          <div className={styles.num}>
            <Button
              variant={show1 === 1 ? "contained" : "outlined"}
              onClick={() => {
                setShow1(1);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    hotSystemsCount: 1,
                  },
                }));
                // setButtonError("");
                handleClick1(1);
              }}
            >
              <b>1</b>
            </Button>
            <Button
              variant={show1 === 2 ? "contained" : "outlined"}
              onClick={() => {
                setShow1(2);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    hotSystemsCount: 2,
                  },
                }));
                // setButtonError("");
                handleClick1(2);
              }}
            >
              <b>2</b>
            </Button>
            <Button
              variant={show1 === 3 ? "contained" : "outlined"}
              onClick={() => {
                setShow1(3);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    hotSystemsCount: 3,
                  },
                }));
                // setButtonError("");
                handleClick1(3);
              }}
            >
              <b>3</b>
            </Button>
            <Button
              variant={show1 === 4 ? "contained" : "outlined"}
              onClick={() => {
                setShow1(4);
                setState((prev) => ({
                  ...prev,
                  otherData: {
                    ...prev.needData,
                    hotSystemsCount: 4,
                  },
                }));
                // setButtonError("");
                handleClick1(4);
              }}
            >
              <b>4</b>
            </Button>
          </div>
          <div>
            {data.hot_water.map((item, index) => {
              const suffixNumber = ordinalSuffix(index + 1);
              return (
                <div className={styles.fieldCom}>
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Water Heating</p>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={item.water_heating_id}
                        name="water_heating_id"
                        onChange={(e) =>
                          handleChangeButton(e, index, "hot_water")
                        }
                      >
                        {drop.water.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>{" "}
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Energy Source</p>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={item.energy_source_id}
                        name="energy_source_id"
                        onChange={(e) =>
                          handleChangeButton(e, index, "hot_water")
                        }
                      >
                        {drop.energy.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>
                      Year of {suffixNumber} hot water system was installed
                    </p>
                    <TextField
                      size="small"
                      style={{ width: "100%" }}
                      value={item.installation_year}
                      name="installation_year"
                      label=""
                      onChange={(e) =>
                        handleChangeButton(e, index, "hot_water")
                      }
                    />{" "}
                  </div>{" "}
                  <div className={styles.fields}>
                    <p className={styles.fieldName}>Share of heating</p>
                    <TextField
                      size="small"
                      style={{ width: "100%" }}
                      value={item.share_of_heating}
                      name="share_of_heating"
                      label=""
                      onChange={(e) =>
                        handleChangeButton(e, index, "hot_water")
                      }
                    />{" "}
                  </div>
                  <div className={styles.hr}></div>
                </div>
              );
            })}
          </div>

          <div className={styles.fieldDetailsCon}>
            <div className={styles.fields}>
              <p className={styles.fieldName}>Are renewable energy used?</p>
              <RadioGroup
                name="are_renewable_energy_used" // Name of the radio group
                value={submitData.are_renewable_energy_used} // Bind the state value
                onChange={handleChange} // Handle value changes
              >
                <div className={styles.Radio}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="no"
                  />
                </div>
              </RadioGroup>
              {submitData.are_renewable_energy_used && (
                <div>
                  <CustomCheckBox
                    data={typesOfRenewableEnergy}
                    name="type_of_renewable_energy_id"
                    onCheckChange={handleCheckboxChange}
                    selectedValues={submitData.type_of_renewable_energy_id}
                  />
                </div>
              )}
            </div>
          </div>

          {/* <div className={styles.fields}>
            <CustomInput
              label="Unobstructed collector area (solar thermal energy)"
              endIcon="m&#178;"
              name="unobstructed_collector_area"
              value={submitData.unobstructed_collector_area}
              onChange={handleChange1}
            />
          </div> */}
          {/* <div
            style={{
              background: "#AFDB2840",
              marginTop: "15px",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <p className={styles.fieldName}>
              Do the solar collectors or systems possess the European quality
              certification 'Solar Keymark'?
            </p>

            <RadioGroup
              name="do_u_have_solar_collector" // Name of the radio group
              value={submitData.do_u_have_solar_collector} // Bind the state value
              onChange={handleChange} // Handle value changes
            >
              <div className={styles.Radio}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="no"
                />
              </div>
            </RadioGroup>
          </div> */}
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
    </div>
  );
}

export default Step4;

const heat = {
  energy_source_id: null,
  heating_system_id: null,
  installation_year: null,
  index: 1,
  distribution_heating: null,
  share_of_heating: 100,
};

const hot_water = {
  energy_source_id: null,
  water_heating_id: null,
  installation_year: null,
  share_of_heating: null,
  index: 1,
  share_of_heating: 100,
};
