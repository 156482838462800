import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function CustomSnackBar({ open, handleClose }) {
  return (
    <>
      <Snackbar
        open={open?.open}
        autoHideDuration={open?.duration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={open.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {open?.text}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CustomSnackBar;
