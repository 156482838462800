export const englisText = {
  immediately_available: "Immediately Available",
  legally_valid: "Legally valid for 10 years",
  energy_certificate: "Energy certificate according to GEG",
  consumption_certificate: "Consumption Certificate",
  need_certificate: "Need Certificate",
  save_resume_later: "Save & Resume Later",
  next: `Next`,
  previous: "Previous",
  google_review: `Google review`,
  our_payment_methods: `Our Payment Methods`,
  energy_certificates: `Energy Certificates`,
  create_consumption_certificate: `create a consumption certificate`,
  create_needs_certificate: `create a needs certificate`,
};

export const consumCertEngText = {
  step_1: "Step 1",
  step_2: "Step 2",
  step_3: "Step 3",
  step_4: "Step 4",
  step_5: "Step 5",
  step_6: "Step 6",
  step_7: "Step 7",
  step_8: "Step 8",
  building_info: "Building Info",
  heating_info: "Heating Info",
  heat_water_info: "Heat Water Info",
  vacancy_info: "Vacancy Info",
  property_info: "Property Info",
  property_address: "Property Address",
  certificate: "Certificate",
  billing_delivery_address: "Billing & Delivery Address",
  building_data: "Building Data",
  provided_information: "Provided information",
  close: "Close",
  occasion: "Occasion",
  occasion_error: `Occasion is required`,
  occasion_paragraph: `If you need the energy certificate for financing or a loan application, please select the reason for issue as "Other". Energy certificates with the reasons for issue as "New construction" or "Modernization" cannot be ordered according to Section 80 Sentences 1 and 2 GEG.`,
  postal_code: "Postal Code",
  postal_code_error: `Invalid postal code`,
  building_type: `Building Type`,
  building_type_error: `Building Type is required`,
  building_type_paragraph: `For mixed-use buildings, we recommend a demand certificate as this provides more accurate results.`,
  year_of_construction: "Year Of Construction",
  year_of_construction_error: `Year must be between 1850 and 2024.`,
  number_of_apartments: `Number Of Apartments`,
  number_of_apartments_error:
    "The Number of Apartments is required as a number.",
  number_of_apartments_paragraph: `Since energy certificates can only be issued for the entire building and not for individual apartments, information on all apartments in the building is required. Please count granny flats as separate residential units. For single-family homes, if there are no other apartments in the building, please select 1 residential unit.`,
  eMail: `E-Mail`,
  eMail_error: `Invalid email address`,
  eMail_para_1:
    "Please provide a valid email address so that we can contact you if we have any questions regarding your information.",
  eMail_para_2:
    "You will also receive the order confirmation and invoice via the email address you provided.",
  number_of_floors: "Number Of Floors",
  number_of_floors_error: "The Number of floors is required as a number.",
  number_of_floors_paragraph: ``,
  floor_to_ceiling_height: "Floor To Ceiling Height (In Meters)",
  floor_to_ceiling_height_error:
    "The Floor to Ceiling Height (in meters) is required as a number.",
  floor_to_ceiling_height_paragraph: ``,
  is_there_heated_basement: `Is There Is A Heated Basement`,
  yes: "Yes",
  no: "No",
  Please_provide_proper_information: "Please provide proper information",
  heating_system_are_installed: `How Many Heating System Are Installed?`,
  energy_sources: `Energy Sources`,
  energy_sources_error: "Energy source is required.",
  energy_sources_paragraph: "Please select your main energy source here",
  unit: "Unit",
  unit_error: `Unit ID is required.`,
  unit_para_1: `Please enter the correct consumption unit of the energy source/fuel.`,
  unit_para_2: `You can usually find this on your heating bill.`,
  name_of_provider_line: `If you do not know the primary energy factor of your energy provider, please tell us the name of your provider. You can leave notes here .`,
  date: "Date",
  date_paragraph: `"To apply for a consumption certificate, consumption values ​​from the last three years must be available - that is, the first billing period must be at least three years ago. Enter the month and year of the start of the first billing period here "`,
  consumption_from_this: "Consumption For November 2021 To October 2022",
  consumption_from_this_error:
    "Consumption field is invalid. It must be a non-negative number and should not contain 'e', '-', or '+'.",
  consumption_from_this_para_1:
    "Please enter the total energy consumption for each period here. A billing period is always one year long.",
  consumption_from_this_para_2:
    "For apartment buildings, you should state the total energy consumption of the entire building, as energy certificates always refer to the entire building.",
  consumption_from_this_para_3:
    "If it is a combined heat and power plant (CHP), please only state the amount of heat produced (without electricity).",
  included_heating_system: `Is Hot Water Included In Heating System (1)`,
  included_heating_system_paragraph: `Please indicate here whether the hot water energy consumption is already included in the consumption stated above or not. You can also specify the hot water energy consumption separately.`,
  consumption_is_unknown: `Consumption is unknown`,
  percentage_share_of_hot_water: `Percentage Share Of Hot Water`,
  percentage_share_of_hot_water_paragraph: `Enter the share of hot water supply here. The default value is 18%.`,
  vacancy_info_paragraph: `If the property was not fully occupied during the entire period, you can enter the vacancy rate in percent here.`,
  vacancy_info_para_1: `Please enter the vacancy rate in % for an entire year and for the entire area.`,
  vacancy_info_para_2: `Calculating the vacancy rate You add up the vacancy rate over the months and divide this by 12. Then multiply the value by 100 and you get the percentage.`,
  vacancy_info_para_3: `Example If the property was vacant for 3 months in 12 months, this would be 25%. Calculation: 3 / 12 * 100 = 25%`,
  heating_system_installed_on: `Year The 1st Heating System Was Installed On`,
  heating_system_installed_on_error: `Year must be between 1903 and 2024`,
  heating_system_installed_on_paragraph: `The year of manufacture of the heating system can be found in the exhaust gas measurement report, in the operating instructions, on the type plate, in the acceptance report, etc. If you use district heating, please state the year of manufacture of the district heating transfer station. If your boiler has been modernized, please state the year of modernization. The year the burner was modernized is not relevant. If you have several devices, please state the predominant year of manufacture.`,
  type_of_ventilation: `Type Of Ventilation`,
  type_of_ventilation_paragraph: `How is the building ventilated? Examples of ventilation systems without heat recovery are exhaust air systems in rooms without windows, such as bathrooms. Ventilation systems with heat recovery include, for example, central or decentralized supply and exhaust air systems that are equipped with heat exchangers.`,
  exhaust_air_system: `Exhaust air system`,
  without_heat_recovery: `Ventilation system without heat recovery`,
  with_heat_recovery: `Ventilation system with heat recovery`,
  shaft_ventilation: `Shaft Ventilation`,
  windows_ventilation: `Windows Ventilation`,
  living_space: `Living Space (Not Building Area)`,
  living_space_error: `Please enter a valid living space (positive number).`,
  living_space_paragraph: `The energy certificate is always linked to the respective building. We therefore ask you to state the living space of the entire building (excluding the basement) in square meters. Based on this information, the usable area of ​​the building is automatically calculated in the energy certificate.`,
  insulation_regulation_met: `Was The 1977 Thermal Insulation Regulation Met?`,
  insulation_regulation_met_paragraph: ``,
  building_part: `Building Part`,
  building_part_error: `Please select a building part.`,
  building_part_para_1: `In the local sense, a building is only considered a mixed building if two separate energy certificates have to be issued. This is the case if the commercial and residential areas are in a closer ratio than 90% and 10% to each other. In all other cases, "residential building" must be selected.`,
  building_part_para_2: `Furthermore, a joint energy certificate can be issued if the heating system does not differ significantly.`,
  building_part_para_3: `We do not currently issue energy certificates for non-residential buildings.`,
  property_have_basement: `Does Property Have Basement?`,
  lies_below_ground_floor: `What Lies Below The Ground Floor?`,
  unheated_basement: `Unheated basement`,
  heated_cellar: `Heated cellar`,
  no_floor_slab: `No basement available/ floor slab`,
  renewables_energy_used: `Are Renewables Energy Used?`,
  do_you_use: `What Do You Use?`,
  solar_theramal_energy: `Solar Theramal Energy`,
  photovoltaics: `Photovoltaics`,
  heat_pump: `Heat Pump`,
  biomass: `Biomass`,
  renewables_energy_used_for: `What Are Renewables Energy Used For?`,
  heating: `Heating`,
  hotwater: `Hotwater`,
  electricity: `Electricity`,
  optional_information_about_equipment: `Would You Like To Provide Optional Information About The Equipment?`,
  sun_protection: `Which Sun Protection Is Available?`,
  sun_protection_error: `Please select a sun protection option.`,
  glare_protection: `No sun/glare protection`,
  flare_protection: `Sun and/or flare protection (roller shutters, blinds), automatically operated`,
  only_glare_protection: `Only glare protection (mostly in office building)`,
  light_directing: `Only glare protection, light-directing (mostly in office buildings)`,
  insulation_glazing: `Does The Property Have Thermal Insulation/Insulation Glazing?`,
  attic_insulation: `Is There Roof Or Attic Insulation?`,
  house_number: `Street Or House Number`,
  house_number_error: `Street is required`,
  house_number_para_1: `Please enter the address of the building for which the energy certificate is to be created.`,
  house_number_para_2: `You can enter the billing and delivery address in the next step.`,
  postcode: `Postcode`,
  postcode_error: `Postcode is required`,
  postcode_para_1: `Please enter the address of the building for which the energy certificate is to be created.`,
  postcode_para_2: `You can enter the billing and delivery address in the next step.`,
  location: `Location`,
  location_error: `Location is required`,
  location_para_1: `Please enter the address of the building for which the energy certificate is to be created.`,
  location_para_2: `You can enter the billing and delivery address in the next step.`,
  upload_building_photo: `Upload building Photo`,
  drag_drop_upload: `Drag & Drop or Upload`,
  energy_certificate_available: `Your Energy Certificate is Available Now`,
  energy_certificate_available_para_1: `Here is your simplified sample copy with your data. Please note that this is not the original ID card. You will receive the original after we review your order.`,
  energy_certificate_available_para_2: `Please review all the data again and then click continue`,
  i_reviewed_entries: `I reviewed my entries and energy values and found no errors.`,
  order_type: `Order Type`,
  order_type_paragaraph: `Please select whether you are submitting the application as a private individual or as a company. If you are ordering as a business person with a VAT ID, select 'Business'. If you are ordering as a business person without a VAT ID, select either 'Private' or 'Commercial without VAT ID'.`,
  private_owners: `Private Owners`,
  commercial: `Commercial`,
  commercial_ustId: `Commercial (excluding USt.ID)`,
  phone_number: `Phone Number`,
  first_name: `First Name`,
  surname: `Surname`,
  billing_house_number: `Street & House Number`,
  billing_house_number_para_1: `Please enter the address that should appear on the invoice. If you choose the optional postal delivery, we will also send your energy certificate to this address.`,
  billing_house_number_para_2: `If you need different addresses for shipping and the invoice, please enter the additional address in the comments field or contact our customer service directly.`,
  billing_house_number_para_3: `If your address is abroad, you can also enter a foreign address. We also ship abroad.`,
  company_name: `Company Name`,
  VAT_ID: `VAT ID`,
  common_error: `this field cannot be empty.`,
  add_on_services: `Add On Services`,
  postal_dispatch_invoices: `High-quality postal dispatch of ID cards and invoices`,
  express_exhibition_today: `Express exhibition today`,
  advanced_verification: `Advanced Verification (Recommended)`,
  free_correction_option: `Free correction option if incorrect information is detected`,
  experts_explain: `We answer your questions - experts explain!`,
  delivery_method: `Delivery Method of ID Card (Delivery Method of ID Card)`,
  delivery_method_2: `Delivery Method of ID Card (Printed copy by mail and digitally by e-mail)`,
  order_summary: `Order Summary`,
  energy_performance_certificate: `Energy Performance Certificate`,
  certificate_residential_build: `Consumption Certificate for Residential Buildings`,
  i_agree_text: `I agree to the terms and conditions and have has taken note of the provisions on data protection and cancellation policy`,
  terms_conditions: `Terms & conditions`,
  total: `Total`,
  order_now: `Order Now`,
};

export const needCertificateEngText = {
  step_1: "Step 1",
  step_2: "Step 2",
  step_3: "Step 3",
  step_4: "Step 4",
  step_5: "Step 5",
  step_6: "Step 6",
  step_7: "Step 7",
  step_8: "Step 8",
  building_info: "Building Info",
  structure_details: `Structure Details`,
  building_component: `Building Component`,
  heat_generator_details: `Heat Generator details`,
  property_address: "Property Address",
  file_upload: `File Upload`,
  certificate: "Certificate",
  billing_delivery_address: "Billing & Delivery Address",
  building_data: "Building Data",
  provided_information: "Provided information",
  occasion: "Occasion",
  occasion_error: `Occasion is required`,
  occasion_paragraph: `If you need the energy certificate for financing or a loan application, please select the reason for issue as "Other". Energy certificates with the reasons for issue as "New construction" or "Modernization" cannot be ordered according to Section 80 Sentences 1 and 2 GEG.`,
  postal_code: "Postal Code",
  postal_code_error: `Invalid postal code`,
  building_type: `Building Type`,
  building_type_error: `Building Type is required`,
  building_type_paragraph: `For mixed-use buildings, we recommend a demand certificate as this provides more accurate results.`,
  number_of_apartments: `Number Of Apartments`,
  number_of_apartments_error:
    "The Number of Apartments is required as a number.",
  number_of_apartments_paragraph: `Since energy certificates can only be issued for the entire building and not for individual apartments, information on all apartments in the building is required. Please count granny flats as separate residential units. For single-family homes, if there are no other apartments in the building, please select 1 residential unit.`,
  year_of_construction: "Year Of Construction",
  year_of_construction_error: `Year must be between 1850 and 2024.`,
  no_of_floors: `No. Of Floors`,
  eMail: `E-Mail`,
  eMail_error: `Invalid email address`,
  eMail_para_1:
    "Please provide a valid email address so that we can contact you if we have any questions regarding your information.",
  eMail_para_2:
    "You will also receive the order confirmation and invoice via the email address you provided.",
  number_of_floors: "Number Of Floors",
  number_of_floors_error: "The Number of floors is required as a number.",
  number_of_floors_paragraph: ``,
  heated_area_floor: `Percentage Heated Area In 1st Floor`,
  provide_your_proper_information: `Provide your proper information.`,
  structure_details_para: `To accurately determine the property's energy requirements, we need the following information from you.`,
  structure_details_para_note: `Please provide only the shape and external dimensions of your building section.`,
  as_per_your_floor_plan: `As per your floor plan`,
  wall_length: `Wall Length A`,
  corners: `Corners`,
  custom: `Custom`,
  total_floor_height: `Average Total Floor Height (Incl. Ceiling)`,
  living_space: `Living Space`,
  construction_type: `Construction Type`,
  material_used: `Material Used`,
  part_the_building: `Part Of The Building`,
  type_of_ventilation: `Type Of Ventilation`,
  exhaust_air_system: `Exhaust air system`,
  without_heat_recovery: `Ventilation system without heat recovery`,
  with_heat_recovery: `Ventilation system with heat recovery`,
  shaft_ventilation: `Shaft Ventilation`,
  windows_ventilation: `Windows Ventilation`,
  cooling_system: `Is There A Cooling System?`,
  yes: "Yes",
  no: "No",
  from_electricity: `From Electricity`,
  delivered_cold: `Delivered cold`,
  passive_cooling: `Passive cooling`,
  cooling_from_heat: `Cooling from heat`,
  has_leaks_been_conducted: `Has A Test For Leaks Been Conducted(Blower Door Test)?`,
  compliance_with_tightness: `Compliance with tightness`,
  without_leak_test: `Without leak test`,
  obvious_leaks: `Obvious leaks`,
  other: `Other`,
  required_field_error: `This field is required`,
  roof: `Roof`,
  building_component_error: `Please enter details in Window, Basement, Insulation section`,
  roof_shape: `Roof Shape`,
  flat_roof: `Flat Roof`,
  gable_roof: `Gable Roof`,
  hip_roof: `Hip Roof`,
  pult_roof: `Pult Roof`,
  walm_flat: `Walm + Flat`,
  structure: `Structure`,
  attic: `Attic`,
  attic_type: `Attic Type`,
  attic_height: `Attic Height`,
  roof_pitch: `Roof Pitch`,
  ridge_length: `Ridge Length`,
  ridge_width: `Ridge Width`,
  is_there_second_attic: `Is There Second Attic`,
  is_there_second_attic_heated: `Is There Second Attic Heated`,
  second_attic_height: `Second Attic Height`,
  knee_wall_or_sill: `Does Property Have A Knee Wall Or Sill?`,
  knee_wall_sill_height: `Knee Wall / Sill Height`,
  window: `Window`,
  window_top_para_1: `The reported age of your windows, 47 years, seems unusually high. Please verify this information. If it is accurate, you may proceed.`,
  have_dimensions_window: `Do You Have Dimensions Of Each Window?`,
  windows_in_wall: `Please Enter All The Windows In Wall A`,
  window_top_para_2: `Since May 2021, the Building Energy Act (GEG) requires detailed images of the renovation status, building envelope, and system technology (such as heat generators) to be submitted. However, only the image of the building is shown on the energy certificate.`,
  wall: `Wall`,
  window_type: `Window Type`,
  window_area_wall: `Percentage Of Window Area On The Wall`,
  type: `Type`,
  length: `Length`,
  width: `Width`,
  count: `Count`,
  basement: `Basement`,
  below_ground_floor: `What Lies Below The Ground Floor?`,
  floor_slab: `Floor Slab`,
  Insulation: `Insulation`,
  property_insulated:`Is The Property Insulated?`,
  exterior_walls:`Exterior Walls`,
  roof_or_top_ceiling:`Roof Or Top Ceiling`,
  floor_basement:`Floor / Basement`,
  basement_walls:`Basement Walls`,
  heating_system_installed: `How Many Heating System Are Installed?`,
  energy_source: `Energy Source`,
  heating_system : `Heating System`,
  year_1st_heating_installed: `Year Of 1st Heating System Was Installed`,
  year_2nd_heating_installed: `Year Of 2nd Heating System Was Installed`,
  year_3rd_heating_installed: `Year Of 3rd Heating System Was Installed`,
  year_4th_heating_installed: `Year Of 4th Heating System Was Installed`,
  distribution_heating: `Distribution Heating`,
  share_heating: `Share Of Heating`,
  hot_water_system_installed :`How Many Hot Water System Are Installed?`,
  water_heating: `Water Heating`,
  year_1st_water_installed: `Year Of 1st Hot Water System Was Installed`,
  year_2nd_water_installed: `Year Of 2nd Hot Water System Was Installed`,
  year_3rd_water_installed: `Year Of 3rd Hot Water System Was Installed`,
  year_4th_water_installed: `Year Of 4th Hot Water System Was Installed`,
  renewable_energy: `Are Renewable Energy Used?`,
  unheated_basement: `Unheated basement`,
  photovoltaics: `Photovoltaics`,
  heat_pump: `Heat Pump`,
  biomass: `Biomass`,
  house_number: `Street Or House Number`,
  location:`Location`,
  postcode:`Postcode`,
  billing_house_number_para_1: `Please enter the address that should appear on the invoice. If you choose the optional postal delivery, we will also send your energy certificate to this address.`,
  billing_house_number_para_2: `If you need different addresses for shipping and the invoice, please enter the additional address in the comments field or contact our customer service directly.`,
  billing_house_number_para_3: `If your address is abroad, you can also enter a foreign address. We also ship abroad.`,
  direction_object_point:`In Which Cardinal Direction Does Side A Of The Object Point? `,
  property_another_house:`Is The Property Next To Another House?`,
  title_file_upload:`Information On File Upload`,
  description_file_upload: `Since May 2021, the Building Energy Act (GEG) requires detailed images of the renovation status, building envelope, and system technology (such as heat generators) to be submitted. However, only the image of the building is shown on the energy certificate.`,
  photo_heater:`Photo of the heater`,
  photo_building:`Photo of the Building`,
  energy_certificate_available: `Your Energy Certificate is Available Now`,
  energy_certificate_available_para_1: `Here is your simplified sample copy with your data. Please note that this is not the original ID card. You will receive the original after we review your order.`,
  energy_certificate_available_para_2: `Please review all the data again and then click continue`,
  i_reviewed_entries: `I reviewed my entries and energy values and found no errors.`,
  order_type: `Order Type`,
  order_type_paragaraph: `Please select whether you are submitting the application as a private individual or as a company. If you are ordering as a business person with a VAT ID, select 'Business'. If you are ordering as a business person without a VAT ID, select either 'Private' or 'Commercial without VAT ID'.`,
  private_owners: `Private Owners`,
  commercial: `Commercial`,
  commercial_ustId: `Commercial (excluding USt.ID)`,
  phone_number: `Phone Number`,
  first_name: `First Name`,
  surname: `Surname`,
  add_on_services: `Add On Services`,
  postal_dispatch_invoices: `High-quality postal dispatch of ID cards and invoices`,
  express_exhibition_today: `Express exhibition today`,
  advanced_verification: `Advanced Verification (Recommended)`,
  free_correction_option: `Free correction option if incorrect information is detected`,
  experts_explain: `We answer your questions - experts explain!`,
  delivery_method: `Delivery Method of ID Card (Delivery Method of ID Card)`,
  delivery_method_2: `Delivery Method of ID Card (Printed copy by mail and digitally by e-mail)`,
  custom_structure: `Custom Structure`,


};
const successEngText = {
  heading: `Thank you for ordering`,
  statusText: `Your payment has been successfully received.`,
  status_desc: `We have received your order  and shall get started with the processing.You will receive your final certificate on the email shared by you.`,
  receipt:`Receipt`,
  invoice: `Invoice`,

};
const failureEngText = {
  heading: `Payment Failed – Action Required`,
  statusText: `Your payment was not successfully processed.`,
  status_desc: `We have received your order but were unable to process the payment.
Once the payment is successfully processed, we will begin processing your order and 
send the final certificate to the email address you provided.`,
  retry_payment:` Retry Payment`,

};