import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Components/Greenox/Header/Header";
import Step1 from "./Step1";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { steps } from "../../../Utility/utils";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5.jsx";
import Step6 from "./Step6.jsx";
import Address from "./Address.jsx";
import GenerateCertifiacte from "../../../Components/GenerateCertifiacte/GenerateCertifiacte.jsx";
import { AppContext } from "../../../ContextApi/AppContext/AppContext.jsx";
import Step7 from "./Step7.jsx";
import Step8 from "./Step8.jsx";
import HeaderNew from "../../../Components/Greenox/HeaderNew/HeaderNew.jsx";

function ConsumptionForm() {
  const { state, setState } = useContext(AppContext);
  const { page: currentPage } = state;
  const { page, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const pageNumber = Number(page);
    if (isNaN(pageNumber) || pageNumber < 1 || pageNumber > steps.length) {
      navigateToPage(1);
    } else {
      // setCurrentPage(pageNumber);
    }
  }, [page]);

  // useEffect(() => {
  //   setState((prev) => ({ ...prev, filledPage: 8 }));
  // }, []);
  const navigateToPage = (pageNumber) => {
    // setCurrentPage(pageNumber);
    navigate(`/consumption-certificate/${id}`);
  };

  const handleNavigation = (direction) => {
    navigateToPage(currentPage + direction);
  };
  return (
    <div>
      <HeaderNew showDetails={true} />
      {currentPage === 1 && <Step1 steps={steps} />}
      {currentPage === 2 && <Step2 />}
      {currentPage === 3 && <Step3 />}
      {currentPage === 4 && <Step4 />}
      {currentPage === 5 && <Step5 />}
      {currentPage === 6 && <Step6 />}
      {/* {currentPage === 7 && <Address />} */}
      {currentPage === 7 && <Step7 />}
      {currentPage === 8 && <Step8 showForm={true} />}
    </div>
  );
}

export default ConsumptionForm;
